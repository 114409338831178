import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductPopup from './ProductPopup.component';
import { PRODUCT_POPUP_ID } from './ProductPopup.config';

/** @namespace PeggysagePwa/Component/ProductPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[PRODUCT_POPUP_ID] || {}
});

/** @namespace PeggysagePwa/Component/ProductPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PeggysagePwa/Component/ProductPopup/Container */
export class ProductPopupContainer extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            title: PropTypes.string,
            id: PropTypes.number,
            sku: PropTypes.string,
            bundleOptionUid: PropTypes.string,
            isBundleOptionSelectable: PropTypes.bool,
            onBundleOptionSelected: PropTypes.func,
            onBackButtonClick: PropTypes.func
        })
    };

    static defaultProps = {
        payload: {
            title: '',
            id: 0,
            sku: '',
            bundleOptionUid: null,
            isBundleOptionSelectable: false,
            onBundleOptionSelected: null,
            onBackButtonClick: null
        }
    };

    containerProps() {
        const { payload } = this.props;

        return { payload };
    }

    render() {
        return (
            <ProductPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPopupContainer);
