import {
    ShareWishlistForm as SourceShareWishlistForm
} from 'SourceComponent/ShareWishlistForm/ShareWishlistForm.component';
import { CustomerType } from 'Type/Account.type';

import shareWishlistForm from './ShareWishlistForm.form';

import './ShareWishlistForm.override.style';

/** @namespace PeggysagePwa/Component/ShareWishlistForm/Component */
export class ShareWishlistFormComponent extends SourceShareWishlistForm {
    static propTypes = {
        ...SourceShareWishlistForm.propTypes,
        customer: CustomerType.isRequired
    };

    /**
     * Override: needed to get local file
     * @return {*}
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const { customer } = this.props;

        return shareWishlistForm(customer);
    }
}

export default ShareWishlistFormComponent;
