import { formatOrders } from 'Util/Orders';

import {
    GET_ORDER_LIST,
    SET_ORDER_ADDING_TO_FAVORITES_STATUS,
    SET_ORDER_LOADING_STATUS
} from './Order.action';

/** @namespace PeggysagePwa/Store/Order/Reducer/getInitialState */
export const getInitialState = () => ({
    orderList: {},
    isLoading: true,
    isAddingOrderToFavorites: false
});

/** @namespace PeggysagePwa/Store/Order/Reducer/OrderReducer */
export const OrderReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        orderList,
        status,
        loadMore
    } = action;

    switch (type) {
    case GET_ORDER_LIST:
        const { items = [], page_info, year_filter_options } = orderList;
        const { orderList: { items: prevItems = [] } } = state;
        const formattedOrders = formatOrders(items);

        const finalItems = loadMore ? [...formattedOrders, ...prevItems] : formattedOrders;

        return {
            ...state,
            isLoading: status,
            orderList: { items: finalItems, pageInfo: page_info, year_filter_options }
        };
    case SET_ORDER_LOADING_STATUS:
        return {
            ...state,
            isLoading: status
        };
    case SET_ORDER_ADDING_TO_FAVORITES_STATUS:
        return {
            ...state,
            isAddingOrderToFavorites: status
        };
    default:
        return state;
    }
};

export default OrderReducer;
