/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const UPDATE_PERFECTCORP_PRODUCT_DETAILS = 'UPDATE_PERFECTCORP_PRODUCT_DETAILS';
export const TOGGLE_GET_PFPROD_LOADER = 'TOGGLE_GET_PFPROD_LOADER';

/**
 * Update product list with new list (rewrite if already exists).
 * @param  {Array<Object>} items List of products returned from fetch
 * @param  {Number} totalItems Total number of products in this filter
 * @return {void}
 * @namespace PeggysagePwa/Store/PerfectCorpProduct/Action/updateProductDetails */

export const updateProductDetails = (product) => ({
    type: UPDATE_PERFECTCORP_PRODUCT_DETAILS,
    perfectProduct: product
});

/** @namespace PeggysagePwa/Store/PerfectCorpProduct/Action/toggleLoader */
export const toggleLoader = (isLoading) => ({
    type: TOGGLE_GET_PFPROD_LOADER,
    isLoading
});
