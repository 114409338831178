import {
    CONFIRMATION_REQUIRED,
    SHOW_VAT_NUMBER_OPTIONAL,
    SHOW_VAT_NUMBER_REQUIRED
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

export { CONFIRMATION_REQUIRED, SHOW_VAT_NUMBER_OPTIONAL, SHOW_VAT_NUMBER_REQUIRED };

export const CUSTOMER_TYPES = {
    private: 'private',
    pro: 'enterprise',
    student: 'student',
    employee: 'employee'
};

export const FILE_KEY_PRO_ID = 'carte_identite';
export const FILE_KEY_PRO_AVS = 'attestation_avs';
export const FILE_KEY_STUDENT_ID = 'justificatif_scolarite';
export const FILE_KEY_EMPLOYEE_ID = 'bulletin_paie';

export const CUSTOMER_STEP = 'CUSTOMER_STEP';
export const COMPANY_SEARCH_STEP = 'company-search';
export const COMPANY_STEP = 'company';
export const UPLOAD_STEP = 'identification';
export const OPTIN_STEP = 'optin';
export const RECAP_STEP = 'success';

export const CREATE_ACCOUNT_DATA = 'create_account';
export const CREATE_ACCOUNT_DATA_DURATION = 15;
