import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PRODUCT_BUNDLE_OPTIONS_POPUP_ID } from 'Component/Product/Product.config.js';
import { showPopup } from 'Store/Popup/Popup.action';
import { ProductItemType, ProductType } from 'Type/ProductList.type';

import ProductBundleButton from './ProductBundleButton.component';

/** @namespace PeggysagePwa/Component/ProductBundleButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currencyCode: state.ConfigReducer.currencyData.current_currency_code
});

/** @namespace PeggysagePwa/Component/ProductBundleButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (popupId, payload) => dispatch(showPopup(popupId, payload))
});

/** @namespace PeggysagePwa/Component/ProductBundleButton/Container */
export class ProductBundleButtonContainer extends PureComponent {
    static propTypes = {
        currencyCode: PropTypes.string.isRequired,
        product: ProductType.isRequired,
        optionGroup: ProductItemType.isRequired,
        selectedOptions: PropTypes.arrayOf(PropTypes.string),
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedOptions: []
    };

    containerFunctions = {
        showBundlePopup: this.showBundlePopup.bind(this)
    };

    containerProps() {
        const {
            currencyCode,
            product,
            optionGroup,
            selectedOptions
        } = this.props;

        return {
            currencyCode,
            product,
            optionGroup,
            selectedOptions
        };
    }

    showBundlePopup(e) {
        const {
            showPopup,
            optionGroup: {
                option_id,
                title
            }
        } = this.props;

        // Needed because the component is a button inside a form
        e.preventDefault();

        showPopup(
            PRODUCT_BUNDLE_OPTIONS_POPUP_ID,
            {
                title,
                currentOptionId: option_id
            }
        );
    }

    render() {
        return (
            <ProductBundleButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBundleButtonContainer);
