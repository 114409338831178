/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import MatomoRouteWrapperComponent from './MatomoRouteWrapper.component';

export const withMatomo = (originalRender, matomoRoute) => (
    <MatomoRouteWrapperComponent route={ matomoRoute }>
        { originalRender }
    </MatomoRouteWrapperComponent>
);

export default withMatomo;
