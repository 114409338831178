/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * GTM scripts
 */
export class Scripts {
    static getScript({
        site_id, dataLayerName, path
    }) {
        return `(function(){ 
            var u="${path}";
            ${dataLayerName}.push(['setSiteId', '${site_id}']);
            ${dataLayerName}.push(['setTrackerUrl', u+'piwik.php']);
            //${dataLayerName}.push(['trackPageView']);
            ${dataLayerName}.push(['enableLinkTracking']);
            
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.type='text/javascript'; g.defer=true; g.async=true; g.src=u+'piwik.js';
                s.parentNode.insertBefore(g,s); })();
            `;
    }
}

export default Scripts;
