import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CategorySortContainer as SourceCategorySortContainer
} from 'SourceComponent/CategorySort/CategorySort.container';

/** @namespace PeggysagePwa/Component/CategorySort/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace PeggysagePwa/Component/CategorySort/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PeggysagePwa/Component/CategorySort/Container */
export class CategorySortContainer extends SourceCategorySortContainer {
    static propTypes = {
        ...SourceCategorySortContainer.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    /**
     * Override
     * @returns {{isMobile}}
     */
    containerProps() {
        const { isMobile } = this.props;

        return {
            ...super.containerProps(),
            isMobile
        };
    }

    /**
     * Override: change custom labels
     * @param option
     * @returns {{asc: *, desc: *}|{asc: *}}
     * @private
     */
    _getLabel(option) {
        const { id, label } = option;

        switch (id) {
        case 'name':
            return {
                asc: __('Name: A to Z', label),
                desc: __('Name: Z to A', label)
            };
        case 'position':
            return {
                asc: __('Best sellers')
            };
        case 'price':
            return {
                asc: __('%s: Low to High', label),
                desc: __('%s: High to Low', label)
            };
        case 'none':
            return {
                asc: __('Best sellers')
            };
        case 'rating_summary':
            return {
                desc: label
            };
        default:
            return {
                asc: __('%s: Ascending', label),
                desc: __('%s: Descending', label)
            };
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySortContainer);
