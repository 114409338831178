import {
    getInitialState as sourceGetInitialState,
    updateEveryTime as sourceUpdateEveryTime
} from 'SourceStore/Meta/Meta.reducer';
import { UPDATE_META } from 'Store/Meta/Meta.action';

/**
 * Override: add ogType
 * @type {(string|string)[]}
 */
export const updateEveryTime = [
    ...sourceUpdateEveryTime,
    'ogType'
];

/** @namespace PeggysagePwa/Store/Meta/Reducer/filterData */
export const filterData = (data) => {
    const updated = updateEveryTime.reduce((acc, key) => {
        acc[key] = data[key];

        return acc;
    }, {});

    return { ...data, ...updated };
};

/**
 * Override: add ogType
 * @namespace PeggysagePwa/Store/Meta/Reducer/getInitialState
 */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    ogType: ''
});

/** @namespace PeggysagePwa/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case UPDATE_META:
        const filteredData = filterData(payload);

        return {
            ...state,
            ...filteredData
        };

    default:
        return state;
    }
};

export default MetaReducer;
