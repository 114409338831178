import PropTypes from 'prop-types';

import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';
import FIELD_TYPE from 'SourceComponent/Field/Field.config';
import { noopFn } from 'Util/Common';

import './Field.override.style';

/** @namespace PeggysagePwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    static propTypes = {
        revealPassword: PropTypes.func.isRequired,
        hasFloatingLabel: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool.isRequired
    };

    /**
     * Override: add required tag, set the input out of the label
     * @returns {JSX.Element}
     */
    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...newAttr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...inputEvents }
                  // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ isChecked }
                />
                <div block="input-control" disabled={ isDisabled } />
                <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                    { label }
                    { super.renderRequiredTag() }
                </label>
            </>
        );
    }

    /**
     * Override: add button to reveal password
     * @returns {JSX.Element}
     */
    renderDefaultInput() {
        const {
            revealPassword,
            type
        } = this.props;

        if (type === FIELD_TYPE.password) {
            return (
                <>
                    { super.renderDefaultInput() }
                    <button
                      type="button"
                      block="Button"
                      className="password-reveal"
                      onClick={ revealPassword }
                    >
                        { __('Show password') }
                    </button>
                </>
            );
        }

        return (
            <>
                { super.renderDefaultInput() }
            </>
        );
    }

    /**
     * Override: add classes to handle floating labels
     * @returns {JSX.Element}
     */
    render() {
        const {
            type, validationResponse, mix, hasFloatingLabel, isEmpty, attr: { name }
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        const mods = {
            [name]: true,
            type,
            hasFloatingLabel,
            isEmpty
        };

        return (
            <div block="Field" elem="Wrapper" mods={ mods }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: !hasError && validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
