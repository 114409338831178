/* eslint-disable simple-import-sort/sort */
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    UPDATE_CONFIG,
    UPDATE_CONFIG_DEVICE,
    UPDATE_CURRENT_CURRENCY
} from 'Store/Config/Config.action';

import {
    MAX_WIDTH,
    MAX_HEIGHT,
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    countries, reviewRatings, storeConfig, currencyData, currency, cartDisplayConfig,
    getIndexedRatings,
    getCurrencyRates,
    getCurrencyData,
    getCountryData,
    getCheckoutAgreementData,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Config/Config.reducer';

export {
    MAX_WIDTH,
    MAX_HEIGHT,
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    countries, reviewRatings, storeConfig, currencyData, currency, cartDisplayConfig,
    getIndexedRatings,
    getCurrencyRates,
    getCurrencyData,
    getCountryData,
    getCheckoutAgreementData
};

export const {
    cheapestShippingMethod
} = BrowserDatabase.getItem('config') || { cheapestShippingMethod: {} };

/** @namespace PeggysagePwa/Store/Config/Reducer/getCheapestShippingMethod */
export const getCheapestShippingMethod = (base, state) => (base || state.cheapestShippingMethod || {});

/** @namespace PeggysagePwa/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    cheapestShippingMethod
});

/**
 * Override: add cheapest shipping method
 * @param state
 * @param action
 * @returns {{isLoading: boolean, reviewRatings: *, checkoutAgreements: [], category_url_suffix: string, currencyData: *, currency: *, cheapestShippingMethod, countries: *, device: (*&{opera: boolean, blackberry: boolean, standaloneMode: boolean, android: boolean, isMobile: boolean, ios: boolean, windows: boolean}), cartDisplayConfig: *, priceTaxDisplay: {}}|{isLoading: boolean, reviewRatings: *, checkoutAgreements: [], category_url_suffix: string, currencyData: *, currency: *, cheapestShippingMethod, countries: *, device: {opera: boolean, blackberry: boolean, standaloneMode: boolean, android: boolean, isMobile: boolean, ios: boolean, windows: boolean}, cartDisplayConfig: *, priceTaxDisplay: {}}|(*&{isLoading: boolean, reviewRatings: [], checkoutAgreements: *, category_url_suffix: string, currencyData: *, currency: *, cheapestShippingMethod: *, countries: *, device: {opera: boolean, blackberry: boolean, standaloneMode: boolean, android: boolean, isMobile: boolean, ios: boolean, windows: boolean}, cartDisplayConfig: {}, priceTaxDisplay: {}})|{isLoading: boolean, reviewRatings: *, checkoutAgreements: [], category_url_suffix: string, currencyData: (*&{current_currency_code: string}), currency: *, cheapestShippingMethod, countries: *, device: {opera: boolean, blackberry: boolean, standaloneMode: boolean, android: boolean, isMobile: boolean, ios: boolean, windows: boolean}, cartDisplayConfig: *, priceTaxDisplay: {}}}
 * @constructor
 * @namespace PeggysagePwa/Store/Config/Reducer/ConfigReducer
 */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {},
            getCheapestShippingMethod: cheapestShippingMethod = {}
        } = {},
        device
    } = action;

    const { currentCurrency = '' } = action;
    const { currencyData: prevCurrencyData } = state;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;

        window.secure_base_media_url = secure_base_media_url;

        return {
            ...state,
            countries: getCountryData(countries, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currency: getCurrencyRates(currency, state),
            currencyData: getCurrencyData(currencyData, state),
            cheapestShippingMethod: getCheapestShippingMethod(cheapestShippingMethod, state),
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            cartDisplayConfig
        };

    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };

    case UPDATE_CURRENT_CURRENCY:
        return {
            ...state,
            currencyData: {
                ...prevCurrencyData,
                current_currency_code: currentCurrency
            }
        };

    default:
        return state;
    }
};
export default ConfigReducer;
