/* eslint-disable no-console */
// import { getAuthorizationToken, ONE_HOUR, ONE_HOUR_IN_SECONDS } from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

const MAGENTO_CACHE_ID = 'magento_cache_id';

export const setMagentoCacheId = (cacheId) => {
    BrowserDatabase.setItem(cacheId, MAGENTO_CACHE_ID, ONE_MONTH_IN_SECONDS);
};

export const getMagentoCacheId = () => {
    const cacheId = BrowserDatabase.getItem(MAGENTO_CACHE_ID);
    if (cacheId) {
        return BrowserDatabase.getItem(MAGENTO_CACHE_ID);
    }

    return null;
};

export const aroundParseResponse = (args, callback, _instance) => {
    if (args && args.length > 0) {
        const response = args[0];
        if (typeof response === 'object' && (args[0].constructor.name === 'Promise' || args[0].constructor.name === 'Response')) {
            if (args[0].constructor.name === 'Promise') {
                try {
                    response.then(
                        (res) => {
                            const cacheId = res.headers.get('x-magento-cache-id');
                            setMagentoCacheId(cacheId);
                            if (process.env.NODE_ENV === 'developmentoff') {
                                console.log('%cCache Id received',
                                    'background-color: #0000cc; color: #ffffff; font-weight: bold; border-radius: 5px; padding: 2px 5px',
                                    cacheId, res);
                            }
                        }
                    );
                } catch (e) {
                    // no error handling
                }
            } else if (args[0].constructor.name === 'Response') {
                try {
                    const cacheId = response.headers.get('x-magento-cache-id');
                    if (cacheId && (cacheId !== getMagentoCacheId())) {
                        setMagentoCacheId(cacheId);
                    }
                } catch (e) {
                    // no error handling
                }
            }
        }
    }

    return callback(...args);
};

export const aroundAppendTokenToHeaders = (args, callback, instance) => {
    const cacheId = getMagentoCacheId();
    if (cacheId) {
        if (process.env.NODE_ENV === 'developmentoff') {
            console.log('%cCache Id sent',
                'background-color: #00cc00; color: #ffffff; font-weight: bold; border-radius: 5px; padding: 2px 5px',
                cacheId);
        }

        return {
            ...callback(...args, instance),
            'X-Magento-Cache-Id': cacheId
        };
    }

    return {
        ...callback(...args, instance)
    };
};

export default {
    'Util/Request/parseResponse': {
        function: aroundParseResponse
    },
    'PeggysagePwa/Util/Request/parseResponse': {
        function: aroundParseResponse
    },
    'Util/Request/appendTokenToHeaders': {
        function: aroundAppendTokenToHeaders
    }
};
