/**
 * @namespace PeggysagePwa/Util/Yotpo/Index/getYotpoProductReviewStates
 */
export const getYotpoProductReviewStates = async (productId, apiKey) => {
    if (!productId) {
        return null;
    }

    return fetch(
        `https://api-cdn.yotpo.com/products/${apiKey}/${productId}/bottomline`
    )
        .then(
            /** @namespace PeggysagePwa/Util/Yotpo/Index/getYotpoProductReviewStates/then/catch/then/then/fetch/then */
            (response) => response.json()
        )
        .then(
            /** @namespace PeggysagePwa/Util/Yotpo/Index/getYotpoProductReviewStates/then/catch/then/then */
            (data) => {
                const {
                    response: {
                        bottomline = {}
                    } = {}
                } = data;

                return bottomline;
            }
        )
        .catch(
            /** @namespace PeggysagePwa/Util/Yotpo/Index/getYotpoProductReviewStates/then/catch */
            (error) => {
                // eslint-disable-next-line
                console.error(error);
            }
        );
};
