import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import StoreLocatorIcon from 'Component/StoreLocatorIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { MixType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList.type';

import './TrainingCard.style';

/** @namespace PeggysagePwa/Component/TrainingCard/Component */
export class TrainingCardComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isLoading: PropTypes.bool,
        mix: MixType,
        displayMode: PropTypes.string.isRequired,
        thumbnail: PropTypes.string,
        getCentersWithValidSessions: PropTypes.func.isRequired,
        onClickAvailabilities: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false,
        mix: {},
        thumbnail: ''
    };

    className = 'TrainingCard';

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        this.sharedComponent = (
            <Image
              // imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: this.className, elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderPictureWrapper() {
        const { displayMode } = this.props;

        if (displayMode === 'compact') {
            return null;
        }

        return (
            <div block={ this.className } elem="FigureReview">
                <figure block={ this.className } elem="Figure">
                    { this.renderPicture() }
                </figure>
            </div>
        );
    }

    renderName() {
        const { product: { name } } = this.props;

        return (
            <p block={ this.className } elem="Name">
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderDescription() {
        const {
            displayMode,
            product: { short_description: { html: shortDescription } }
        } = this.props;

        if (displayMode !== 'compact' || !shortDescription) {
            return null;
        }

        return (
            <div block={ this.className } elem="Description">
                <Html content={ shortDescription } />
            </div>
        );
    }

    renderAvailabilities() {
        const { getCentersWithValidSessions, onClickAvailabilities } = this.props;

        const centersWithValidSessions = getCentersWithValidSessions();

        const storeCount = centersWithValidSessions.length;

        const buttonLabel = storeCount ? __('Voir les disponibilités') : __('Aucune disponibilité');

        const storeLabel = storeCount > 1 ? __('Dans %s villes', storeCount) : __('Dans 1 ville');

        return (
            <div block={ this.className } elem="Availabilities">
                { !!storeCount && <StoreLocatorIcon /> }
                <div block={ this.className } elem="ContentStore">
                    { !!storeCount && (
                        <div block={ this.className } elem="AvailabilitiesStores">
                            { storeLabel }
                        </div>
                    ) }
                    <div block={ this.className } elem="AvailabilitiesLink">
                        <button
                          block="Button"
                          type="button"
                          mods={ { likeLink: true } }
                          onClick={ onClickAvailabilities }
                        >
                            { buttonLabel }
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderCardContent() {
        return (
            <div block={ this.className } elem="Inner">
                { this.renderPictureWrapper() }
                <div block={ this.className } elem="Content">
                    { this.renderName() }
                    <div block={ this.className } elem="ContentInner">
                        { this.renderDescription() }
                        { this.renderAvailabilities() }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            mix,
            isLoading
        } = this.props;

        return (
            <li
              block={ this.className }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
            </li>
        );
    }
}

export default TrainingCardComponent;
