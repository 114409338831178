import { COOKIE_FAVORITE_SHOP } from 'Component/PushCollectInStorePopup/PushCollectInStorePopup.config';
import CheckoutQuery from 'Query/Checkout.query';
import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn, ONE_HOUR_IN_SECONDS } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { fetchQueryGet } from 'Util/Request/Query';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';

/**
 * Get shop info from shop id
 * @param shop_id
 * @param cacheLifetime
 * @returns {Promise<Request|null>}
 * @namespace PeggysagePwa/Util/FavoriteShop/Index/getShopInfo
 */
export const getShopInfo = async (shop_id, cacheLifetime = ONE_HOUR_IN_SECONDS) => {
    if (!shop_id) {
        return null;
    }

    return fetchQueryGet(CheckoutQuery.getShopInfo(shop_id), 'getShopInfo', cacheLifetime)
        .then(
            /** @namespace PeggysagePwa/Util/FavoriteShop/Index/getShopInfo/then/catch/fetchQueryGet/then */
            ({ getShopInfo: shopInfo }) => shopInfo
        )
        .catch(
            /** @namespace PeggysagePwa/Util/FavoriteShop/Index/getShopInfo/then/catch */
            (error) => {
                showNotification('error', getErrorMessage(error));
            }
        );
};

/**
 * Retrieve favorite shop from cookie or customer data for logged in users
 * @returns {Promise<{}|*>}
 * @namespace PeggysagePwa/Util/FavoriteShop/Index/getCustomerFavoriteShop
 */
export const getCustomerFavoriteShop = async () => {
    if (isSignedIn()) {
        const store = getStore();
        const {
            MyAccountReducer: {
                customer: {
                    favorite_shop_id
                }
            } = {}
        } = store.getState();

        if (favorite_shop_id) {
            const response = await getShopInfo(favorite_shop_id);

            if (response) {
                return response;
            }
        }

        return {};
    }

    // Not logged in => check cookie
    const favoriteShop = BrowserDatabase.getItem(COOKIE_FAVORITE_SHOP);

    if (favoriteShop?.id) {
        const response = await getShopInfo(favoriteShop?.id);
        if (response) {
            return response;
        }
    }

    return {};
};

/**
 * Save favorite shop in cookie or into customer data for logged in users
 * @param {LocationType} shop
 * @namespace PeggysagePwa/Util/FavoriteShop/Index/setCustomerFavoriteShop
 */
export const setCustomerFavoriteShop = async (shop) => {
    const { id: favoriteShopId, name: favoriteShopName } = shop;

    if (!isSignedIn()) {
        // Save favorite shop in cookie
        const cookieFavoriteShop = {
            id: favoriteShopId,
            name: favoriteShopName
        };

        BrowserDatabase.setItem(cookieFavoriteShop, COOKIE_FAVORITE_SHOP, ONE_MONTH_IN_SECONDS);

        return true;
    }

    const store = getStore();
    const { dispatch } = store;

    // Save favorite shop in customer data
    const mutation = MyAccountQuery.getUpdateInformationMutation({ input: { favorite_shop_id: favoriteShopId } });

    try {
        const { updateCustomerV2: { customer } } = await fetchMutation(mutation);

        dispatch(updateCustomerDetails(customer));
        BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);

        return true;
    } catch (e) {
        showNotification('error', __('We are experiencing issues, please try again later'));

        return false;
    }
};

/**
 * Transfer favorite shop id from cookie to logged in customer, then delete cookie
 * @returns {Promise<boolean>}
 * @namespace PeggysagePwa/Util/FavoriteShop/Index/saveCustomerFavoriteShopFromCookie
 */
export const saveCustomerFavoriteShopFromCookie = async () => {
    if (!isSignedIn()) {
        return false;
    }

    const cookieFavoriteShop = BrowserDatabase.getItem(COOKIE_FAVORITE_SHOP);
    if (cookieFavoriteShop?.id) {
        const response = await setCustomerFavoriteShop(cookieFavoriteShop);
        if (response) {
            BrowserDatabase.deleteItem(COOKIE_FAVORITE_SHOP);
        }
    }

    return true;
};
