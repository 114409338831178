import PropTypes from 'prop-types';
import React from 'react';

import Loader from 'Component/Loader';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace PeggysagePwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        ...SourceAddToCart.propTypes,
        subLabel: PropTypes.string.isRequired
    };

    renderSubLabel() {
        const { subLabel } = this.props;

        if (subLabel === '') {
            return null;
        }

        return <span block="AddToCart" elem="SubLabel">{ subLabel }</span>;
    }

    /**
     * Override: add subLabel in button
     * @returns {JSX.Element}
     */
    render() {
        const {
            mix,
            handleButtonClick,
            layout,
            isDisabled,
            isAdding
        } = this.props;

        const label = isAdding ? __('Adding...') : __('Add to cart');

        return (
            <button
              onClick={ handleButtonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { layout } }
              disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                <span>{ label }</span>
                { this.renderSubLabel() }
                <Loader isLoading={ isAdding } />
            </button>
        );
    }
}

export default AddToCartComponent;
