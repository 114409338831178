/* eslint-disable max-len */
import {
    CartIcon as SourceCartIcon
} from 'SourceComponent/CartIcon/CartIcon.component';

import './CartIcon.override.style';

/** @namespace PeggysagePwa/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIcon {
    /**
     * Override: change svg
     */
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M18,18.9l-0.7-12c0-0.6-0.6-1.1-1.2-1.1h-2.7V4.4c0-2.4-2-4.4-4.4-4.4C6.4,0,4.4,2,4.4,4.4v1.3H1.7
                    C1,5.7,0.5,6.2,0.5,6.8L0,18.9c0,0.8,0.3,1.6,0.8,2.2C1.4,21.7,2.2,22,3,22h11.9c0.8,0,1.6-0.3,2.2-0.9C17.7,20.5,18,19.7,18,18.9
                    L18,18.9z M5.7,4.4c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0v1.3H5.7V4.4z M16.3,20.3c-0.3,0.4-0.8,0.6-1.3,0.6H3
                    c-0.5,0-1-0.2-1.3-0.5c-0.3-0.4-0.5-0.8-0.5-1.3L1.7,6.9h2.7v5.2h1.2V6.9h6.4v5.2h1.2V6.9h2.7l0.7,12C16.8,19.4,16.6,19.9,16.3,20.3
                    L16.3,20.3z"
                />
            </svg>
        );
    }
}

export default CartIconComponent;
