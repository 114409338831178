import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';

/** @namespace PeggysagePwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // ...
});

/** @namespace PeggysagePwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // ...
});

/** @namespace PeggysagePwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        ...SourceAddToCartContainer.propTypes,
        subLabel: PropTypes.string
    };

    static defaultProps = {
        ...SourceAddToCartContainer.defaultProps,
        subLabel: ''
    };

    containerProps() {
        const { subLabel } = this.props;

        return {
            ...super.containerProps(),
            subLabel
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
