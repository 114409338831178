import {
    FilterIcon as SourceFilterIcon
} from 'SourceComponent/FilterIcon/FilterIcon.component';

import './FilterIcon.override.style';

/** @namespace PeggysagePwa/Component/FilterIcon/Component */
export class FilterIconComponent extends SourceFilterIcon {
    /**
     * Override
     * @returns {JSX.Element}
     */
    render() {
        return (
            <svg
              block="FilterIcon"
              width="26.599"
              height="26.303"
              viewBox="0 0 26.599 26.303"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M111.387,37.733a3.677,3.677,0,0,0-3.527,2.716h-1.9a.95.95,0,1,0,0,1.9h1.9a3.65,3.65,0,0,0,7.049.046l15.751-.046h0a.95.95,0,0,0,0-1.9l-15.725.046a3.681,3.681,0,0,0-3.544-2.763Zm0,1.9a1.771,1.771,0,1,1-1.77,1.77A1.756,1.756,0,0,1,111.387,39.633Zm10.149,7.281a3.724,3.724,0,0,0-3.579,2.765h-12a.95.95,0,1,0,0,1.9h12a3.7,3.7,0,0,0,7.155,0h5.542a.95.95,0,0,0,0-1.9h-5.54a3.724,3.724,0,0,0-3.579-2.765Zm0,1.9a1.816,1.816,0,1,1-1.818,1.813,1.8,1.8,0,0,1,1.818-1.813ZM114.154,56.6a3.729,3.729,0,0,0-3.579,2.765H105.96a.95.95,0,1,0,0,1.9h4.613a3.7,3.7,0,0,0,7.16,0h12.923a.95.95,0,0,0,0-1.9H117.731a3.727,3.727,0,0,0-3.577-2.765Zm0,1.9a1.818,1.818,0,1,1-1.818,1.818A1.8,1.8,0,0,1,114.154,58.5Z" transform="translate(-105.006 -37.733)" />
            </svg>
        );
    }
}

export default FilterIconComponent;
