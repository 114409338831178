/* eslint-disable  simple-import-sort/sort */
import { GROUP_ID_PRO, GROUP_ID_EMPLOYEE, GROUP_ID_STUDENT } from 'Route/MyAccount/MyAccount.config';
import {
    ADD_TO_CART,
    ADD_TO_WISHLIST,
    checkEveryOption,
    getIndexedAttributeOption,
    getIndexedAttributes,
    getIndexedConfigurableOptions,
    getIndexedVariants,
    getIndexedSingleVariant,
    getVariantsIndexes,
    getVariantIndex,
    getIndexedCustomOption,
    getIndexedCustomOptions,
    getIndexedReviews,
    getBundleId,
    getBundleOptions as sourceGetBundleOptions,
    // getIndexedProduct,
    // getIndexedProducts,
    // getIndexedParameteredProducts,
    sortBySortOrder,
    getIsConfigurableParameterSelected,
    getNewParameters,
    showNewReviewPopup,
    sortAlphabetically,
    getBooleanLabel,
    filterConfigurableOptions,
    validateProductQuantity,
    getAttributesWithValues as sourceGetAttributesWithValues
} from 'SourceUtil/Product/Product';
import getStore from 'Util/Store';

export {
    ADD_TO_CART,
    ADD_TO_WISHLIST,
    checkEveryOption,
    getIndexedAttributeOption,
    getIndexedAttributes,
    getIndexedConfigurableOptions,
    getIndexedVariants,
    getIndexedSingleVariant,
    getVariantsIndexes,
    getVariantIndex,
    getIndexedCustomOption,
    getIndexedCustomOptions,
    getIndexedReviews,
    getBundleId,
    // getBundleOptions,
    // getIndexedProduct,
    // getIndexedProducts,
    // getIndexedParameteredProducts,
    sortBySortOrder,
    getIsConfigurableParameterSelected,
    getNewParameters,
    showNewReviewPopup,
    sortAlphabetically,
    getBooleanLabel,
    filterConfigurableOptions,
    validateProductQuantity
    // getAttributesWithValues
};

/**
 * Override: add verification (var bundle_options can have no selection_details since override for quick order)
 * @namespace PeggysagePwa/Util/Product/getBundleOptions */
export const getBundleOptions = (options, items) => {
    if (!options[0].selection_details) {
        return items;
    }

    return sourceGetBundleOptions(options, items);
};

/**
 * Override: copy of original function, needed to call function in this file
 * @namespace PeggysagePwa/Util/Product/getIndexedProduct */
export const getIndexedProduct = (product, itemSku) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews,
        items = [],
        bundle_options = []
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    const updatedProduct = {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: itemSku ? getIndexedSingleVariant(initialVariants, itemSku) : getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count
        }
    };

    if (bundle_options.length) {
        updatedProduct.items = getBundleOptions(bundle_options, items);
    }

    return updatedProduct;
};

/**
 * Override: copy of original function, needed to call function in this file
 * @namespace PeggysagePwa/Util/Product/getIndexedProducts */
export const getIndexedProducts = (products) => products.map((product) => getIndexedProduct(product));

/**
 * Override: copy of original function, needed to call function in this file
 * @namespace PeggysagePwa/Util/Product/getIndexedParameteredProducts */
export const getIndexedParameteredProducts = (products) => Object.entries(products)
    .reduce((products, [id, product]) => ({
        ...products,
        [id]: getIndexedProduct(product)
    }), {});

/**
 * Override: prevent error by verifying existence of variable
 * @namespace PeggysagePwa/Util/Product/getAttributesWithValues */
export const getAttributesWithValues = (product) => {
    const { attributes = {} } = product;

    if (!attributes) {
        return {};
    }

    return sourceGetAttributesWithValues(product);
};

/**
 * Return true if the current user can't purchase the product
 * @returns {boolean}
 * @namespace PeggysagePwa/Util/Product/isRestrictedToCurrentUser
 */
export const isRestrictedToCurrentUser = (product) => {
    const { produit_pro, produit_etudiant } = product;
    const store = getStore();
    const {
        MyAccountReducer: {
            customer
        } = {}
    } = store.getState();

    const userGroupId = customer?.group_id;

    const allowedGroupsIds = [];

    if (produit_pro) {
        allowedGroupsIds.push(GROUP_ID_PRO, GROUP_ID_EMPLOYEE);
    }

    if (produit_etudiant) {
        allowedGroupsIds.push(GROUP_ID_STUDENT);
    }

    // No restriction
    if (allowedGroupsIds.length === 0) {
        return false;
    }

    return !allowedGroupsIds.includes(userGroupId);
};

/** @namespace PeggysagePwa/Util/Product/getProductTags */
export const getProductTags = (product, isCustomerTypeStudentEnabled = true) => {
    const {
        tags = [], produit_etudiant, produit_pro, afficher_produit_reserve_pro
    } = product;

    const mergedTags = Array.from(tags);

    if (produit_pro) {
        mergedTags.push({ type: 'produit_pro', value: true });
    }

    if (produit_etudiant && isCustomerTypeStudentEnabled) {
        mergedTags.push({ type: 'produit_etudiant', value: true });
    }

    if (afficher_produit_reserve_pro) {
        mergedTags.push({ type: 'produit_reserve_pro', value: true });
    }

    return mergedTags;
};
