import { getEncodedBundleUid } from 'SourceUtil/Product/Transform';
import { getBundleId } from 'Util/Product/Product';

export * from 'SourceUtil/Product/Transform';

/**
 * Return an array of quantity (key = original uid of option)
 * @param selectedOptions {array} Array of uids
 * @param options {array} Array of options
 * @returns {mixed}
 * @namespace PeggysagePwa/Util/Product/Transform/getQuantityFromSelectedOptions
 */
export const getQuantityFromSelectedOptions = (selectedOptions, options) => {
    const quantity = {};

    selectedOptions.forEach((uid) => {
        const uidParts = atob(uid).split('/');
        // Quantity is the last part
        const qty = +uidParts[uidParts.length - 1];
        // Get option id to compare with the options props
        const bundleId = getBundleId(uid);

        const option = options.find((option) => getBundleId(option.uid) === bundleId);
        if (option) {
            // Get uid with default quantity (not sure if option uid is always with a qty of 1)
            const uidWithoutQuantity = getEncodedBundleUid(option.uid, option.quantity);

            quantity[uidWithoutQuantity] = qty;
        }
    });

    return quantity;
};
