import {
    ProductCustomizableOptionsContainer as SourceProductCustomizableOptionsContainer
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.container';

/** @namespace PeggysagePwa/Component/ProductCustomizableOptions/Container */
export class ProductCustomizableOptionsContainer extends SourceProductCustomizableOptionsContainer {
    // ...
}

export default ProductCustomizableOptionsContainer;
