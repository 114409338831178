/* eslint-disable max-len */
import { PureComponent } from 'react';

import './StoreLocatorIcon.style';

/** @namespace PeggysagePwa/Component/StoreLocatorIcon/Component */
export class StoreLocatorIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="StoreLocatorIcon"
              width="13"
              height="18"
              viewBox="0 0 13 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g transform="translate(-0.436 -0.14)">
                    <path d="M6.9,18.1H6.6l-0.6-0.6c-1.4-1.4-5.6-5.8-5.6-10.8c0-3.6,2.9-6.6,6.5-6.6c3.6,0,6.5,3,6.5,6.6c0,4.5-4.3,9.2-5.7,10.8c-0.2,0.2-0.3,0.3-0.3,0.4l-0.2,0.2H6.9z M6.9,1.3C4,1.3,1.6,3.7,1.6,6.7c0,0,0,0,0,0c0,4.5,4,8.6,5.3,9.9c3.5-3.9,5.3-7.3,5.3-9.9C12.2,3.8,9.9,1.3,6.9,1.3" />
                    <path d="M6.9,9.7c-1.6,0-3-1.3-3-3c0-1.7,1.3-3,3-3c1.6,0,3,1.3,3,3C9.9,8.4,8.6,9.7,6.9,9.7C7,9.7,7,9.7,6.9,9.7 M6.9,4.9c-1,0-1.8,0.8-1.8,1.8S6,8.5,6.9,8.5s1.8-0.8,1.8-1.8l0,0C8.7,5.8,8,5,6.9,4.9C7,4.9,7,4.9,6.9,4.9" />
                </g>
            </svg>
        );
    }
}

export default StoreLocatorIconComponent;
