import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/Training/Query */
export class TrainingQuery {
    getTrainingCenters() {
        const query = new Field('getTrainingCenters')
            .addFieldList(this._getTrainingCentersFields());

        return query;
    }

    /**
     * @param options : {
     *     $firstname: String!
     *     $lastname: String!
     *     $phone: String!
     *     $postcode: String!
     *     $sku: String!
     *     $shop_id: Int
     *     $date: String!
     * }
     * @returns Boolean
     */
    getRequestTrainingCallbackMutation(options) {
        return new Field('requestTrainingCallback')
            .addArgument('input', 'RequestTrainingCallbackInput!', options);
    }

    _getTrainingCentersFields() {
        return [
            'shop_id',
            'contact_secondname',
            'store_name',
            'address_street1',
            'address_street2',
            'address_postcode',
            'address_city',
            'address_country_id',
            'telephone',
            new Field('products').addFieldList(this._getTrainingFields())
        ];
    }

    _getTrainingFields() {
        return [
            'uid',
            'name',
            'sku',
            new Field('short_description').addField('html'),
            'duree_formation_jours',
            'duree_formation_heures',
            this._getTrainingDatesField()
        ];
    }

    _getTrainingDatesField() {
        return new Field('date_formation')
            .addFieldList([
                new Field('training_center').addFieldList([
                    'shop_id',
                    'contact_secondname'
                ]),
                new Field('sessions').addFieldList([
                    'date_from',
                    'date_to',
                    'available'
                ])
            ]);
    }
}

export default new TrainingQuery();
