import { UPDATE_IS_LOADING_IN_GDPR, UPDATE_PRIVACY_INFORMATIONS } from './Gdpr.action';

/** @namespace PeggysagePwa/Store/Gdpr/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: true,
    privacyInformations: {}
});

/** @namespace PeggysagePwa/Store/Gdpr/Reducer/GdprReducer */
export const GdprReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRIVACY_INFORMATIONS:
        const { data } = action;

        return {
            ...state,
            privacyInformations: data
        };
    case UPDATE_IS_LOADING_IN_GDPR:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default GdprReducer;
