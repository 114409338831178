/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
// Disabled due `domToReact` internal logic
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import React, { lazy, PureComponent, Suspense } from 'react';

import ExternalScript from 'Component/ExternalScript';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader/Loader.component';
import SliderPageBuilder from 'Component/SliderPageBuilder';
import { SLIDER_SKELETON } from 'Component/SliderPageBuilder/SliderPageBuilder.config.js';
import { hash } from 'Util/Request/Hash';

import Buttons from '../../../packages/page-builder/src/component/Buttons';
import { BUTTONS_SKELETON } from '../../../packages/page-builder/src/component/Buttons/Buttons.config';
import Dotdigital from '../../../packages/page-builder/src/component/DotdigitalForm';
import { DOTDIGITAL_FORM_SKELETON } from '../../../packages/page-builder/src/component/DotdigitalForm/Dotdigital.config';
import { DYNAMIC_BLOCK_SKELETON } from '../../../packages/page-builder/src/component/DynamicBlock/DynamicBlock.config';
import DynamicBlock from '../../../packages/page-builder/src/component/DynamicBlock/DynamicBlock.container';
import { GOOGLE_MAP_SKELETON } from '../../../packages/page-builder/src/component/GoogleMap/GoogleMap.config';
import GoogleMap from '../../../packages/page-builder/src/component/GoogleMap/GoogleMap.container';
import HtmlCode from '../../../packages/page-builder/src/component/HtmlCode';
import { HTML_CODE_SKELETON } from '../../../packages/page-builder/src/component/HtmlCode/HtmlCode.config';
// import Slider from '../../../packages/page-builder/src/component/Slider';
// import { SLIDER_SKELETON } from '../../../packages/page-builder/src/component/Slider/Slider.config';
import Tab from '../../../packages/page-builder/src/component/Tab/Tab.component';
import { TAB_SKELETON } from '../../../packages/page-builder/src/component/Tab/Tab.config';
import { isNotEmptyArr, makeId } from '../../../packages/page-builder/src/helper/functions';

export const WidgetFactory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */
    'Component/WidgetFactory'
));

/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace PeggysagePwa/Component/Html/Component
 */
export class HtmlComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired
    };

    createdOutsideElements = {};

    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        // Disable by overrideRules in '../../../packages/page-builder/src/plugin/Html.component.plugin.js':
        // We filter out img tag and implement it separately
        // because its behaviour is different from usual in page-builder
        /* {
            query: { name: ['img'] },
            replace: this.replaceImages
        }, */
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['div'] },
            replace: this.replaceDiv
        },
        { query: { dataContentType: 'buttons' }, replace: this.replaceButtons },
        { query: { dataContentType: 'tabs' }, replace: this.replaceTab },
        { query: { dataContentType: 'slider' }, replace: this.replaceSlider },
        { query: { dataContentType: 'map' }, replace: this.replaceMap },
        { query: { dataContentType: 'dynamic_block' }, replace: this.replaceDynamicBlock },
        { query: { dataContentType: 'dotdigitalgroup_form' }, replace: this.replaceDotdigitalForm },
        { query: { dataContentType: 'html' }, replace: this.replaceHtmlCode }
    ];

    // Override parserOptions:
    // - Add our rules which is based on data-content-type attr
    // - Process special attr such as: data-background-images
    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            this.replaceSpecialDomAttrs(domNode);

            const rule1 = this.rules.find((rule) => {
                const { query: { dataContentType } } = rule;

                return dataContentType && domAttrs && domAttrs['data-content-type'] === dataContentType;
            });

            if (rule1) {
                const { replace } = rule1;

                return replace.call(this, domNode);
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        }
    };

    decodeUrl(value) {
        // eslint-disable-next-line fp/no-let
        let result = '';
        const cleanValue = decodeURIComponent((value).replace(window.location.href, ''));
        const regexp = /{{.*\s*url="?(.*\.([a-z|A-Z]*))"?\s*}}/;
        if (regexp.test(cleanValue)) {
            const [, url] = regexp.exec(cleanValue);
            result = `media/${ url }`;
        }

        return result;
    }

    replaceSpecialDomAttrs(domNode) {
        const { attribs: domAttrs } = domNode;
        if (!domAttrs || Object.keys(domAttrs).length === 0) {
            return;
        }
        if (domAttrs['data-background-images']) {
            this.handleDynamicBackgroundImages(domAttrs, domNode);
        }
    }

    // Magento page-builder is using the below objects:
    // {"desktop_image": "http://host/media/wysiwyg/background.jpg", "mobile_image": "http://host/media/wysiwyg/banner-1.jpg"}
    // OR {"desktop_image": "{{media url=wysiwyg/wide-banner-background.jpg}}"}
    // to generate 2 unique classnames for desktop & mobile.
    // Let just generate 1 unique classname and use media-query for mobile
    handleDynamicBackgroundImages(domAttrs, domNode) {
        try {
            // eslint-disable-next-line
            const images = JSON.parse(domAttrs['data-background-images'].replace(/\\(.)/mg, "$1")) || {};
            // eslint-disable-next-line no-magic-numbers
            const uniqClassName = `bg-image-${ makeId(5) }`;
            // eslint-disable-next-line fp/no-let
            let css = '';
            if (images.desktop_image) {
                // Sometimes magento returns an stringify object instead of a string. We need to decode to a URL string
                const imageUrl = this.decodeUrl(images.desktop_image) || images.desktop_image;
                css += `
                    .${uniqClassName} {
                        background-image: url(${imageUrl});
                    }
                    `;
            }
            if (images.mobile_image) {
                const imageUrl = this.decodeUrl(images.mobile_image) || images.mobile_image;
                css += `
                    @media only screen and (max-width: 810px) {
                        .${uniqClassName} {
                            background-image: url(${imageUrl});
                        }
                    }
                    `;
            }
            // Let's add it to our <head /> tag
            if (css) {
                const { head } = document;
                const style = document.createElement('style');
                head.appendChild(style);
                style.type = 'text/css';
                if (style.styleSheet) {
                    // This is required for IE8 and below.
                    style.styleSheet.cssText = css;
                } else {
                    style.appendChild(document.createTextNode(css));
                }
                // eslint-disable-next-line no-param-reassign
                domNode.attribs.class = `${domNode.attribs.class || ''} ${uniqClassName}`;
            }
        } catch (_e) {
            // Just forget it
            // console.log(e);
        }
    }

    // options obj: {isInLoop: boolean, allowedTypes: ('tag'|'script'|'style')[]}.
    // The idea is:
    // - For individual element, we create a React Element and store all of its props.
    // - For in-loop elements (data.map(() => <div />). We create just the first element
    // then store all of its sibling's props to a bag.
    // The result is we will have the same HTML structure in React Element. So that we can
    // use React to manipulate these elements freely
    toReactElements(domNodes, skeleton, options = {}, res = {}) {
        const {
            isInLoop = false,
            // Sometimes, page-builder html code contains un-sanitize chars from script or style tags,
            // which makes our parser run incorrectly.
            // Most of the time, we don't need them so that we limit to "tag" by default
            allowedTypes = ['tag']
        } = options;

        // eslint-disable-next-line fp/no-let
        let result = res;

        // eslint-disable-next-line fp/no-let
        let skeletonIdx = 0; // Index to help mapping current domNode with our skeleton config
        domNodes.forEach((domNode) => {
            if (allowedTypes.indexOf(domNode.type) === -1) {
                return;
            }
            // Begin
            this.replaceSpecialDomAttrs(domNode);

            const config = skeleton[skeletonIdx] || skeleton[0];
            skeletonIdx += 1;

            const orgProps = this.attributesToProps(domNode.attribs || {});

            // Create element if not existed
            if (!result[config.name]) {
                // eslint-disable-next-line react/prop-types
                const element = React.forwardRef(({ children, ...rest }, ref) => (
                    React.createElement(domNode.name, { ...(!isInLoop && orgProps), ...rest, ref }, children)
                ));

                result[config.name] = {
                    Ele: element, propsBag: [], childData: [], childEleBag: []
                };
            }

            // Generate all children nodes if our skeleton reached the end
            // in order to render these children nodes later on
            const childEle = (!config.children && domNode.children)
                ? domToReact(domNode.children, this.parserOptions) : null;

            const childData = ((isInLoop || config.isLoopParent) && isNotEmptyArr(domNode.children))
                ? domNode.children.map((i) => i.data) : null;

            result[config.name] = {
                ...result[config.name],
                propsBag: [...result[config.name].propsBag, orgProps],
                childEleBag: [...result[config.name].childEleBag, childEle],
                childData: [...result[config.name].childData, ...(childData || [])]
            };

            if (domNode.children && config.children) {
                const childRes = this.toReactElements(domNode.children, config.children, {
                    isInLoop: (isInLoop || config.isLoopParent),
                    allowedTypes
                }, result);

                result = { ...result, ...childRes };
            }
        });

        return result;
    }

    attributesToProps(attribs) {
        const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());

        const convertPropertiesToValidFormat = (properties) => Object.entries(properties)
            .reduce((validProps, [key, value]) => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value)) {
                    return { ...validProps, [toCamelCase(key)]: +value };
                }

                return { ...validProps, [toCamelCase(key)]: value };
            }, {});

        const properties = convertPropertiesToValidFormat(attribs);

        return attributesToProps(properties);
    }

    scrollToTopFunction() {
        document.documentElement.scrollIntoView();
    }

    /**
     * Replace links to native React Router links
     * @param  {{ attribs: Object, children: Array }}
     * @return {void|JSX} Return JSX if link is allowed to be replaced
     * @memberof Html
     */
    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        const { class: attrClass } = attrs;
        if (attrClass === '_blank' || (Array.isArray(attrClass) && attrClass.includes('_blank'))) {
            attrs.target = '_blank';
        }

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);
            const isAnchorLink = (value) => new RegExp('^#', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href) && !isAnchorLink(href)) {
                return (
                    <Link
                      onClick={ this.scrollToTopFunction }
                      { ...attributesToProps({ ...attrs, to: href }) }
                    >
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }

            return (
                <a { ...attributesToProps({ ...attrs, href }) }>
                    { domToReact(children, this.parserOptions) }
                </a>
            );
        }
    }

    /**
     * Replace img to React Images
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            return <Image { ...attributes } isPlain />;
        }
    }

    /**
     * Replace input.
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceInput({ attribs }) {
        return <input { ...attributesToProps(attribs) } />;
    }

    /**
     * Wrap table in container
     *
     * @param attribs
     * @param children
     * @returns {*}
     */
    wrapTable({ attribs, children }) {
        return (
            <div block="Table" elem="Wrapper">
                <table { ...attributesToProps(attribs) }>
                    { domToReact(children, this.parserOptions) }
                </table>
            </div>
        );
    }

    /**
     * Insert corresponding widget
     *
     * @param {{ attribs: Object }} { attribs }
     * @returns {null|JSX} Return Widget
     * @memberof Html
     */
    replaceWidget({ attribs }) {
        return (
            <Suspense fallback={ <Loader isLoading /> }>
                <WidgetFactory { ...this.attributesToProps(attribs) } />
            </Suspense>
        );
    }

    /**
     * Override: verify existence of children
     * @param elem
     * @returns {JSX.Element}
     */
    replaceStyle(elem) {
        const { children } = elem;

        if (!children || !children.length) {
            return <></>;
        }

        const elemHash = hash(children[0].data);

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const style = document.createElement('style');

        if (children && children[0]) {
            style.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(style);
        this.createdOutsideElements[elemHash] = true;

        return <></>;
    }

    /**
     * Override: remove test isNaN
     * @param elem
     * @returns {JSX.Element}
     */
    replaceScript(elem) {
        const { attribs, children } = elem;
        const { src = '' } = attribs;
        const scriptContent = children[0] ? children[0].data : '';
        const elemHash = hash(src + scriptContent);

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            script.appendChild(document.createTextNode(children[0].data));
        }

        if (attribs.src) {
            const attrId = `script-${elemHash}`;

            this.createdOutsideElements[elemHash] = true; // security

            return (
                <ExternalScript
                  src={ attribs.src }
                  attrId={ attrId }
                />
            );
        }

        // if (!Number.isNaN(Number(script))) {
        document.head.appendChild(script);
        // }

        this.createdOutsideElements[elemHash] = true;

        return <></>;
    }

    /**
     * Peggysage: interpret html from page builder (originally string with html entities)
     * @param attribs
     * @param children
     * @returns {JSX.Element}
     */
    replaceDiv({ attribs, children }) {
        if (attribs && attribs['data-content-type'] === 'html' && !!children[0]) {
            return (
                <div { ...this.attributesToProps(attribs) }>
                    <HtmlComponent content={ children[0].data } />
                </div>
            );
        }

        return (
            <div { ...this.attributesToProps(attribs) }>
                { children && domToReact(children, this.parserOptions) }
            </div>
        );
    }

    replaceTab(domNode) {
        return <Tab elements={ this.toReactElements([domNode], TAB_SKELETON) } />;
    }

    replaceSlider(domNode) {
        // return <Slider elements={ this.toReactElements([domNode], SLIDER_SKELETON) } />;
        return <SliderPageBuilder elements={ this.toReactElements([domNode], SLIDER_SKELETON) } />;
    }

    replaceMap(domNode) {
        return <GoogleMap elements={ this.toReactElements([domNode], GOOGLE_MAP_SKELETON) } />;
    }

    replaceDynamicBlock(domNode) {
        return <DynamicBlock elements={ this.toReactElements([domNode], DYNAMIC_BLOCK_SKELETON) } />;
    }

    replaceDotdigitalForm(domNode) {
        return (
            <Dotdigital
              elements={ this.toReactElements([domNode], DOTDIGITAL_FORM_SKELETON, { allowedTypes: ['tag', 'script'] }) }
            />
        );
    }

    replaceHtmlCode(domNode) {
        return (
            <HtmlCode
              elements={ this.toReactElements([domNode], HTML_CODE_SKELETON, { allowedTypes: ['tag', 'script'] }) }
            />
        );
    }

    replaceButtons(domNode) {
        return <Buttons elements={ this.toReactElements([domNode], BUTTONS_SKELETON) } />;
    }

    render() {
        const { content } = this.props;

        if (typeof content !== 'string') {
            return null;
        }

        return parser(content, this.parserOptions);
    }
}

export default HtmlComponent;
