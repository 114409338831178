/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import React from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace PeggysagePwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    static propTypes = {
        ...SourcePopup.propTypes,
        hasBackButton: PropTypes.bool.isRequired,
        onBackButtonClick: PropTypes.func.isRequired
    };

    renderBackButton() {
        const { hasBackButton, onBackButtonClick } = this.props;

        if (!hasBackButton) {
            return null;
        }

        return (
            <button
              type="button"
              block="Popup"
              elem="BackBtn"
              aria-label={ __('Back') }
              onClick={ onBackButtonClick }
            >
                <ChevronIcon direction={ LEFT } />
                { __('Back') }
            </button>
        );
    }

    /**
     * Override: add back button
     */
    renderTitle() {
        return (
            <>
                { this.renderBackButton() }
                { super.renderTitle() }
            </>
        );
    }

    hidePopupAndGoBack() {
        this.hidePopUp();
    }

    /**
     * Override: store popup id and payload in history state,
     * use replaceState instead of pushState if there is a back button, to prevent multiple useless states
     */
    onVisible() {
        const { onVisible, id, hasBackButton } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        const stateObj = {
            popupOpen: true,
            id
        };
        const url = `${location.pathname}${location.search}${location.hash}`;

        if (hasBackButton) {
            window.history.replaceState(stateObj, '', url);
        } else {
            window.history.pushState(stateObj, '', url);
        }

        onVisible();
    }

    /**
     * Override:  wrap content in div in order to fix the header
     * @return {JSX.Element|null}
     */
    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    <div block="Popup" elem="Main">
                        { this.renderNotifications() }
                        { children }
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
