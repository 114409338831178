import {
    ORDER_ACTION_LABELS,
    ORDER_ID,
    ORDER_INVOICES,
    ORDER_ITEMS,
    ORDER_REFUNDS,
    ORDER_SHIPMENTS
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.config';

export {
    ORDER_ACTION_LABELS,
    ORDER_ID,
    ORDER_INVOICES,
    ORDER_ITEMS,
    ORDER_REFUNDS,
    ORDER_SHIPMENTS
};
