import PropTypes from 'prop-types';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    CUSTOMER_GROUP_LABELS, GROUP_ID_EMPLOYEE, GROUP_ID_PRO, GROUP_ID_STUDENT
} from 'Route/MyAccount/MyAccount.config';
import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
} from 'SourceComponent/ProductPrice/ProductPrice.config';
import { TierPricesType } from 'Type/Price.type';
import { formatPrice, roundPrice } from 'Util/Price/Price';

import './ProductPrice.override.style';

/** @namespace PeggysagePwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    static propTypes = {
        ...SourceProductPrice.propTypes,
        userGroupId: PropTypes.number.isRequired,
        tierPrices: TierPricesType
    };

    static defaultProps = {
        ...SourceProductPrice.defaultProps,
        tierPrices: []
    };

    /**
     * Override: remove old price for bundles
     * @return {JSX.Element}
     */
    renderBundlePrice() {
        const {
            originalPrice: {
                minFinalPrice = {},
                minFinalPrice: { value: minValue = 0 } = {},
                maxFinalPrice = {},
                maxFinalPrice: { value: maxValue = 0 } = {},
                minFinalPriceExclTax = {},
                maxFinalPriceExclTax = {}
                // minRegularPrice = {},
                // maxRegularPrice = {},
                // minRegularPrice: { value: minRegularValue = 0 } = {},
                // maxRegularPrice: { value: maxRegularValue = 0 } = {}
            }
        } = this.props;

        if (minValue === maxValue) {
            const renderer = (minValue === 0)
                ? this.renderDefaultPrice()
                : this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax);

            return (
                <>
                    { /* minValue < minRegularValue && this.renderRegularPrice(minRegularPrice) */ }
                    { renderer }
                </>
            );
        }

        return (
            <>
                <div
                  block="ProductPrice"
                  elem="BundleFrom"
                  // mods={ { hasDiscount: minValue < minRegularValue } }
                >
                    { minValue > 0 && this.renderPriceBadge(__('from')) }
                    { /* minValue < minRegularValue && this.renderRegularPrice(minRegularPrice) */ }
                    { this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax) }
                </div>
                <div
                  block="ProductPrice"
                  elem="BundleTo"
                  // mods={ { hasDiscount: maxValue < maxRegularValue } }
                >
                    { maxValue > 0 && this.renderPriceBadge(__('to')) }
                    { /* maxValue < maxRegularValue && this.renderRegularPrice(maxRegularPrice) */ }
                    { this.renderPriceWithOrWithoutTax(maxFinalPrice, maxFinalPriceExclTax) }
                </div>
            </>
        );
    }

    renderSpecialPriceWithoutDiscount() {
        const {
            price: {
                finalPrice: {
                    value: finalPrice,
                    currency
                } = {},
                finalPriceExclTax: {
                    value: finalPriceExclTax
                } = {}
            } = {},
            tierPrices,
            priceType,
            userGroupId,
            displayTaxInPrice
        } = this.props;

        if (priceType === PRODUCT_TYPE.bundle
            || ![GROUP_ID_PRO, GROUP_ID_STUDENT, GROUP_ID_EMPLOYEE].includes(userGroupId)
            || !tierPrices
            || tierPrices.length === 0) {
            return null;
        }

        const tierPricesValues = tierPrices.map(({ final_price: { value = 0 } = {} }) => value);
        const minTierPriceValue = Math.min(...tierPricesValues);

        const finalPriceValue = displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
            ? finalPriceExclTax : finalPrice;

        if (roundPrice(finalPriceValue) !== roundPrice(minTierPriceValue)) {
            return (
                <del
                  block="ProductPrice"
                  elem="Price"
                  mods={ { isOldPrice: true } }
                  data-label={ displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
                      ? __('Excl. Tax') : __('Incl. Tax') }
                  className={ displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
                      ? 'price-excluding-tax' : 'price-including-tax' }
                >
                    <span block="ProductPrice" elem="PriceValue">
                        { formatPrice(minTierPriceValue, currency) }
                    </span>
                </del>
            );
        }

        return null;
    }

    /**
     * Add Tax in data attribute
     * @param price
     * @param label
     * @returns {JSX.Element|null}
     */
    renderPrice(price, label) {
        const {
            discountPercentage,
            displayTaxInPrice
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        return (
            <>
                { this.renderSpecialPriceWithoutDiscount() }
                <PriceSemanticElementName
                  block="ProductPrice"
                  elem="Price"
                  data-label={ displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
                      ? __('Excl. Tax') : __('Incl. Tax') }
                  className={ displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
                      ? 'price-excluding-tax' : 'price-including-tax' }
                >
                    { this.renderPriceBadge(label) }
                    <span
                      itemProp={ itemProp }
                      content={ content }
                      block="ProductPrice"
                      elem="PriceValue"
                    >
                        { priceFormatted }
                    </span>
                </PriceSemanticElementName>
            </>
        );
    }

    /**
     * Render subprice as default price (same DOM)
     * @param price
     * @returns {JSX.Element|null}
     */
    renderSubPrice(price) {
        const {
            discountPercentage
        } = this.props;

        const {
            value: priceExclTax = 0,
            valueFormatted: priceExclTaxFormatted = 0
        } = price;

        if (!priceExclTax) {
            return null;
        }

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        const label = '';

        return (
            <PriceSemanticElementName
              block="ProductPrice"
              elem="Price"
              data-label={ __('Excl. Tax') }
              className="price-excluding-tax"
            >
                { this.renderPriceBadge(label) }
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                  aria-label={ __('Current product price excl. tax') }
                >
                    { priceExclTaxFormatted }
                </span>
            </PriceSemanticElementName>
        );
    }

    /**
     * Override: custom display for pro/student
     * @returns {JSX.Element|null}
     */
    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            userGroupId
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        if ([GROUP_ID_PRO, GROUP_ID_STUDENT, GROUP_ID_EMPLOYEE].includes(userGroupId)) {
            return (
                <span
                  block="ProductPrice"
                  elem="Price"
                  mods={ { isPublic: true } }
                  aria-label={ __('Public price') }
                  itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
                  data-label={ __('Incl. Tax') }
                >
                    <span block="ProductPrice" elem="PriceValue">
                        { `${__('Public price:')} ${originalPriceFormatted}` }
                    </span>
                </span>
            );
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { originalPriceFormatted }
            </del>
        );
    }

    /**
     * Override: move old price under final price
     * @param defaultLabel
     * @returns {JSX.Element}
     */
    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label
        } = this.props;

        return (
            <>
                { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                { this.renderOldPrice() }
                { this.renderSchema() }
            </>
        );
    }

    /**
     * Override: remove tiers price
     */
    renderTierPrice() {
        return null;
    }

    renderUserGroupLabel() {
        const { userGroupId } = this.props;

        /* if (![GROUP_ID_PRO, GROUP_ID_STUDENT].includes(userGroupId)) {
            return null;
        } */

        if (userGroupId === GROUP_ID_EMPLOYEE) {
            const groupLabel = CUSTOMER_GROUP_LABELS[GROUP_ID_PRO]?.singular.toLowerCase() || '';
            return (
                <span block="ProductPrice" elem="UserGroupLabel">
                { __('%s price', groupLabel) }
                </span>
            );
        }

        const groupLabel = CUSTOMER_GROUP_LABELS[userGroupId]?.singular.toLowerCase() || '';

        if (!groupLabel) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="UserGroupLabel">
                { __('%s price', groupLabel) }
            </span>
        );
    }

    /**
     * Override: add class and label for pro and student prices
     * @returns {JSX.Element|*}
     */
    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0
                } = {}
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
            userGroupId
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ {
                  hasDiscount: discountPercentage !== 0,
                  isPreview,
                  isPro: userGroupId === GROUP_ID_PRO || userGroupId === GROUP_ID_EMPLOYEE,
                  isStudent: userGroupId === GROUP_ID_STUDENT
              } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { this.renderUserGroupLabel() }
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
