import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { GROUP_ID_EMPLOYEE, GROUP_ID_PRO, GROUP_ID_STUDENT } from 'Route/MyAccount/MyAccount.config';
import { DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX } from 'SourceComponent/ProductPrice/ProductPrice.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    // mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { CustomerType } from 'Type/Account.type';
import BrowserDatabase from 'Util/BrowserDatabase';
// import { isSignedIn } from 'Util/Auth';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace PeggysagePwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // ...sourceMapStateToProps(state),
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    customer: state.MyAccountReducer.customer
});

/** @namespace PeggysagePwa/Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    )
});

/** @namespace PeggysagePwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static propTypes = {
        ...SourceProductPriceContainer.propTypes,
        isSignedIn: PropTypes.bool.isRequired,
        customer: CustomerType.isRequired,
        requestCustomerData: PropTypes.func.isRequired
    };

    containerProps() {
        const { customer: { group_id = 0 } = {}, tierPrices } = this.props;
        const { displayTaxInPrice } = this.state;

        return {
            ...super.containerProps(),
            displayTaxInPrice,
            userGroupId: group_id,
            tierPrices
        };
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            displayTaxInPrice: this.getDisplayTaxInPrice(props)
        };
    }

    componentDidMount() {
        this.updateTaxDisplay();
    }

    /**
     * Display Incl. Tax for anonymous or B2C users
     */
    componentDidUpdate(prevProps) {
        const { customer: { group_id: groupId } = {}, displayTaxInPrice } = this.props;
        const { customer: { group_id: prevGroupId } = {}, displayTaxInPrice: prevDisplayTaxInPrice } = prevProps;

        /* const {
            requestCustomerData,
            customer
        } = this.props;

        if (isSignedIn() && !Object.keys(customer).length) {
            requestCustomerData();
        }

        if (customer && ![GROUP_ID_PRO, GROUP_ID_STUDENT].includes(customer.group_id)) {
            this.setState({ displayTaxInPrice: DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX });
        } */

        if (groupId !== prevGroupId || displayTaxInPrice !== prevDisplayTaxInPrice) {
            this.updateTaxDisplay();
        }
    }

    /**
     * Get tax display directly from browser config as state is not always updated... (broadcast problem ?)
     * @returns {never}
     */
    getDisplayTaxInPrice(props = null) {
        const { displayTaxInPrice } = props || this.props;
        const config = BrowserDatabase.getItem('config');
        const {
            storeConfig: {
                priceTaxDisplay: {
                    product_price_display_type: configDisplayTaxInPrice = null
                } = {}
            } = {}
        } = config || {};

        return configDisplayTaxInPrice || displayTaxInPrice;
    }

    updateTaxDisplay() {
        const { customer: { group_id: groupId } = {} } = this.props;

        const newDisplayTaxInPrice = ![GROUP_ID_PRO, GROUP_ID_STUDENT, GROUP_ID_EMPLOYEE].includes(groupId)
            ? DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
            : this.getDisplayTaxInPrice();

        this.setState({ displayTaxInPrice: newDisplayTaxInPrice });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
