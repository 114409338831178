/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import AlmaPayment from 'Component/AlmaPayment';
import { ALMAPAYMENT_CREDIT_CART } from 'Component/AlmaPayment/AlmaPayment.config';
import BNPMercanet from 'Component/BNPMercanet';
import { BNPMERCANET_CREDIT_CART } from 'Component/BNPMercanet/BNPMercanet.config';
import Ingenico from 'Component/Ingenico';
import { INGENICO_CREDIT_CARD, INGENICO_EPAYMENTS } from 'Component/Ingenico/Ingenico.config';
import PayPal from 'Component/PayPal';
import { PAYPAL_EXPRESS } from 'Component/PayPal/PayPal.config';
import Worldline from 'Component/Worldline';
import {
    WORDLINE_CREDIT_CARD,
    WORDLINE_HOSTED_CHECKOUT,
    WORDLINE_REDIRECT_PAYMENT
} from 'Component/Worldline/Worldline.config';

export const renderDefault = (instance, args, callback) => {
    const {
        selectedPaymentCode
    } = instance.props;

    // console.log(selectedPaymentCode);

    if (![
        PAYPAL_EXPRESS,
        WORDLINE_HOSTED_CHECKOUT,
        WORDLINE_CREDIT_CARD,
        WORDLINE_REDIRECT_PAYMENT,
        BNPMERCANET_CREDIT_CART,
        ALMAPAYMENT_CREDIT_CART,
        INGENICO_CREDIT_CARD,
        INGENICO_EPAYMENTS
    ].includes(selectedPaymentCode)) {
        return callback.apply(instance, args);
    }

    return null;
};

export const renderPayPal = (instance) => {
    const {
        setLoading,
        setDetailsStep,
        selectedPaymentCode,
        areTermsAccepted,
        formRef
    } = instance.props;

    if (PAYPAL_EXPRESS === selectedPaymentCode) {
        return (
            <PayPal
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
              areTermsAccepted={ areTermsAccepted }
              formRef={ formRef }
              key="PayPal"
            />
        );
    }

    return null;
};

export const renderWorldline = (instance) => {
    const {
        setLoading,
        setDetailsStep,
        selectedPaymentCode,
        areTermsAccepted,
        formRef
    } = instance.props;

    if ([WORDLINE_HOSTED_CHECKOUT, WORDLINE_CREDIT_CARD, WORDLINE_REDIRECT_PAYMENT].includes(selectedPaymentCode)) {
        return (
            <Worldline
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
              areTermsAccepted={ areTermsAccepted }
              formRef={ formRef }
              key="Worldline"
            />
        );
    }

    return null;
};

export const renderBNPMercanet = (instance) => {
    const {
        setLoading,
        setDetailsStep,
        selectedPaymentCode,
        areTermsAccepted,
        formRef
    } = instance.props;

    if (BNPMERCANET_CREDIT_CART === selectedPaymentCode) {
        return (
            <BNPMercanet
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
              areTermsAccepted={ areTermsAccepted }
              formRef={ formRef }
              key="BNPMercanet"
            />
        );
    }
    if (ALMAPAYMENT_CREDIT_CART === selectedPaymentCode) {
        return (
            <AlmaPayment
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
              areTermsAccepted={ areTermsAccepted }
              formRef={ formRef }
              key="AlmaPayment"
            />
        );
    }

    return null;
};

export const renderIngenico = (instance) => {
    const {
        setLoading,
        setDetailsStep,
        selectedPaymentCode,
        areTermsAccepted,
        formRef
    } = instance.props;

    if ([INGENICO_CREDIT_CARD, INGENICO_EPAYMENTS].includes(selectedPaymentCode)) {
        return (
            <Ingenico
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
              areTermsAccepted={ areTermsAccepted }
              formRef={ formRef }
              key="Ingenico"
            />
        );
    }

    return null;
};

const renderActions = (args, callback, instance) => [
    renderDefault(instance, args, callback),
    renderPayPal(instance),
    renderWorldline(instance),
    renderBNPMercanet(instance),
    renderIngenico(instance)
];

export const config = {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderActions
        }
    }
};

export default config;
