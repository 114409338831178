import OrderQuery from 'Query/Order.query';
// import ProductListQuery from 'Query/ProductList.query';
import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    /**
     * Override: add order details field
     * @param cartId
     * @returns {*}
     */
    getPlaceOrderMutation(cartId) {
        const mutation = super.getPlaceOrderMutation(cartId);

        mutation
            .addField(this._getOrderDetailsField())
            .addField('payment_redirect_url')
            .addField('payment_external_token');

        return mutation;
    }

    getExternalPaymentOrderDetailsQuery(token) {
        return new Field('GetExternalPaymentOrderDetails')
            .addArgument('token', 'String!', token)
            .addField(this._getOrderField())
            .addField(this._getOrderDetailsField());
    }

    setExternalPaymentOrderValidated(token) {
        return new Field('SetExternalPaymentOrderValidated')
            .addArgument('token', 'String!', token)
            .addField(this._getOrderField())
            .addField(this._getOrderDetailsField());
    }

    /**
     * Override: change order_id by order_number
     * @returns {Field}
     * @private
     */
    _getOrderField() {
        return new Field('order')
            .addFieldList([
                // 'order_number'
                new Field('order_number').setAlias('order_id')
            ]);
    }

    _getOrderDetailsField() {
        return new Field('orderDetails')
            .addFieldList(this._getOrderDetailsFields());
    }

    _getOrderDetailsFields() { // like _getSingleOrderFields() without invoices, refunds, comments...
        return [
            ...OrderQuery._getOrderItemsFields(),
            OrderQuery._getOrderTimelineField(), // NEW
            OrderQuery._getOrderItemsProductsField(),
            OrderQuery._getOrderShippingAddressField(),
            OrderQuery._getOrderBillingAddressField(),
            OrderQuery._getOrderPaymentMethodsField(),
            OrderQuery._getOrderShippingMethodField(),
            OrderQuery._getOrderShippingMethodCodeField(),
            'validation_consentement_produits_pro',
            'necessite_consentement_produits_pro',
            'paiement_consentement_produits_pro',
            'customer_email'
            // OrderQuery._getOrderCommentsField()
        ];
    }

    /**
     * Override
     * @returns {string[]}
     * @private
     */
    _getEstimatedShippingFields() {
        return [
            ...super._getEstimatedShippingFields(),
            'method_type',
            'method_group',
            'delivery_date'
        ];
    }

    getLocationSuggestion(keyword) {
        const query = new Field('getLocationSuggestion')
            .addArgument('keyword', 'String!', keyword)
            .addFieldList(this._getLocationSuggestionFields());

        return query;
    }

    _getLocationSuggestionFields() {
        return ['id', 'postcode', 'city'];
    }

    searchRelay(cart_id, postcode_id) {
        const query = new Field('searchRelay')
            .addArgument('cart_id', 'String!', cart_id)
            .addArgument('postcode_id', 'Int!', postcode_id)
            .addFieldList(this._getSearchRelayFields());

        return query;
    }

    searchFavoriteShop(postcode_id) {
        const query = new Field('searchFavoriteShop')
            .addArgument('postcode_id', 'Int!', postcode_id)
            .addFieldList(this._getSearchRelayFields());

        return query;
    }

    getShopInfo(shop_id) {
        const query = new Field('getShopInfo')
            .addArgument('shop_id', 'Int!', shop_id)
            .addFieldList(this._getSearchRelayFields());

        return query;
    }

    getCustomerShopInfo() {
        const query = new Field('getCustomerShopInfo')
            .addFieldList(this._getSearchRelayFields());

        return query;
    }

    _getSearchRelayFields() {
        return [
            'id',
            'type',
            'name',
            'city',
            'street',
            'postcode',
            'country_code',
            'latitude',
            'longitude',
            'hours',
            'distance'
        ];
    }

    setShippingMethodsOnCart(cart_id, carrier_code, method_code) {
        const input = { cart_id, shipping_methods: [{ carrier_code, method_code }] };
        const mutation = new Field('setShippingMethodsOnCart')
            .addArgument('input', 'SetShippingMethodsOnCartInput', input)
            .addField(
                new Field('cart')
                    .addField('selected_shipping_method')
                    .addField(this._getShippingPhoneInfo())
            );

        return mutation;
    }

    setShippingPhoneOnCart(cart_id, phone_number) {
        const input = { cart_id, phone_number };
        const mutation = new Field('setShippingPhoneOnCart')
            .addArgument('input', 'SetShippingPhoneOnCartInput', input)
            .addField(
                new Field('cart')
                    .addField('selected_shipping_method')
                    .addField(this._getShippingPhoneInfo())
            );

        return mutation;
    }

    getSaveRelay(cart_id, relay) {
        const mutation = new Field('saveRelay')
            .addArgument('cart_id', 'String!', cart_id)
            .addArgument('relay', 'RelayInput!', relay)
            .addFieldList(this._getSaveRelayFields());

        return mutation;
    }

    _getShippingPhoneInfo() {
        return new Field('shipping_phone')
            .addFieldList([
                'phone_asked',
                'phone_required',
                'mobile_required',
                'phone_number'
            ]);
    }

    _getSaveRelayFields() {
        return [
            'email',
            new Field('relay_info').addFieldList([
                'relay_id',
                'relay_type'
            ]),
            new Field('shipping_addresses').addFieldList([
                'firstname',
                'lastname',
                'company',
                'street',
                'postcode',
                'city',
                new Field('country').addFieldList([
                    'code'
                ]),
                'telephone'
            ]),
            'selected_shipping_method'
        ];
    }

    getCheapestShippingMethod() {
        return new Field('getCheapestShippingMethod')
            .addFieldList(this._getEstimatedShippingFields());
    }

    getProcessHCRedirectRequestMutation(input) {
        return new Field('processHCRedirectRequest')
            .addArgument('input', 'ProcessRequestInput!', input)
            .addField('redirect_url')
            .addField('reserved_order_id');
    }

    getProcessCCCreateRequest(input) {
        return new Field('processCCCreateRequest')
            .addArgument('input', 'ProcessRequestInput!', input)
            .addField('redirect_url');
    }

    getProcessRPRedirectRequestMutation(input) {
        return new Field('processRPRedirectRequest')
            .addArgument('input', 'ProcessRequestInput!', input)
            .addField('redirect_url');
    }

    _getPaymentMethodFields() {
        return ['code', 'title', 'description'];
    }
}

export default new CheckoutQuery();
