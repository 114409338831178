import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace PeggysagePwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    // ...
}

export default LoaderComponent;
