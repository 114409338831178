import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { RefType } from 'Type/Common.type';

import './ExternalScript.style';

/** @namespace PeggysagePwa/Component/ExternalScript/Component */
export class ExternalScriptComponent extends PureComponent {
    static propTypes = {
        attrId: PropTypes.string.isRequired,
        elemRef: RefType.isRequired
    };

    render() {
        const { attrId, elemRef } = this.props;

        return (
            <div block="ExternalScript" id={ attrId } ref={ elemRef } />
        );
    }
}

export default ExternalScriptComponent;
