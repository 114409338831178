/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class MatomoQuery {
    _getMatomoConfigurationFields = () => ([
        'enabled',
        'site_id',
        'path',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'matomo_general_init',
        'matomo_impressions',
        'matomo_product_click',
        'matomo_product_detail',
        'matomo_product_add_to_cart',
        'matomo_product_remove_from_cart',
        'matomo_purchase',
        'matomo_checkout',
        'matomo_checkout_option',
        'matomo_user_login',
        'matomo_user_register',
        'matomo_not_found',
        'matomo_category_filters',
        'matomo_additional'
    ]);

    getMatomoConfiguration = () => new Field('getMatomo')
        .setAlias('matomo')
        .addFieldList(this._getMatomoConfigurationFields());
}

export default new MatomoQuery();
