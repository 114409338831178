import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import FieldNumberWithControls from './FieldNumberWithControls.component';

/** @namespace PeggysagePwa/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onInputFocus: this.onInputFocus.bind(this),
        onInputBlur: this.onInputBlur.bind(this),
        onInputKeyDown: this.onInputKeyDown.bind(this)
    };

    /**
     * Override: set input value
     * @param value
     * @returns {number|*|null}
     */
    setValue(value) {
        const {
            attr: { min = 0, max = DEFAULT_MAX_PRODUCTS } = {}
        } = this.props;

        const { value: stateValue } = this.state;

        // eslint-disable-next-line no-nested-ternary
        const rangedValue = value <= min ? min : value > max ? max : value;

        if (stateValue >= 0) {
            this.fieldRef.value = rangedValue;
            this.setState({ value: rangedValue });

            return rangedValue;
        }

        return null;
    }

    /**
     * Override: stop event propagation
     * @param value
     * @param e
     */
    handleValueChange(value, e) {
        const {
            events: { onChange } = {}
        } = this.props;

        // Prevent hypothetical container Link from triggering redirect
        e.stopPropagation();
        e.preventDefault();

        const newValue = this.setValue(value);

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    onInputFocus(e) {
        const {
            events: { onFocus } = {}
        } = this.props;

        // Prevent hypothetical container Link from triggering redirect
        e.stopPropagation();
        e.preventDefault();

        if (typeof onFocus === 'function') {
            onFocus(e);
        }
    }

    onInputBlur(e) {
        const {
            events: { onBlur, onChange } = {}
        } = this.props;

        const newValue = this.setValue(+e.target.value);

        if (typeof onChange === 'function') {
            onChange(newValue);
        }

        if (typeof onBlur === 'function') {
            onBlur(e);
        }
    }

    onInputKeyDown(e) {
        if (e.key === 'Enter') {
            this.fieldRef.blur();
        }
    }
}

export default FieldNumberWithControls;
