import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import PayPalQuery from 'Query/PayPal.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { RefType } from 'Type/Common.type';
import { TotalsType } from 'Type/MiniCart.type';
import { isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import { setPaymentToken } from 'Util/PaymentTokenPersistence';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { validateGroup } from 'Util/Validator';

import PayPal from './PayPal.component';
import { PAYPAL_EXPRESS, PAYPAL_SCRIPT } from './PayPal.config';

/** @namespace PeggysagePwa/Component/PayPal/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartTotals: state.CartReducer.cartTotals,
    clientId: state.ConfigReducer.paypal_client_id,
    isSandboxEnabled: state.ConfigReducer.paypal_sandbox_flag
});

/** @namespace PeggysagePwa/Component/PayPal/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e)),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error)))
});

/** @namespace PeggysagePwa/Component/PayPal/Container */
export class PayPalContainer extends PureComponent {
    static propTypes = {
        clientId: PropTypes.string,
        isSandboxEnabled: PropTypes.bool,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        cartTotals: TotalsType.isRequired,
        areTermsAccepted: PropTypes.bool,
        formRef: RefType
    };

    static defaultProps = {
        clientId: 'sb',
        isSandboxEnabled: false,
        areTermsAccepted: false,
        formRef: null
    };

    state = {
        isDisabled: true
    };

    containerFunctions = {
        onInit: this.onInit.bind(this),
        onClick: this.onClick.bind(this),
        onError: this.onError.bind(this),
        onCancel: this.onCancel.bind(this),
        onApprove: this.onApprove.bind(this),
        createOrder: this.createOrder.bind(this)
    };

    componentDidMount() {
        const script = document.getElementById(PAYPAL_SCRIPT);
        if (script) {
            script.onload = () => this.forceUpdate();
        }

        this.checkIsDisabled();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { areTermsAccepted } = this.props;
        const { areTermsAccepted: prevAreTermsAccepted } = prevProps;

        if (areTermsAccepted !== prevAreTermsAccepted) {
            this.checkIsDisabled();
        }

        const script = document.getElementById(PAYPAL_SCRIPT);
        if (script) {
            script.onload = () => this.forceUpdate();
        }
    }

    containerProps() {
        const {
            setLoading,
            setDetailsStep,
            showNotification,
            showErrorNotification,
            selectedPaymentCode,
            cartTotals,
            clientId,
            isSandboxEnabled
        } = this.props;
        const { isDisabled } = this.state;

        return {
            paypal: this.getPayPal(),
            environment: this.getEnvironment(),
            isDisabled,
            setLoading,
            setDetailsStep,
            showNotification,
            showErrorNotification,
            selectedPaymentCode,
            cartTotals,
            clientId,
            isSandboxEnabled
        };
    }

    getIsDisabled = () => {
        const { selectedPaymentCode } = this.props;
        return selectedPaymentCode !== PAYPAL_EXPRESS;
    };

    checkIsDisabled() {
        const { areTermsAccepted } = this.props;
        this.setState({ isDisabled: !areTermsAccepted });
    }

    onInit(_data, _actions) {
        this.checkIsDisabled();
    }

    onClick(_data, _actions) {
        const { areTermsAccepted, formRef } = this.props;

        if (!areTermsAccepted && formRef && formRef.current) {
            validateGroup(formRef.current);
        }
    }

    async onApprove(data) {
        const { showErrorNotification, setDetailsStep } = this.props;
        const { orderID, payerID } = data;
        const cart_id = getCartId();

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id,
                payment_method: {
                    code: 'paypal_express',
                    paypal_express: {
                        token: orderID,
                        payer_id: payerID
                    }
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
            const { placeOrder: { order: { order_id }, orderDetails } } = orderData;

            setDetailsStep(order_id, orderDetails);
        } catch (e) {
            showErrorNotification(e);
        }
    }

    onCancel() {
        const { showNotification, setLoading } = this.props;
        setLoading(false);
        showNotification('info', __('Your payment has been canceled'));
    }

    onError(err) {
        const { showErrorNotification, setLoading } = this.props;
        setLoading(false);
        showErrorNotification(err);
    }

    getPayPal = () => {
        const paypal = window;
        return paypal || false;
    };

    getEnvironment = () => {
        const { isSandboxEnabled } = this.props;
        return isSandboxEnabled ? 'sandbox' : 'production';
    };

    async createOrder() {
        const { setLoading, selectedPaymentCode } = this.props;
        const guest_cart_id = this._getGuestQuoteId();

        setLoading(true);
        setPaymentToken(null);
        const {
            paypalExpress: { token }
        } = await fetchMutation(PayPalQuery.getCreatePaypalExpressTokenMutation({
            guest_cart_id,
            express_button: false,
            code: selectedPaymentCode,
            // use_paypal_credit: this.getIsCredit(),
            urls: {
                cancel_url: 'www.paypal.com/checkoutnow/error',
                return_url: 'www.paypal.com/checkoutnow/error'
            }
        }));

        return token;
    }

    _getGuestQuoteId = () => (isSignedIn() ? '' : getCartId());

    render() {
        return (
            <PayPal
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPalContainer);
