/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_FBP_CHECKOUT = 'fbp_checkout';
export const EVENT_FBP_CHECKOUT_OPTION = 'fbp_checkout_option';
export const EVENT_FBP_IMPRESSIONS = 'fbp_impressions';
export const EVENT_FBP_IMPRESSIONS_PLP = 'fbp_impressions_plp';
export const EVENT_FBP_IMPRESSIONS_HOME = 'fbp_impressions_home';
export const EVENT_FBP_IMPRESSIONS_CROSS_SELL = 'fbp_impressions_cross_sell';
export const EVENT_FBP_IMPRESSIONS_WISHLIST = 'fbp_impressions_wishlist';
export const EVENT_FBP_IMPRESSIONS_SEARCH = 'fbp_impressions_search';
export const EVENT_FBP_IMPRESSIONS_LINKED = 'fbp_impressions_linked';
export const EVENT_FBP_META_UPDATE = 'fbp_meta_update';
export const EVENT_FBP_GENERAL_INIT = 'fbp_general_init';
export const EVENT_FBP_PRODUCT_ADD_TO_CART = 'fbp_product_add_to_cart';
export const EVENT_FBP_PRODUCT_CLICK = 'fbp_product_click';
export const EVENT_FBP_PRODUCT_DETAIL = 'fbp_product_detail';
export const EVENT_FBP_PRODUCT_REMOVE_FROM_CART = 'fbp_product_remove_from_cart';
export const EVENT_FBP_PURCHASE = 'fbp_purchase';
export const EVENT_FBP_USER_LOGIN = 'fbp_user_login';
export const EVENT_FBP_USER_REGISTER = 'fbp_user_register';
