export const UPDATE_PRIVACY_INFORMATIONS = 'UPDATE_PRIVACY_INFORMATIONS';
export const UPDATE_IS_LOADING_IN_GDPR = 'UPDATE_IS_LOADING_IN_GDPR';

/** @namespace PeggysagePwa/Store/Gdpr/Action/updatePrivacyInformations */
export const updatePrivacyInformations = (data) => ({
    type: UPDATE_PRIVACY_INFORMATIONS,
    data
});

/** @namespace PeggysagePwa/Store/Gdpr/Action/updateIsLoading */
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_IS_LOADING_IN_GDPR,
    isLoading
});
