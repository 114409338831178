import ShareWishlistForm from 'Component/ShareWishlistForm';
import {
    ShareWishlistPopup as SourceShareWishlistPopup
} from 'SourceComponent/ShareWishlistPopup/ShareWishlistPopup.component';
import { CustomerType } from 'Type/Account.type';

import './ShareWishlistPopup.override.style';

/** @namespace PeggysagePwa/Component/ShareWishlistPopup/Component */
export class ShareWishlistPopupComponent extends SourceShareWishlistPopup {
    static propTypes = {
        ...SourceShareWishlistPopup.propTypes,
        customer: CustomerType.isRequired
    };

    /**
     * Override: pass customer
     * @return {JSX.Element}
     */
    renderContent() {
        const { handleFormData, isFormLoading, customer } = this.props;

        return (
            <ShareWishlistForm
              onSave={ handleFormData }
              isFormLoading={ isFormLoading }
              customer={ customer }
            />
        );
    }
}

export default ShareWishlistPopupComponent;
