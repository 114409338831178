import PropTypes from 'prop-types';

export const FaqType = PropTypes.shape({
    faq_id: PropTypes.number.isRequired,
    name: PropTypes.string,
    content: PropTypes.string
});

export const FaqCategoryType = PropTypes.shape({
    category_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    picto: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
        category_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        faqs: PropTypes.arrayOf(PropTypes.shape({
            faq_id: PropTypes.number.isRequired
        }))
    })),
    faqs: PropTypes.arrayOf(FaqType)
});
