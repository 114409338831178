import { UPDATE_CONTENT_WIDGETS, UPDATE_LOAD_STATUS } from './Widget.action';

/** @namespace PeggysagePwa/Store/Widget/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: true,
    widgets: []
});

/** @namespace PeggysagePwa/Store/Widget/Reducer/WidgetReducer */
export const WidgetReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_CONTENT_WIDGETS:
        const { data } = action;

        return {
            ...state,
            widgets: data
        };
    case UPDATE_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default WidgetReducer;
