export const UPDATE_CONTENT_WIDGETS = 'UPDATE_CONTENT_WIDGETS';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';

/** @namespace PeggysagePwa/Store/Widget/Action/updateContentWidgets */
export const updateContentWidgets = (data) => ({
    type: UPDATE_CONTENT_WIDGETS,
    data
});

/** @namespace PeggysagePwa/Store/Widget/Action/updateIsLoading */
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_LOAD_STATUS,
    isLoading
});
