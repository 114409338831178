import {
    ACCOUNT_CONFIRMATION_URL,
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_INFORMATION_EDIT_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_HISTORY,
    ACCOUNT_ORDER_PRINT_URL,
    ACCOUNT_ORDER_URL,
    ACCOUNT_REGISTRATION_URL,
    ACCOUNT_URL,
    LOCKED_ACCOUNT_ERROR_MESSAGE
} from 'SourceRoute/MyAccount/MyAccount.config';

export {
    ACCOUNT_CONFIRMATION_URL,
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_INFORMATION_EDIT_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_HISTORY,
    ACCOUNT_ORDER_PRINT_URL,
    ACCOUNT_ORDER_URL,
    ACCOUNT_REGISTRATION_URL,
    ACCOUNT_URL,
    LOCKED_ACCOUNT_ERROR_MESSAGE
};

// Routes without constant in Scandi
export const ACCOUNT_ADDRESS_BOOK_URL = '/customer/address';
export const ACCOUNT_NEWSLETTER_SUBSCRIPTION_URL = '/newsletter/manage';

// New routes for PeggySage
export const ACCOUNT_PRO_LOGIN_URL = '/pro/account/login/';
export const ACCOUNT_PRO_REGISTRATION_URL = '/pro/account/create/';
export const ACCOUNT_STUDENT_LOGIN_URL = '/student/account/login/';
export const ACCOUNT_STUDENT_REGISTRATION_URL = '/student/account/create/';
export const ACCOUNT_EMPLOYEE_REGISTRATION_URL = '/employee/account/create/';
export const ACCOUNT_ORDER_FAVORITES_URL = '/sales/order/favorites';
export const ACCOUNT_CUSTOMER_SERVICE_URL = '/customer/issues';
export const ACCOUNT_CUSTOMER_SERVICE_ISSUE_URL = '/customer/issue/';
export const ACCOUNT_QUICK_ORDER_URL = '/quick-order';
export const ACCOUNT_DOCUMENTS_URL = '/customer/documents';

export const ACCOUNT_URLS = [
    ACCOUNT_ADDRESS_BOOK_URL,
    ACCOUNT_CONFIRMATION_URL,
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_INFORMATION_EDIT_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_NEWSLETTER_SUBSCRIPTION_URL,
    ACCOUNT_ORDER_FAVORITES_URL,
    ACCOUNT_ORDER_HISTORY,
    ACCOUNT_ORDER_PRINT_URL,
    ACCOUNT_ORDER_URL,
    // ACCOUNT_PRO_LOGIN_URL,
    // ACCOUNT_PRO_REGISTRATION_URL,
    ACCOUNT_REGISTRATION_URL,
    ACCOUNT_STUDENT_LOGIN_URL,
    ACCOUNT_STUDENT_REGISTRATION_URL,
    ACCOUNT_URL,
    ACCOUNT_CUSTOMER_SERVICE_URL,
    ACCOUNT_QUICK_ORDER_URL,
    ACCOUNT_DOCUMENTS_URL
];

// CUSTOMER GROUP IDS
export const GROUP_ID_PRO = 4;
export const GROUP_ID_STUDENT = 5;
export const GROUP_ID_EMPLOYEE = 6;

export const CUSTOMER_GROUP_LABELS = {
    [GROUP_ID_PRO]: {
        singular: __('Pro'),
        plural: __('Pros')
    },
    [GROUP_ID_EMPLOYEE]: {
        singular: __('Salarié'),
        plural: __('Salariés')
    },
    [GROUP_ID_STUDENT]: {
        singular: __('Étudiant'),
        plural: __('Étudiants')
    }
};

// From Magento 2
export const STATUS_ATTENTE = 'ATTENTE';
export const STATUS_ATTENTE_NO_DOC = 'ATTENTE_NO_DOCUMENT';
export const STATUS_ACCEPTE = 'ACCEPTE';
export const STATUS_REFUSE = 'REFUSE';
export const STATUS_DESACTIVE = 'DESACTIVE';
export const STATUS_ATTENTE_ETUDIANT = 'ATTENTE_ET';
export const STATUS_ATTENTE_ETUDIANT_NO_DOC = 'ATTENTE_ET_NO_DOC';
export const STATUS_ACCEPTE_ETUDIANT = 'ACCEPTE_ET';
export const STATUS_REFUSE_ETUDIANT = 'REFUSE_ET';
export const STATUS_DESACTIVE_ETUDIANT = 'DESACTIVE_ET';

export const STATUS_ATTENTE_SALARIE = 'ATTENTE_SALARIE';
export const STATUS_ATTENTE_SALARIE_NO_DOC = 'ATTENTE_SALARIE_NO_DOC';
export const STATUS_ACCEPTE_SALARIE = 'ACCEPTE_SALARIE';
export const STATUS_REFUSE_SALARIE = 'REFUSE_SALARIE';
export const STATUS_DESACTIVE_SALARIE = 'DESACTIVE_SALARIE';

export const PENDING_STATUSES = [
    STATUS_ATTENTE,
    STATUS_ATTENTE_NO_DOC,
    STATUS_ATTENTE_ETUDIANT,
    STATUS_ATTENTE_ETUDIANT_NO_DOC,
    STATUS_ATTENTE_SALARIE,
    STATUS_ATTENTE_SALARIE_NO_DOC
];
export const CANCELED_STATUSES = [
    STATUS_REFUSE,
    STATUS_DESACTIVE,
    STATUS_REFUSE_ETUDIANT,
    STATUS_DESACTIVE_ETUDIANT,
    STATUS_REFUSE_SALARIE,
    STATUS_DESACTIVE_SALARIE
];
export const ACCEPTED_STATUSES = [
    STATUS_ACCEPTE,
    STATUS_ACCEPTE_ETUDIANT,
    STATUS_ACCEPTE_SALARIE
];
