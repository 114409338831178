import {
    BEST_MATCH_SORT_OPTION_VALUE,
    NONE_SORT_OPTION,
    NONE_SORT_OPTION_VALUE
} from 'SourceRoute/SearchPage/SearchPage.config';

export {
    BEST_MATCH_SORT_OPTION_VALUE,
    NONE_SORT_OPTION,
    NONE_SORT_OPTION_VALUE
};

// Number of results loaded on search page
export const SEARCH_PAGE_PAGESIZE = 12;
