import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace PeggysagePwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderColorAttribute() {
        const { attribute: { attribute_value }, isSelected } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const {
            /* label, */labelText, count, image
        } = attributeOption;

        return (
            <div
              block="ProductAttributeValue"
              elem="ColorWrapper"
              mods={ { isSelected } }
            >
                <div
                  block="ProductAttributeValue"
                  elem="Color"
                >
                    <img
                      block="ProductAttributeValue"
                      elem="ColorImg"
                      src={ image }
                      alt={ labelText }
                    />
                </div>
                <span
                  block="ProductAttributeValue"
                  elem="ColorText"
                  title={ labelText }
                >
                    { labelText }
                </span>
                <span
                  block="ProductAttributeValue"
                  elem="ColorCount"
                >
                    { count }
                </span>
            </div>
        );
    }

    /**
     * Override: remove parenthesis
     * @param subLabel
     * @returns {JSX.Element|null}
     */
    renderSublabel(subLabel) {
        const { isProductCountVisible } = this.props;

        if (!subLabel || !isProductCountVisible) {
            return null;
        }

        return (
            <strong
              block="ProductAttributeValue"
              elem="SubLabel"
            >
                { subLabel }
            </strong>
        );
    }

    /**
     * Override: custom color attribute with image
     * @returns {JSX.Element|null}
     */
    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (attribute_code === 'couleur') {
            return (
                <a
                  href={ href }
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable, isColor: true } }
                  onClick={ this.clickHandler }
                  aria-hidden={ isNotAvailable }
                  rel="nofollow"
                  mix={ mix }
                >
                    { this.renderColorAttribute() }
                </a>
            );
        }

        if (isFormattedAsText || !showProductAttributeAsLink) {
            return super.render();
        }

        return (
            <>
                { /* eslint-disable-next-line react/forbid-elements */ }
                <a
                  href={ href }
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  aria-hidden={ isNotAvailable }
                  rel="nofollow"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </a>
            </>
        );
    }
}

export default ProductAttributeValueComponent;
