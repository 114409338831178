import PropTypes from 'prop-types';
import React, { lazy, PureComponent } from 'react';

import Popup from 'Component/Popup';

import { PRODUCT_POPUP_ID } from './ProductPopup.config';

import './ProductPopup.style';

export const ProductBundleOptionItem = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Component/ProductBundleOptionItem'));

/** @namespace PeggysagePwa/Component/ProductPopup/Component */
export class ProductPopupComponent extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            title: PropTypes.string,
            id: PropTypes.number,
            sku: PropTypes.string,
            bundleOptionUid: PropTypes.string,
            isBundleOptionSelectable: PropTypes.bool,
            hasBackButton: PropTypes.bool,
            onBundleOptionSelected: PropTypes.func,
            onBackButtonClick: PropTypes.func
        }).isRequired
    };

    renderContent() {
        const {
            payload: {
                id,
                sku,
                bundleOptionUid,
                isBundleOptionSelectable,
                onBundleOptionSelected,
                onBackButtonClick
            }
        } = this.props;

        if (!id || !sku) {
            return null;
        }

        return (
            <ProductBundleOptionItem
              productSKU={ sku }
              productID={ id }
              bundleOptionUid={ bundleOptionUid }
              isBundleOptionSelectable={ isBundleOptionSelectable }
              onBundleOptionSelected={ onBundleOptionSelected }
              onBackButtonClick={ onBackButtonClick }
              key={ id }
            />
        );
    }

    render() {
        const {
            payload: {
                title, onBackButtonClick, hasBackButton = true
            }
        } = this.props;

        return (
            <Popup
              id={ PRODUCT_POPUP_ID }
              clickOutside={ false }
              title={ title }
              mix={ { block: 'ProductPopup' } }
              hasBackButton={ hasBackButton }
              onBackButtonClick={ onBackButtonClick }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default ProductPopupComponent;
