import PropTypes from 'prop-types';
import { lazy } from 'react';

import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';
import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE, TYPE_NOTFOUND,
    TYPE_PRODUCT
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';

export {
    CategoryPage,
    ProductPage,
    CmsPage,
    NoMatch
};

export const TrainingPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "training" */ 'Route/TrainingPage'));
export const TutosPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/TutosPage'));

/** @namespace PeggysagePwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewrites {
    static propTypes = {
        ...SourceUrlRewrites.propTypes,
        trainingCategoryId: PropTypes.number.isRequired
    };

    /**
     * Override: Training page
     * @returns {JSX.Element}
     */
    renderCategoryPage() {
        const { props, trainingCategoryId } = this.props;
        const {
            history,
            location,
            match,
            categoryIds
        } = props;

        if (categoryIds === trainingCategoryId) {
            return (
                <TrainingPage
                  history={ history }
                  location={ location }
                  match={ match }
                  categoryIds={ categoryIds }
                />
            );
        }

        return super.renderCategoryPage();
    }

    renderTutosPage() {
        return (
            <TutosPage />
        );
    }

    renderContent() {
        const { type } = this.props;

        switch (type) {
        case TYPE_PRODUCT:
            return this.renderProductPage();
        case TYPE_CMS_PAGE:
            return this.renderCmsPage();
        case TYPE_CATEGORY:
            return this.renderCategoryPage();
        case 'PWA_TUTOS':
            return this.renderTutosPage();
        case TYPE_NOTFOUND:
            return this.renderNoMatch();
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewritesComponent;
