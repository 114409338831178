/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SHARE_WISHLIST_POPUP_ID } from 'Component/ShareWishlistPopup/ShareWishlistPopup.config';
import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountMyWishlistContainer as SourceMyAccountMyWishlistContainer
} from 'SourceComponent/MyAccountMyWishlist/MyAccountMyWishlist.container';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { CustomerType } from 'Type/Account.type';
import { ProductType } from 'Type/ProductList.type';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { WISHLIST_FILTER_POPUP_ID } from './MyAccountMyWishlist.config';

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace PeggysagePwa/Component/MyAccountMyWishlist/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    wishlist: state.WishlistReducer.wishlist,
    customer: state.MyAccountReducer.customer
});

/** @namespace PeggysagePwa/Component/MyAccountMyWishlist/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    sortWishlistItems: (sort, filters) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.sortWishlistItems(dispatch, sort, filters)
    ),
    filterWishlistItems: (sort, filters) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.filterWishlistItems(dispatch, sort, filters)
    ),
    showPopup: (popupId, payload) => dispatch(showPopup(popupId, payload)),
    hideActivePopup: () => dispatch(hideActivePopup())
});

/** @namespace PeggysagePwa/Component/MyAccountMyWishlist/Container */
export class MyAccountMyWishlistContainer extends SourceMyAccountMyWishlistContainer {
    static propTypes = {
        ...SourceMyAccountMyWishlistContainer.propTypes,
        sortWishlistItems: PropTypes.func.isRequired,
        filterWishlistItems: PropTypes.func.isRequired,
        popupFilter: PropTypes.func.isRequired,
        wishlist: PropTypes.shape({
            id: PropTypes.string,
            creators_name: PropTypes.string,
            orders: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string
            })),
            items: PropTypes.arrayOf(ProductType),
            items_count: PropTypes.number,
            sharing_code: PropTypes.string,
            updated_at: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
                values: PropTypes.arrayOf(PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.string
                }))
            })),
            related_products: PropTypes.arrayOf(ProductType)
        }).isRequired,
        customer: CustomerType.isRequired
    };

    state = {
        ...this.state,
        sortFields: [],
        selectedSort: {
            sortDirection: SORT_DIRECTION_TYPE.desc,
            sortKey: 'date'
        },
        filters: {},
        customFiltersValues: {}
    };

    containerFunctions = {
        ...this.containerFunctions,
        // filterWishlistItems: this.filterWishlistItems.bind(this),
        sortWishlistItems: this.sortWishlistItems.bind(this),
        onFilterButtonClick: this.onFilterButtonClick.bind(this),
        onFilterSubmitButtonClick: this.onFilterSubmitButtonClick.bind(this),
        toggleCustomFilter: this.toggleCustomFilter.bind(this)
    };

    componentDidMount() {
        this.setFilters();
        this.setSortFields();
    }

    componentDidUpdate(prevProps) {
        const { wishlist } = this.props;
        const { wishlist: prevWishlist } = prevProps;

        if (wishlist !== prevWishlist) {
            this.setFilters();
            this.setSortFields();
        }
    }

    /**
     * Override
     * @returns {{popupFilter, wishlist}}
     */
    containerProps() {
        const {
            wishlist,
            customer
        } = this.props;
        const {
            sortFields,
            selectedSort,
            filters,
            customFiltersValues
        } = this.state;

        const { related_products: relatedProducts } = wishlist || {};

        return {
            ...super.containerProps(),
            sortFields,
            selectedSort,
            filters,
            customFiltersValues,
            relatedProducts: relatedProducts || [],
            customer
        };
    }

    setFilters() {
        const { wishlist: { filters: wishlistFilters } } = this.props;

        if (!wishlistFilters) {
            this.setState({ filters: {} });
            return;
        }

        /* eslint-disable no-param-reassign */
        const formattedFilters = wishlistFilters.map((filter) => {
            const { label, value, values } = filter;

            return {
                attribute_code: value,
                attribute_label: label,
                attribute_options: values.reduce((result, option) => {
                    const { label, value } = option;

                    result[value] = {
                        count: null,
                        image: null,
                        label,
                        swatch_data: null,
                        value_string: value.toString()
                    };

                    return result;
                }, {}),
                attribute_position: 1,
                attribute_type: 'select',
                attribute_values: values.map((option) => option.value),
                has_swatch: false,
                is_boolean: false
            };
        }).reduce((filters, filter) => {
            const { attribute_code } = filter;

            filters[attribute_code] = filter;

            return filters;
        }, {});
        /* eslint-enable no-param-reassign */

        this.setState({ filters: formattedFilters });
    }

    setSortFields() {
        const { wishlist: { orders = [] } = {} } = this.props;

        const sortFields = orders.map((filter) => ({ id: filter.value, label: filter.label }));

        this.setState({ sortFields });
    }

    /**
     * Override: redirect to login if customer is not signed in
     * @returns {Promise<void>}
     */
    async addAllToCart() {
        if (!isSignedIn()) {
            history.push({ pathname: appendWithStoreCode('customer/account/login') });
            return;
        }

        this.setState({ isLoading: true });

        await this.addAllToCartAsync();
    }

    /**
     * Override: allow action for unsigned users
     * @returns {Promise<void>}
     */
    async removeAll() {
        const { clearWishlist } = this.props;

        this.setState({ isLoading: true });

        try {
            await clearWishlist();
            this.showNotificationAndRemoveLoading('Wishlist cleared');
        } finally {
            this.setState({ isLoading: false });
        }
    }

    /**
     * Override: add popupId parameter
     */
    shareWishlist() {
        const { showPopup } = this.props;

        showPopup(
            SHARE_WISHLIST_POPUP_ID,
            { title: __('Share Wishlist') }
        );
    }

    getFiltersParameter() {
        const { customFiltersValues } = this.state;

        return Object.keys(customFiltersValues).reduce((filters, code) => {
            const values = customFiltersValues[code];

            values.forEach((value) => {
                filters.push({
                    code,
                    value
                });
            });

            return filters;
        }, []);
    }

    getSortParameter() {
        const { selectedSort: { sortDirection, sortKey } } = this.state;

        return `${sortDirection} ${sortKey}`;
    }

    filterWishlistItems() {
        const { filterWishlistItems } = this.props;

        filterWishlistItems(this.getSortParameter(), this.getFiltersParameter());
    }

    sortWishlistItems(sortDirection, [sortKey]) {
        const { sortWishlistItems } = this.props;

        this.setState({
            selectedSort: {
                sortDirection,
                sortKey
            }
        }, () => {
            sortWishlistItems(this.getSortParameter(), this.getFiltersParameter());
        });
    }

    onFilterButtonClick() {
        const { showPopup } = this.props;

        showPopup(
            WISHLIST_FILTER_POPUP_ID,
            {
                title: __('Filtrer')
            }
        );
    }

    onFilterSubmitButtonClick() {
        const { hideActivePopup } = this.props;

        hideActivePopup();
    }

    _getNewFilterArray(filterKey, value) {
        const { customFiltersValues } = this.state;

        // If already set, unselected id (like an input radio)
        if (customFiltersValues[filterKey] !== undefined && customFiltersValues[filterKey].includes(value)) {
            return [];
        }

        return [value];
    }

    updateFilter(filterName, filterArray) {
        const { customFiltersValues } = this.state;

        const newCustomFiltersValues = {
            ...customFiltersValues,
            [filterName]: filterArray
        };

        this.setState({ customFiltersValues: newCustomFiltersValues }, () => {
            this.filterWishlistItems();
        });
    }

    toggleCustomFilter(requestVar, value) {
        this.updateFilter(requestVar, this._getNewFilterArray(requestVar, value));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMyWishlistContainer);
