import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
 * Override: add name field
 * @returns {Object}
 * @namespace PeggysagePwa/Component/ShareWishlistForm/Form/shareWishlistForm
 */
export const shareWishlistForm = (customer) => {
    const { firstname = '', lastname = '' } = customer;

    const defaultName = firstname && lastname ? `${ firstname } ${ lastname }` : '';

    return [
        {
            type: FIELD_TYPE.text,
            label: __('Your name'),
            attr: {
                name: 'name',
                'aria-label': __('Your name'),
                defaultValue: defaultName,
                readOnly: defaultName ? 'readonly' : false
            },
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.alphaSpaceDash,
                isRequired: true
            },
            addRequiredTag: true
        },
        {
            type: FIELD_TYPE.email,
            label: __('Email addresses, separated by commas'),
            attr: {
                name: 'emails',
                placeholder: __('Email addresses, separated by commas'),
                'aria-label': __('Email address')
            },
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.emailList,
                isRequired: true
            },
            addRequiredTag: true
        },
        {
            label: __('Message'),
            type: FIELD_TYPE.textarea,
            validateOn: ['onChange'],
            attr: {
                name: 'message',
                placeholder: __('Message'),
                'aria-label': __('Message')
            },
            validationRule: {
                isRequired: false
            }
        }
    ];
};

export default shareWishlistForm;
