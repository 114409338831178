/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import Image from 'Component/Image';
import { BUNDLE_TYPE } from 'Component/ProductBundleOption/ProductBundleOption.config';
import ProductPrice from 'Component/ProductPrice';
import { ProductItemType, ProductType } from 'Type/ProductList.type';
import { getPrice, getProductInStock } from 'Util/Product/Extract';
import { getQuantityFromSelectedOptions } from 'Util/Product/Transform';

import './ProductBundleButton.style';

/** @namespace PeggysagePwa/Component/ProductBundleButton/Component */
export class ProductBundleButtonComponent extends PureComponent {
    static propTypes = {
        // currencyCode: PropTypes.string.isRequired,
        product: ProductType.isRequired,
        optionGroup: ProductItemType.isRequired,
        selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        showBundlePopup: PropTypes.func.isRequired
    };

    renderUnavailableProducts(unavailableCount) {
        if (!unavailableCount) {
            return null;
        }

        const content = unavailableCount > 1 ? __('%s produits indisponibles', unavailableCount)
            : __('1 produit indisponible');

        return (
            <p block="ProductBundleButton" elem="Unavailable" className="ProductBundleItem-UnavailableTitle">
                { content }
            </p>
        );
    }

    renderSubtitle() {
        const {
            optionGroup: {
                type,
                position
            }
        } = this.props;

        // eslint-disable-next-line
        let subtitle = '';

        switch (type) {
        case BUNDLE_TYPE.kit:
        case BUNDLE_TYPE.couleur:
            subtitle = __('Personnalisez votre KIT');
            break;
        case BUNDLE_TYPE.obligatoire:
            subtitle = __('Détail du KIT');
            break;
        case BUNDLE_TYPE.produit:
            subtitle = __('Produit n°%s', position);
            break;
        case BUNDLE_TYPE.gift:
            subtitle = __('Produits Offerts', position);
            break;
        default:
            subtitle = __('Personnalisez votre produit');
        }

        return (
            <p block="ProductBundleButton" elem="Subtitle">
                { subtitle }
            </p>
        );
    }

    getSelectedOption() {
        const {
            optionGroup: {
                options
            },
            selectedOptions
        } = this.props;

        const quantity = getQuantityFromSelectedOptions(selectedOptions, options);
        const uids = Object.keys(quantity);

        if (!uids.length) {
            return {};
        }

        return {
            option: options.find((option) => option.uid === uids[0]),
            quantity: quantity[uids[0]]
        };
    }

    renderSelectedOptionColor(option) {
        const {
            product: {
                pastille_couleur
            }
        } = option;

        if (!pastille_couleur) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'ProductBundleButton', elem: 'Picture' } }
              src={ pastille_couleur }
              ratio="custom"
              objectFit="cover"
            />
        );
    }

    renderSelectedOptionPrice(option, quantity = 1) {
        const {
            // currencyCode,
            product: {
                dynamic_price
            }
        } = this.props;

        if (!dynamic_price) {
            return null;
        }

        const {
            product: {
                price_range: priceRange,
                type_id: type
            }
        } = option;

        // Below: use option price with quantity, but return string
        /* const {
            priceLabel
        } = bundleOptionToLabel(option, currencyCode); */

        return (
            <div block="ProductBundleButton" elem="TitlePrice">
                <ProductPrice
                  price={ getPrice(priceRange, false, {}, type) }
                  mods={ { type: 'regular' } }
                />
                { quantity > 1 && <span>{ `x ${quantity}` }</span> }
            </div>
        );
    }

    renderTitle() {
        const {
            optionGroup: {
                type,
                options,
                title: groupTitle,
                required
            }
        } = this.props;

        // eslint-disable-next-line
        let title = '';

        switch (type) {
        case BUNDLE_TYPE.gift:
            title = options.length > 1 ? __('Voir les %s produits offerts', options.length)
                : __('Voir les %s produits offerts', options.length);
            break;
        case BUNDLE_TYPE.obligatoire:
            title = options.length > 1 ? __('Voir les %s produits du Kit', options.length)
                : __('Voir le produit du Kit');
            break;
        default:
            title = groupTitle;
        }

        if (type !== BUNDLE_TYPE.obligatoire && type !== BUNDLE_TYPE.gift) {
            const { option: selectedOption, quantity = 1 } = this.getSelectedOption();

            if (selectedOption) {
                return (
                    <>
                        <div block="ProductBundleButton" elem="TitleProduct">
                            { this.renderSelectedOptionColor(selectedOption) }
                            <span>{ selectedOption.label }</span>
                        </div>
                        { this.renderSelectedOptionPrice(selectedOption, quantity) }
                    </>
                );
            }
        }

        return (
            <>
                { title }
                { required
                && type !== BUNDLE_TYPE.obligatoire
                && type !== BUNDLE_TYPE.gift
                && <strong block="ProductBundleItem" elem="Required"> *</strong> }
            </>
        );
    }

    renderWrapperTitle() {
        const {
            optionGroup: {
                type,
                options
            }
        } = this.props;

        const unavailableCount = options.filter((option) => !getProductInStock(option.product)).length;

        return (
            <div block="ProductBundleButton" elem="WrapperTitle">
                { !!unavailableCount
                && (type === BUNDLE_TYPE.obligatoire || type === BUNDLE_TYPE.gift)
                && this.renderUnavailableProducts(unavailableCount) }
                <div block="ProductBundleButton" elem="Title">
                    { this.renderTitle() }
                </div>
            </div>
        );
    }

    render() {
        const { showBundlePopup } = this.props;

        return (
            <button
              block="ProductBundleButton"
              onClick={ showBundlePopup }
            >
                <div block="ProductBundleButton" elem="Inner">
                    { this.renderSubtitle() }
                    { this.renderWrapperTitle() }
                </div>
                <ChevronIcon direction={ RIGHT } />
            </button>
        );
    }
}

export default ProductBundleButtonComponent;
