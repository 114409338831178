import { PureComponent } from 'react';

import './CartToWishlistIcon.style';

/** @namespace PeggysagePwa/Component/CartToWishlistIcon/Component */
export class CartToWishlistIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="CartToWishlistIcon"
              width="22.5"
              height="19.1"
              viewBox="0 0 22.5 19.1"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path block="CartToWishlistIcon" elem="Heart" d="M10.5,19.1c-0.3,0-0.6-0.1-0.8-0.3l-8-8.2c-2.4-2.5-2.4-6.3,0-8.7l0.2-0.2c2.4-2.3,6.2-2.2,8.4,0.2L10.5,2l0.2-0.2C11.8,0.7,13.4,0,15,0c0,0,0,0,0,0c1.6,0,3.2,0.7,4.3,1.8c2.4,2.5,2.4,6.3,0,8.7l-8,8.2C11.1,19,10.8,19.1,10.5,19.1z M6.1,1.5C4.9,1.5,3.8,1.9,3,2.8L2.8,2.9c-1.8,1.9-1.8,4.8,0,6.7l7.7,7.9l7.7-7.9c1.8-1.9,1.8-4.8,0-6.7c-0.8-0.9-2-1.4-3.2-1.4c0,0,0,0,0,0c-1.2,0-2.4,0.5-3.2,1.4l-1.3,1.3L9.3,2.9C8.4,2,7.3,1.5,6.1,1.5C6.1,1.5,6.1,1.5,6.1,1.5z" />
                <path block="CartToWishlistIcon" elem="Arrow" d="M22.5,17.5c0,0,0,0.1,0,0.1l-0.2-4l0-0.3c0-0.3-0.2-0.6-0.5-0.6c-0.3,0-0.6,0.2-0.6,0.5l0,0.1l-0.2,2l0,0c-0.4-0.4-1-0.9-1.6-1.5c-1-0.8-2.1-1.6-3.3-2.2c-1.4-0.7-2.8-1.2-4.4-1.3c-0.2,0-0.4,0-0.6,0s-0.4,0-0.6,0h-0.2l-0.1,0l-0.3,0l-0.6,0c-0.2,0-0.4,0-0.6,0.1l-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1c-1.3,0.3-2.5,0.8-3.6,1.5c-0.8,0.5-1.5,1-2.2,1.7c-0.2,0.2-0.5,0.5-0.7,0.8C1,14.4,1.2,14.2,1.5,14c0.7-0.6,1.4-1.1,2.2-1.5c1.1-0.6,2.3-1,3.6-1.2l0.5-0.1c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0s1.6,0,1.6,0c0.2,0,0.4,0,0.6,0s0.3,0,0.5,0.1c1.4,0.3,2.7,0.8,3.9,1.5c1,0.6,2,1.4,2.9,2.2c0.5,0.5,0.9,1,1.3,1.4l-1.9-0.2l-0.1,0c-0.3,0-0.6,0.2-0.6,0.5c0,0,0,0,0,0c0,0.3,0.1,0.6,0.4,0.6c0,0,0,0,0.1,0h0v0l3.8,1c0.4,0.2,0.9,0.1,1.1-0.3C22.5,17.8,22.5,17.7,22.5,17.5C22.5,17.5,22.5,17.5,22.5,17.5L22.5,17.5z" />
            </svg>
        );
    }
}

export default CartToWishlistIconComponent;
