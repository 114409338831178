/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_YOTPO } from './Yotpo.action';

/** @namespace PeggysagePwa/Store/Yotpo/Reducer/getInitialState */
export const getInitialState = () => ({
    yotpo: {
        isYotpoEnabled: true
    },
    isLoading: false
});

/** @namespace PeggysagePwa/Store/Yotpo/Reducer/YotpoReducer */
export const YotpoReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_YOTPO: {
        const { yotpo } = action;

        return {
            ...state,
            yotpo
        };
    }

    default:
        return state;
    }
};

export default YotpoReducer;
