import PropTypes from 'prop-types';

import ProductBundleOption from 'Component/ProductBundleOption';
import {
    ProductBundleOptions as SourceProductBundleOptions
} from 'SourceComponent/ProductBundleOptions/ProductBundleOptions.component';
import { ProductType } from 'Type/ProductList.type';

import './ProductBundleOptions.override.style';

/** @namespace PeggysagePwa/Component/ProductBundleOptions/Component */
export class ProductBundleOptionsComponent extends SourceProductBundleOptions {
    static propTypes = {
        ...SourceProductBundleOptions.propTypes,
        product: ProductType.isRequired,
        selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        areFiltersVisible: PropTypes.bool.isRequired
    };

    /**
     * Override: pass product to parameters for ProductBundleOption
     * @param group
     * @returns {JSX.Element}
     */
    renderOptionGroup(group) {
        const {
            title,
            options,
            type,
            required,
            uid,
            option_id
        } = group;
        const { product, selectedOptions = [], areFiltersVisible } = this.props;

        const { updateSelectedValues } = this.props;

        return (
            <ProductBundleOption
              key={ option_id }
              title={ title }
              options={ options }
              type={ type }
              isRequired={ required }
              required={ required }
              updateSelectedValues={ updateSelectedValues }
              uid={ uid }
              optionId={ option_id }
              product={ product }
              selectedOptions={ selectedOptions }
              areFiltersVisible={ areFiltersVisible }
            />
        );
    }
}

export default ProductBundleOptionsComponent;
