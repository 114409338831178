import {
    getNumberOfCharacterClasses,
    validate,
    validateGroup,
    validatePassword
} from 'SourceUtil/Validator/Validator';
import { VALIDATION_INPUT_TYPE, VALIDATION_MESSAGES } from 'Util/Validator/Config';

export {
    getNumberOfCharacterClasses,
    validate,
    validateGroup,
    validatePassword
};

export default validate;

/** @namespace PeggysagePwa/Util/Validator/validateEmail */
export const validateEmail = (value) => {
    if (value.length === 0) {
        return true;
    }

    if (!value.includes('@')) {
        return __('Il manque un "@" dans votre email.');
    }

    if (!value.includes('.')) {
        return __('Il manque un "." dans votre email.');
    }

    return true;
};

/**
 * Fix wrong phone number format from "react-phone-input-2" package
 * @namespace PeggysagePwa/Util/Validator/getFixedCountryFormattingData */
export const getFixedCountryFormattingData = (country) => {
    const { countryCode } = country;

    switch (countryCode) {
    case 'de':
        const format = {
            min: '+.. .. .....', // 2 + 7
            max: '+.. ..... ......' // 2 + 11
        };

        return {
            ...country,
            format
        };
    default:
        return country;
    }
};

/**
 * Validate phone number using "react-phone-input-2" PhoneInput component
 * @namespace PeggysagePwa/Util/Validator/validatePhoneNumber */
export const validatePhoneNumber = (value, country) => {
    const { format } = getFixedCountryFormattingData(country);

    /* const errorMessage = value.length !== country.format.replaceAll(/\s|\+|-|\(|\)/gm, '').length
        ? VALIDATION_MESSAGES[VALIDATION_INPUT_TYPE.phone]
        : ''; */

    // FR: the number directly after the prefix cannot be 0
    if (['fr', 'ch'].includes(country.countryCode) && value.substr(2, 1) === '0') {
        return VALIDATION_MESSAGES[VALIDATION_INPUT_TYPE.phone];
    }

    const hasError = typeof format === 'string'
        ? value.length !== format.replaceAll(/\s|\+|-|\(|\)/gm, '').length
        : (
            value.length < format.min.replaceAll(/\s|\+|-|\(|\)/gm, '').length
            || value.length > format.max.replaceAll(/\s|\+|-|\(|\)/gm, '').length
        );

    return hasError ? VALIDATION_MESSAGES[VALIDATION_INPUT_TYPE.phone] : true;
};
