// import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Html from 'Component/Html';
import { MixType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList.type';

import './ProductChips.style';

/** @namespace PeggysagePwa/Component/ProductChips/Component */
export class ProductChipsComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    renderDiscount() {
        const { product: { pastille_promo }, mix } = this.props;
        if (!pastille_promo) {
            return null;
        }

        return (
            <div block="ProductChips" mix={ mix } elem="Discount" className="ProductChips">
                <Html content={ pastille_promo } />
            </div>
        );
    }

    render() {
        return (
            <>
              { this.renderDiscount() }
            </>
        );
    }
}

export default ProductChipsComponent;
