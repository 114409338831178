import {
    ANIMATION_DURATION,
    ERROR_TYPE
} from 'SourceComponent/Notification/Notification.config';

export {
    ANIMATION_DURATION,
    ERROR_TYPE
};

export const NOTIFICATION_LIFETIME = 10000; // originally 1500
export const ERROR_NOTIFICATION_LIFETIME = 10000; // originally 2500
