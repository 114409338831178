import {
    FieldForm as SourceFieldForm
} from 'SourceComponent/FieldForm/FieldForm.component';

import './FieldForm.override.style';

/** @namespace PeggysagePwa/Component/FieldForm/Component */
export class FieldFormComponent extends SourceFieldForm {
    /**
     * Override: pass block attr in props, since the attr is overriden in Form component
     * @returns {{mix: {block: string}}}
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getFormProps() {
        const props = {
            mix: {
                block: 'FieldForm'
            }
        };

        return {
            ...props,
            ...this.props
        };
    }
}

export default FieldFormComponent;
