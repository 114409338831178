import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './BNPMercanet.style';

/**
 * *Note*
 * This component currently can be rendered only once
 * Please try to have no more than 1 component per page and use isDisabled to hide it.
 * @namespace PeggysagePwa/Component/BNPMercanet/Component
*/
export class BNPMercanetComponent extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        handleClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        isDisabled: false
    };

    render() {
        const {
            isDisabled,
            handleClick
        } = this.props;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                <button
                  type="button"
                  block="Button"
                  disabled={ isDisabled }
                  onClick={ handleClick }
                  mix={ { block: 'CheckoutBilling', elem: 'Button', mods: { type: 'BNPMercanet' } } }
                >
                  { __('Complete order') }
                </button>
            </div>
        );
    }
}

export default BNPMercanetComponent;
