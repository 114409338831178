import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { TRAINING_POPUP_ID } from 'Component/TrainingPopup/TrainingPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { MixType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList.type';
import { getSmallImage } from 'Util/Product/Extract';
import { getAllFutureSessions } from 'Util/Training';

import TrainingCard from './TrainingCard.component';

/** @namespace PeggysagePwa/Component/TrainingCard/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace PeggysagePwa/Component/TrainingCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (popupId, payload) => dispatch(showPopup(popupId, payload))
});

/** @namespace PeggysagePwa/Component/TrainingCard/Container */
export class TrainingCardContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isLoading: PropTypes.bool,
        mix: MixType,
        displayMode: PropTypes.string,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false,
        mix: {},
        displayMode: 'default'
    };

    containerFunctions = {
        getCentersWithValidSessions: this.getCentersWithValidSessions.bind(this),
        onClickAvailabilities: this.onClickAvailabilities.bind(this)
    };

    containerProps() {
        const {
            mix,
            isLoading,
            product,
            displayMode
        } = this.props;

        return {
            mix,
            isLoading,
            product,
            displayMode,
            thumbnail: getSmallImage(product)
        };
    }

    getCentersWithValidSessions() {
        const { product: { date_formation } } = this.props;

        if (!date_formation || !date_formation.length) {
            return [];
        }

        return getAllFutureSessions(date_formation, true);
    }

    onClickAvailabilities() {
        const { product, product: { name }, showPopup } = this.props;

        showPopup(
            TRAINING_POPUP_ID,
            {
                title: name,
                product
            }
        );
    }

    render() {
        return (
            <TrainingCard
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainingCardContainer);
