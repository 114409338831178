// TODO update this import when renamed
import {
    QUICKORDER_ADD_PRODUCT,
    QUICKORDER_EMPTY_SELECTED,
    QUICKORDER_GET_PRODUCTS,
    QUICKORDER_INIT_SEARCH,
    QUICKORDER_REMOVE_PRODUCT
} from './QuickOrder.action';

/** @namespace PeggysagePwa/Store/QuickOrder/Reducer/getInitialState */
export const getInitialState = () => ({
    searchedProducts: [],
    isLoading: false,
    selectedProducts: {}
});

/** @namespace PeggysagePwa/Store/QuickOrder/Reducer/QuickOrderReducer */
export const QuickOrderReducer = (state = getInitialState(), action) => {
    const {
        products, type, product, remove
    } = action;

    const { selectedProducts } = state;

    switch (type) {
    case QUICKORDER_GET_PRODUCTS:
        return {
            ...state,
            isLoading: false,
            searchedProducts: products
        };
    case QUICKORDER_INIT_SEARCH:
        return {
            ...state,
            isLoading: true
        };
    case QUICKORDER_ADD_PRODUCT:
        if (selectedProducts[product.sku]) {
            selectedProducts[product.sku].qty++;
        } else {
            selectedProducts[product.sku] = {
                ...product,
                qty: 1
            };
        }

        return {
            ...state,
            selectedProducts
        };
    case QUICKORDER_REMOVE_PRODUCT:
        if ((selectedProducts[product.sku] && selectedProducts[product.sku].qty === 0) || remove) {
            // eslint-disable-next-line fp/no-delete
            delete selectedProducts[product.sku];
        } else {
            selectedProducts[product.sku].qty--;
            if (selectedProducts[product.sku].qty === 0) {
                // eslint-disable-next-line fp/no-delete
                delete selectedProducts[product.sku];
            }
        }

        return {
            ...state,
            selectedProducts
        };
    case QUICKORDER_EMPTY_SELECTED:
        return {
            ...state,
            selectedProducts: {}
        };
    default:
        return state;
    }
};

export default QuickOrderReducer;
