import { getTimestampFromDate } from 'Util/Manipulations/Date';

/** @namespace PeggysagePwa/Util/Training/Index/getFutureSessions */
export function getFutureSessions(sessions, showUnavailable = false) {
    return sessions.filter((session) => {
        const { date_from, available } = session;

        const timestampFrom = getTimestampFromDate(date_from);
        const timestampToday = Date.now();

        return timestampFrom > timestampToday && (showUnavailable || (!showUnavailable && available));
    });
}

/** @namespace PeggysagePwa/Util/Training/Index/getFutureSessionsByCenter */
export function getFutureSessionsByCenter(date_formation, shop_id, showUnavailable = false) {
    const availabilities = date_formation.find((item) => (
        item.training_center?.shop_id === shop_id
        && item.sessions.length > 0
    ));

    if (!availabilities) {
        return [];
    }

    return getFutureSessions(availabilities.sessions, showUnavailable);
}

/** @namespace PeggysagePwa/Util/Training/Index/getAllFutureSessions */
export function getAllFutureSessions(date_formation, showUnavailable = false) {
    return date_formation.map((availabilities) => {
        const { training_center, sessions } = availabilities;

        return {
            training_center,
            sessions: getFutureSessions(sessions, showUnavailable)
        };
    }).filter((availabilities) => availabilities.training_center && availabilities.sessions.length);
}
