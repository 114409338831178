/* eslint-disable simple-import-sort/sort */
import PropTypes from 'prop-types';

import {
    BreadcrumbsType,
    CategoryFragment as SourceCategoryFragment,
    CategoryTreeType,
    SelectedFiltersType,
    FilterType,
    FilterInputType,
    SortFieldsType
} from 'SourceType/Category.type';

export {
    BreadcrumbsType,
    CategoryTreeType,
    SelectedFiltersType,
    FilterType,
    FilterInputType,
    SortFieldsType
};

export const CategoryContentPushType = PropTypes.shape({
    supertitle: PropTypes.string,
    title: PropTypes.string,
    page_identifier: PropTypes.string,
    image_url: PropTypes.string,
    position: PropTypes.number
});

export const CategoryReviewType = PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    photo: PropTypes.string
});

export const CategoryFragment = {
    ...SourceCategoryFragment,
    liste_collections: PropTypes.bool,
    image_collection: PropTypes.string,
    image_collection_ambiance: PropTypes.string,
    est_collection: PropTypes.bool,
    collection_category_name: PropTypes.string,
    content_push: PropTypes.arrayOf(CategoryContentPushType),
    avis: PropTypes.arrayOf(CategoryReviewType)
};
