/* eslint-disable max-lines */
/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import General from './events/General.event';
import PurchaseEvent from './events/Purchase.event';

import {
    DATA_LAYER_NAME,
    EVENT_GENERAL,
    EVENT_PURCHASE,
    GROUPED_PRODUCTS_GUEST,
    GROUPED_PRODUCTS_PREFIX
} from './GoogleMerchant.config';
import Scripts from './Scripts';


/** @namespace GoogleMerchant/Component/GoogleMerchant/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    googleMerchant: state.ConfigReducer.googleMerchant,
    state
});

/** @namespace GoogleMerchant/Component/GoogleMerchant/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    dispatch
});

/**
 * Google tag manager wrapper
 * This should have 1 instance to avoid multiple initializations
 * @namespace GoogleMerchant/Component/GoogleMerchant/Container/GoogleMerchantContainer */
export class GoogleMerchantContainer extends PureComponent {
    static propTypes = {
        googleMerchant: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        state: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        dispatch: PropTypes.func
    };

    static defaultProps = {
        googleMerchant: {
            enabled: false,
            merchant_id: ''
        },
        state: {},
        dispatch: () => {}
    };

    containerProps() {
        const { googleMerchant: { enabled, merchant_id} } = this.props;

        return {
            enabled,
            merchant_id
        };
    };

    containerFunctions = {
        renderBadge: this.renderBadge.bind(this),
        renderReviewRequest: this.renderReviewRequest.bind(this)
    };

    async renderBadge() {
        // ...
        if (typeof window.gapi !== 'undefined') {
            window.renderBadge();
        }
    }

    async renderReviewRequest() {
        // ...
    }



    /**
     * Event list used in GoogleMerchant
     * All used events should be registered in this data mapping
     * TODO: 404 page, categoryFilter, additional events
     *
     * @type {{[p: string]: General|Purchase}}
     */
    static eventList = {
        [EVENT_GENERAL]: General,
        [EVENT_PURCHASE]: PurchaseEvent
    };

    /**
     * GoogleMerchant instance
     *
     * @type {GoogleMerchant}
     */
    static instance = null;

    /**
     * Push data to GoogleMerchant
     *
     * @param event
     * @param data
     */
    static pushData(event, data) {
        if (this.getInstance()) {
            this.getInstance().processDataPush(event, data);
        }
    }

    /**
     * Append Data Layer with new data
     *
     * @param data
     */
    static appendData(data) {
        if (this.getInstance()) {
            this.getInstance().addDataLayer(data);
        }
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|BaseEvent}
     */
    static getEvent(name) {
        if (this.getInstance()) {
            return this.getInstance().getEvent(name);
        }

        return null;
    }

    /**
     * Get GoogleMerchant Instance
     *
     * @return {GoogleMerchant}
     */
    static getInstance() {
        return GoogleMerchantContainer.instance;
    }

    /**
     * Is GoogleMerchant enabled
     *
     * @type {boolean}
     */
    enabled = false;

    /**
     * Prepared Data Layer
     *
     * @type {{}}
     */
    currentDataLayer = {};

    /**
     * Data layer name
     *
     * @type {string}
     */
    currentDataLayerName = DATA_LAYER_NAME;

    /**
     * groupedProducts
     */
    groupedProductsStorageName = GROUPED_PRODUCTS_GUEST;

    /**
     * Event data object
     *
     * @type {{}}
     */
    events = {};

    /**
     * Data storage for event data
     *
     * @type {{}}
     */
    eventDataStorage = {};

    /**
     * Grouped product storage
     */
    groupedProducts = {};

    /**
     * Did mount
     */
    componentDidMount() {
        this.initialize();
    }

    /**
     * If props is updated
     */
    componentDidUpdate() {
        this.initialize();
    }

    /**
     * Unregister component
     */
    componentWillUnmount() {
        this.destruct();
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|*}
     */
    getEvent(name) {
        if (Object.keys(this.events).indexOf(name) >= 0) {
            return this.events[name];
        }

        return null;
    }

    /**
     * Set event storage
     *
     * @param event
     * @param data
     */
    setEventStorage(event, data) {
        this.eventDataStorage[event] = data;
    }

    /**
     * Set grouped products to storage
     *
     * @param groupedProducts
     */
    setGroupedProducts(groupedProducts) {
        BrowserDatabase.setItem(groupedProducts, this.groupedProductsStorageName, ONE_MONTH_IN_SECONDS);
        this.groupedProducts = groupedProducts;
    }

    /**
     * Get reference to grouped products
     */
    getGroupedProducts() {
        return this.groupedProducts;
    }

    /**
     * Get reference to the storage
     *
     * @param event
     * @return {*}
     */
    getEventDataStorage(event) {
        if (typeof this.eventDataStorage[event] === 'undefined') {
            this.resetEventDataStorage(event);
        }

        return this.eventDataStorage[event];
    }

    /**
     * Reset storage data
     *
     * @param event
     */
    resetEventDataStorage(event) {
        this.eventDataStorage[event] = {};
    }

    updateGroupedProducts() {
        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    updateGroupedProductsStorageName(name) {
        this.groupedProductsStorageName = name
            ? `${ GROUPED_PRODUCTS_PREFIX }${ name }`
            : GROUPED_PRODUCTS_GUEST;

        this.updateGroupedProducts();
    }

    /**
     * Register GoogleMerchant event handlers
     */
    registerEvents() {
        this.events = Object.entries(GoogleMerchantContainer.eventList).reduce((acc, [name, Event]) => {
            acc[name] = new Event(name, this);
            acc[name].bindEvent();

            return acc;
        }, {});
    }

    /**
     * Send event and data to the GoogleMerchant
     *
     * @param event
     * @param data
     */
    processDataPush(event, data) {
        if (this.enabled) {
            this.addDataLayer(data);

            if (this.debug) {
                // eslint-disable-next-line no-console
                console.log(event, data);
            }

            window[this.currentDataLayerName].push({
                event,
                ...this.currentDataLayer
            });

            this.currentDataLayer = {};
        }
    }

    /**
     * Unregister/ destruct all parts related to the GoogleMerchant
     */
    destruct() {
        Object.values(this.events).forEach((event, name) => {
            event.destruct();
            // eslint-disable-next-line fp/no-delete
            delete this.events[name];
        });

        this.events = {};
    }

    /**
     * Append current DataLayer with new nata
     *
     * @param data
     */
    addDataLayer(data) {
        if (this.enabled) {
            this.currentDataLayer = { ...this.currentDataLayer, ...data };
        }
    }

    /**
     * Initialize GoogleMerchant
     */
    initialize() {
        const { googleMerchant: { enabled } } = this.props;

        if (this.enabled || !enabled || GoogleMerchantContainer.getInstance()) {
            return;
        }

        this.enabled = true;
        GoogleMerchantContainer.instance = this;
        this.initGroupedProducts();
        this.injectGoogleMerchantcripts();
        this.registerEvents();
    }

    /**
     * Initialize grouped products
     */
    initGroupedProducts() {
        const customer = BrowserDatabase.getItem(CUSTOMER);

        this.updateGroupedProductsStorageName(customer && customer.id);

        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }


    /**
     * Insert GoogleMerchant scripts to the document
     */
    injectGoogleMerchantcripts() {
        const { googleMerchant: { merchant_id: id } } = this.props;

        const script = document.createElement('script');
        script.innerHTML = Scripts.getScript();

        window[this.currentDataLayerName] = window[this.currentDataLayerName] || [];
        // setTimeout(() => {
        document.head.insertBefore(script, document.head.childNodes[0]);
        // }, GOOGLE_MERCHANT_INJECTION_TIMEOUT);

        this.prepareBadge(id);
    }

    prepareBadge(id) {
        window.renderBadge = function() {
            const ratingBadgeContainer = document.createElement("div");
            document.body.appendChild(ratingBadgeContainer);
            window.gapi.load('ratingbadge', function() {
                window.gapi.ratingbadge.render(ratingBadgeContainer, { merchant_id: id });
            });
        }
    }

    getMerchantId() {
        const { googleMerchant: { merchant_id } } = this.props;

        return merchant_id;
    }

    render() {
       return null;
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleMerchantContainer));
