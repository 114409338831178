// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CUSTOMER_GROUP_LABELS, GROUP_ID_PRO, GROUP_ID_STUDENT } from 'Route/MyAccount/MyAccount.config';
import { ProductTagType } from 'Type/ProductList.type';

import './ProductTag.style';

/** @namespace PeggysagePwa/Component/ProductTag/Component */
export class ProductTagComponent extends PureComponent {
    static propTypes = {
        tag: ProductTagType.isRequired
    };

    labelMap = {
        bestseller: __('Meilleures ventes'),
        trendy: __('Tendance'),
        favorite: __('Coup de cœur'),
        limited_edition: __('Edition limitée'),
        web_exclusive: __('Exclusivité web'),
        special_price: __('Prix spécial'),
        promotion: __('Prix en baisse'),
        produit_etudiant: CUSTOMER_GROUP_LABELS[GROUP_ID_STUDENT].singular,
        produit_pro: CUSTOMER_GROUP_LABELS[GROUP_ID_PRO].singular,
        produit_reserve_pro: CUSTOMER_GROUP_LABELS[GROUP_ID_PRO].singular
    };

    render() {
        const { tag: { type, label } } = this.props;

        const tagLabel = label || this.labelMap[type] || type;

        return (
            <div
              block="ProductTag"
              mods={ { type } }
            >
                { tagLabel }
            </div>
        );
    }
}

export default ProductTagComponent;
