/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { TOGGLE_GET_PFPROD_LOADER, UPDATE_PERFECTCORP_PRODUCT_DETAILS } from './PerfectCorpProduct.action';

/** @namespace PeggysagePwa/Store/PerfectCorpProduct/Reducer/getInitialState */
export const getInitialState = () => ({
    perfectProduct: {},
    isLoading: false
});

/** @namespace PeggysagePwa/Store/PerfectCorpProduct/Reducer/ProductReducer */
export const ProductReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_GET_PFPROD_LOADER: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    }

    case UPDATE_PERFECTCORP_PRODUCT_DETAILS:
        const { perfectProduct } = action;

        return {
            ...state,
            perfectProduct
        };
    default:
        return state;
    }
};

export default ProductReducer;
