import PropTypes from 'prop-types';

import FIELD_TYPE from 'Component/Field/Field.config';
import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

import Field from './Field.component';

/** @namespace PeggysagePwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        value: PropTypes.number, // Override: not required (only used with FieldNumberWithControls...),
        hasFloatingLabel: PropTypes.bool
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        value: 0,
        hasFloatingLabel: true
    };

    state = {
        ...this.state,
        hasFloatingLabel: true,
        isEmpty: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        revealPassword: this.revealPassword.bind(this)
    };

    componentDidMount() {
        const { type, hasFloatingLabel } = this.props;

        if (this.isTypeWithFloatingLabel(type)) {
            this.setState({ hasFloatingLabel });

            if (hasFloatingLabel) {
                this.checkIsEmpty();
            }
        } else {
            this.setState({ hasFloatingLabel: false });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            value,
            attr: { defaultValue, value: attrValue },
            options
        } = this.props;
        const {
            value: prevValue,
            attr: { defaultValue: prevDefaultValue, value: prevAttrValue },
            options: prevOptions
        } = prevProps;

        if (value !== prevValue
            || defaultValue !== prevDefaultValue
            || attrValue !== prevAttrValue
            || options !== prevOptions) {
            this.checkIsEmpty();
        }
    }

    /**
     * Override
     */
    componentWillUnmount() {
        const { type } = this.props;

        super.componentWillUnmount();

        if (this.fieldRef && this.isTypeWithFloatingLabel(type)) {
            this.fieldRef.removeEventListener('focus', this.onFocus.bind(this));
            this.fieldRef.removeEventListener('blur', this.onBlur.bind(this));
        }
    }

    /**
     * Override: add events for floating labels
     * @param elem
     */
    setRef(elem) {
        const { validationRule, elemRef, type } = this.props;

        if (elem && this.fieldRef !== elem) {
            this.fieldRef = elem;

            if (elemRef) {
                elemRef.current = elem;
            }

            elem.addEventListener('resetField', this.resetField.bind(this));

            if (this.isTypeWithFloatingLabel(type)) {
                elem.addEventListener('focus', this.onFocus.bind(this));
                elem.addEventListener('blur', this.onBlur.bind(this));
            }

            if (!validationRule || Object.keys(validationRule).length === 0) {
                return;
            }

            elem.addEventListener('validate', this.validate.bind(this));
        }
    }

    isTypeWithFloatingLabel(type) {
        return [
            FIELD_TYPE.email,
            FIELD_TYPE.text,
            FIELD_TYPE.time,
            FIELD_TYPE.dateTime,
            FIELD_TYPE.date,
            FIELD_TYPE.password,
            FIELD_TYPE.tel,
            FIELD_TYPE.number,
            FIELD_TYPE.select,
            FIELD_TYPE.textarea
        ].includes(type);
    }

    checkIsEmpty() {
        const { type, attr: { selectPlaceholder } } = this.props;
        const elem = this.fieldRef;

        if (!elem) {
            this.setState({ isEmpty: false });
        }

        const { value } = elem;

        this.setState({ isEmpty: type === FIELD_TYPE.select ? !value && !selectPlaceholder : !value });
    }

    onFocus() {
        this.setState({ isEmpty: false });
    }

    onBlur() {
        this.checkIsEmpty();
    }

    /**
     * Override: check value for floating label on change event for selects
     * @param hook
     * @param args
     */
    validateOnEvent(hook, ...args) {
        const { type } = this.props;

        if (hook && type === FIELD_TYPE.select) {
            this.checkIsEmpty();
        }

        super.validateOnEvent(hook, ...args);
    }

    revealPassword(e) {
        e.preventDefault();
        const input = e.currentTarget.previousElementSibling;
        const type = input.getAttribute('type');
        input.setAttribute('type', type === 'password' ? 'text' : 'password');
    }

    /**
     * Override
     * @returns {{hasFloatingLabel: boolean, isEmpty: boolean}}
     */
    containerProps() {
        const {
            label,
            attr: {
                placeholder,
                ...attr
            }
        } = this.props;
        const { hasFloatingLabel, isEmpty } = this.state;

        return {
            ...super.containerProps(),
            // if field has floating label but no label, put placeholder instead
            label: hasFloatingLabel && !label && placeholder ? placeholder : label,
            // if field has floating label, remove placeholder
            attr: {
                ...attr,
                placeholder: hasFloatingLabel ? '' : placeholder
            },
            hasFloatingLabel,
            isEmpty
        };
    }

    /**
     * Override
     * @returns {JSX.Element}
     */
    render() {
        return (
            <Field
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default FieldContainer;
