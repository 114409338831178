import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

/** @namespace PeggysagePwa/Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace PeggysagePwa/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    static propTypes = {
        ...SourceProductWishlistButtonContainer.propTypes,
        isInCart: PropTypes.bool,
        onProductAddedToWishlist: PropTypes.func
    };

    static defaultProps = {
        ...SourceProductWishlistButtonContainer.defaultProps,
        wishlistId: null,
        isInCart: false,
        onProductAddedToWishlist: null
    };

    containerProps() {
        const { isInCart } = this.props;
        const { isWishListToggle } = this.state;

        return {
            ...super.containerProps(),
            isInCart,
            isWishListToggle
        };
    }

    /**
     * Override: allow guests
     * @param add
     * @returns {Promise<*>}
     */
    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
            onProductAddedToWishlist
        } = this.props;

        const {
            isWishListToggle
        } = this.state;

        // this.setWishlistButtonLoading(true);

        if (isWishListToggle) {
            return;
        }

        this.setState({ isWishListToggle: true });

        if (add) {
            const isProductAdded = await addProductToWishlist({
                items: magentoProduct,
                wishlistId
            });

            if (isProductAdded && onProductAddedToWishlist) {
                onProductAddedToWishlist();
            }

            this.setState({ isWishListToggle: false });

            return;
        }

        const wishlistItem = this.getWishlistItem(sku);

        if (!wishlistItem) {
            this.setState({ isWishListToggle: false });

            return;
        }

        const {
            wishlist: {
                id: itemId
            }
        } = wishlistItem;

        this.setState({ isWishListToggle: false });

        removeProductFromWishlist({ item_id: itemId });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
