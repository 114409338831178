import {
    ProductCustomizableOptions as SourceProductCustomizableOptions
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.component';

import './ProductCustomizableOptions.override.style';

/** @namespace PeggysagePwa/Component/ProductCustomizableOptions/Component */
export class ProductCustomizableOptionsComponent extends SourceProductCustomizableOptions {
    /**
     * Override: add verification var existence
     * @returns {null|React.ReactNode}
     */
    render() {
        const { options = [] } = this.props;

        if (!options) {
            return null;
        }

        return super.render();
    }
}

export default ProductCustomizableOptionsComponent;
