import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import { BUNDLE_TYPE } from 'Component/ProductBundleOption/ProductBundleOption.config';
import ProductPrice from 'Component/ProductPrice';
import ProductReviewRating from 'Component/ProductReviewRating';
import { RefType } from 'Type/Common.type';
import { ItemOptionProductType } from 'Type/ProductList.type';
import { getPrice, getProductInStock } from 'Util/Product/Extract';

import './ProductBundleOptionDetails.style';

/** @namespace PeggysagePwa/Component/ProductBundleOptionDetails/Component */
export class ProductBundleOptionDetailsComponent extends PureComponent {
    static propTypes = {
        option: ItemOptionProductType.isRequired,
        bundleType: PropTypes.string.isRequired,
        hasDynamicPrice: PropTypes.bool.isRequired,
        isSelectable: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        showProductPopup: PropTypes.func.isRequired,
        isFreeProduct: PropTypes.func.isRequired,
        inputLabelRef: RefType.isRequired,
        isYotpoEnabled: PropTypes.bool.isRequired,
        yotpo: PropTypes.shape({
            rating_summary: PropTypes.number,
            review_count: PropTypes.number
        }).isRequired
    };

    renderProductVisual(product) {
        const { name, thumbnail } = product;

        return (
            <Image
              src={ thumbnail?.url }
              ratio="custom"
              mix={ {
                  block: 'ProductBundleItem',
                  elem: 'Thumbnail',
                  mods: { isPlaceholder: !thumbnail?.url }
              } }
              isPlaceholder={ !thumbnail?.url }
              alt={ name }
            />
        );
    }

    renderProductTitle(product) {
        const { name } = product;

        return (
            <div block="ProductBundleItem" elem="ProductTitle">
                { name }
            </div>
        );
    }

    renderProductRatingSummary(product) {
        const {
            yotpo: {
                rating_summary: yotpoRatingSummary,
                review_count: yotpoReviewCount
            },
            isYotpoEnabled
        } = this.props;
        const {
            rating_summary,
            review_count
        } = product;

        const ratingSummary = isYotpoEnabled ? yotpoRatingSummary : rating_summary;
        const reviewCount = isYotpoEnabled ? yotpoReviewCount : review_count;

        if (!ratingSummary) {
            return null;
        }

        return <ProductReviewRating summary={ ratingSummary || 0 } count={ reviewCount } />;
    }

    renderProductSku(product) {
        const { sku } = product;

        if (!sku) {
            return null;
        }

        return (
            <div block="ProductBundleItem" elem="ProductSku" itemProp="sku">
                { __('SKU: %s', sku) }
            </div>
        );
    }

    renderProductQuantity(qty) {
        return (
            <div block="ProductBundleItem" elem="ProductQuantity">
                { __('Quantity: %s', qty) }
            </div>
        );
    }

    renderProductStock(stock) {
        if (!stock) {
            return (
                <div block="ProductBundleItem" elem="ProductStock">
                    <span className="ProductActions-Stock unavailable">
                        { __('This product is not available') }
                    </span>
                </div>
            );
        }

        return null;
    }

    renderProductInputLabel(htmlFor) {
        const { isSelectable, isSelected, inputLabelRef } = this.props;

        if (!isSelectable || !htmlFor) {
            return null;
        }

        if (isSelected) {
            return (
                <span block="ProductBundleItem" elem="ProductSelected">
                    { __('Choisi') }
                </span>
            );
        }

        return (
            <label
              htmlFor={ htmlFor }
              className="Button"
              ref={ inputLabelRef }
            >
                { __('Choisir') }
            </label>
        );
    }

    renderProductPrice(product) {
        if (!product) {
            return null;
        }

        const {
            price_range: priceRange,
            type_id: type
        } = product;

        const productPrice = getPrice(priceRange, false, {}, type);

        return (
            <div block="ProductBundleItem" elem="ProductPrice">
                <ProductPrice
                  price={ productPrice }
                  mods={ { type: 'regular' } }
                />
            </div>
        );
    }

    renderProductFree() {
        return (
            <div block="ProductBundleItem" elem="ProductFree">
                <span>{ __('Offert') }</span>
            </div>
        );
    }

    renderProductLink() {
        const {
            option: {
                product: {
                    name
                }
            },
            showProductPopup
        } = this.props;

        return (
            <button
              type="button"
              block="ProductBundleItem"
              elem="ProductLink"
              mix={ { block: 'Button', mods: { likeLink: true } } }
              onClick={ showProductPopup }
              title={ name }
              className="ProductBundleItem-ProductLink"
            >
                { __('Voir le détail') }
            </button>
        );
    }

    renderProductDetails() {
        const {
            option: {
                uid,
                product,
                quantity
            },
            bundleType,
            hasDynamicPrice,
            isFreeProduct
        } = this.props;

        if (isFreeProduct(product)) {
            return (
                <>
                    { this.renderProductTitle(product) }
                    { this.renderProductFree() }
                </>
            );
        }

        const stock = getProductInStock(product);

        return (
            <>
                { this.renderProductTitle(product) }
                { this.renderProductRatingSummary(product) }
                { (bundleType !== BUNDLE_TYPE.obligatoire && bundleType !== BUNDLE_TYPE.gift) && (
                    <div block="ProductBundleItem" elem="ProductPriceWrapper">
                        { hasDynamicPrice && this.renderProductPrice(product) }
                        { quantity > 1 && <span block="ProductBundleItem" elem="ProductQty">{ `x ${quantity}` }</span> }
                    </div>
                ) }
                { (bundleType === BUNDLE_TYPE.obligatoire || bundleType === BUNDLE_TYPE.gift) && this.renderProductSku(product) }
                { (bundleType === BUNDLE_TYPE.obligatoire || bundleType === BUNDLE_TYPE.gift) && this.renderProductQuantity(stock ? quantity : 0) }
                { this.renderProductStock(stock) }
                <div block="ProductBundleItem" elem="ProductActions">
                    { this.renderProductInputLabel(`option-${ uid }`) }
                    { (bundleType === BUNDLE_TYPE.gift) && (
                        this.renderProductFree()
                    ) }
                    { this.renderProductLink() }
                </div>
            </>
        );
    }

    render() {
        const { option: { product } } = this.props;

        return (
            <div block="ProductBundleItem" elem="Product">
                <div block="ProductBundleItem" elem="ProductImage">
                    { this.renderProductVisual(product) }
                </div>
                <div block="ProductBundleItem" elem="ProductContent">
                    { this.renderProductDetails() }
                </div>
            </div>
        );
    }
}

export default ProductBundleOptionDetailsComponent;
