/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class AcQuery {
    _getAcConfigurationFields = () => ([
        'enabled',
        'ac_id'
    ]);


    getEvents = () => ([
        'ac_general_init',

    ]);
    getAcConfiguration = () => new Field('getActiveCampaign')
        .setAlias('active_campaign')
        .addFieldList(this._getAcConfigurationFields());
}

export default new AcQuery();
