import ExpandableContent from '@scandipwa/scandipwa/src/component/ExpandableContent';
import history from '@scandipwa/scandipwa/src/util/History';
import { appendWithStoreCode } from '@scandipwa/scandipwa/src/util/Url';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

/** @namespace PageBuilder/Component/Tab/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace PageBuilder/Component/Tab/Component/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    // ...
});

/** @namespace PageBuilder/Component/Tab/Component */
export class Tab extends React.PureComponent {
    static propTypes = {
        ...super.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            activeIdx: 0
        };
    }

    onClickTabMenu = (e, idx) => {
        e.stopPropagation();
        e.preventDefault();

        const { elements: { TabItem } } = this.props;

        const target = TabItem.propsBag[idx]['data-target'];
        if (target) {
            history.push(appendWithStoreCode(`/${ target }`));
        }

        this.setState({ activeIdx: idx });
    };

    renderTabContent = (TabItem, activeIdx, TabContent) => (
        <TabContent.Ele>
            { TabItem.propsBag.map((props, idx) => activeIdx === idx
                && (
                    <TabItem.Ele
                      key={ `tab-content-item-${idx}` }
                      { ...props }
                    >
                        { TabItem.childEleBag[idx] }
                    </TabItem.Ele>
                )) }
        </TabContent.Ele>
    );

    renderTabMenuImage = (TabMenuImageWrapper, TabMenuImage, idx) => {
        if (TabMenuImage?.propsBag[idx]?.src) {
            return (
                <TabMenuImageWrapper.Ele>
                    <TabMenuImage.Ele { ...TabMenuImage.propsBag[idx] } />
                </TabMenuImageWrapper.Ele>
            );
        }

        return null;
    };

    renderTabMenu = (TabMenuHeader, activeIdx, TabMenuLink, TabMenuImageWrapper, TabMenuImage, TabMenuTitle, TabMenu) => (
        <TabMenu.Ele
          className="tabs-navigation ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
        >
            { TabMenuHeader.propsBag.map((props, idx) => (
                    <TabMenuHeader.Ele
                      { ...props }
                      key={ `tab-menu-${idx}` }
                      className={ classNames('tab-header ui-state-default ui-corner-top', {
                          'ui-tabs-active ui-state-active': activeIdx === idx,
                          'has-image': !!TabMenuImage?.propsBag[idx]?.src
                      }) }
                      onClick={ (e) => this.onClickTabMenu(e, idx) }
                    >
                        <TabMenuLink.Ele { ...TabMenuLink.propsBag[idx] }>
                            { this.renderTabMenuImage(TabMenuImageWrapper, TabMenuImage, idx) }
                            <TabMenuTitle.Ele>{ TabMenuTitle.childData[idx] }</TabMenuTitle.Ele>
                        </TabMenuLink.Ele>
                    </TabMenuHeader.Ele>
            )) }
        </TabMenu.Ele>
    );

    renderMobileContent = (TabMenuHeader, TabMenuTitle, TabItem) => (
        <>
            { TabMenuHeader.propsBag.map((props, idx) => (
                <ExpandableContent
                  heading={ TabMenuTitle.childData[idx] }
                  mix={ { block: 'Tab', elem: 'ToggleItem' } }
                >
                    { TabItem.childEleBag[idx] }
                </ExpandableContent>
            )) }
        </>
    );

    render() {
        const {
            elements: {
                BaseTabs,
                TabMenu,
                TabMenuHeader,
                TabMenuLink,
                TabMenuImageWrapper,
                TabMenuImage,
                TabMenuTitle,
                TabContent,
                TabItem
            },
            isMobile
        } = this.props;
        const { activeIdx } = this.state;

        if (isMobile && !TabMenuImage) {
            return (
                <BaseTabs.Ele>
                    { this.renderMobileContent(TabMenuHeader, TabMenuTitle, TabItem) }
                </BaseTabs.Ele>
            );
        }

        return (
            <BaseTabs.Ele>
                { this.renderTabMenu(TabMenuHeader, activeIdx, TabMenuLink, TabMenuImageWrapper, TabMenuImage, TabMenuTitle, TabMenu) }
                { this.renderTabContent(TabItem, activeIdx, TabContent) }
            </BaseTabs.Ele>
        );
    }
}

// export default Tab;
export default connect(mapStateToProps, mapDispatchToProps)(Tab);
