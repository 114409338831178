import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    DEMO_NOTICE,
    FOOTER,
    FORGOT_PASSWORD,
    HEADER,
    HOME,
    LOGIN,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    PRINT_ORDER,
    SEARCH,
    SHARED_WISHLIST,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    URL_ONLY_MAIN_ITEMS_RENDER as SOURCE_URL_ONLY_MAIN_ITEMS_RENDER,
    URL_REWRITES
} from 'SourceComponent/Router/Router.config';

// BEFORE_ITEMS_TYPE
export const TOP_PAGE_PROMO_BANNER = 'TOP_PAGE_PROMO_BANNER';
export const CONTENT_TOP_PROMO_BANNER = 'CONTENT_TOP_PROMO_BANNER';
export const MY_ACCOUNT_BANNER = 'MY_ACCOUNT_BANNER';

// SWITCH_ITEMS_TYPE
export const FAQ = 'FAQ';
export const SAGA = 'SAGA';
export const NEWS = 'NEWS';
export const LOGIN_PRO = 'LOGIN_PRO';
export const LOGIN_STUDENT = 'LOGIN_STUDENT';
export const CREATE_ACCOUNT_PRO = 'CREATE_ACCOUNT_PRO';
export const CREATE_ACCOUNT_STUDENT = 'CREATE_ACCOUNT_STUDENT';
export const CREATE_ACCOUNT_EMPLOYEE = 'CREATE_ACCOUNT_EMPLOYEE';
export const COLLECTIONS = 'COLLECTIONS';
export const TUTOS = 'TUTOS';
export const INTERACTIVE_CATALOG = 'INTERACTIVE_CATALOG';
export const MY_ACCOUNT_ORDERS_FAVORITES = 'MY_ACCOUNT_ORDERS_FAVORITES';
export const MY_ACCOUNT_QUICK_ORDER = 'MY_ACCOUNT_QUICK_ORDER';
export const GDPR_POPUP = 'GDPR_POPUP';
export const PAYTWEAK_ORDER_CONSENT = 'PAYTWEAK_ORDER_CONSENT';
export const DOCUMENT_LIST = 'DOCUMENT_LIST';
export const LOGIN_AS_CUSTOMER = 'LOGIN_AS_CUSTOMER';
export const HELPDESK_EXTERNAL = 'HELPDESK_EXTERNAL';

export {
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE,
    AFTER_ITEMS_TYPE,
    NOTIFICATION_LIST,
    NAVIGATION_TABS,
    DEMO_NOTICE,
    HEADER,
    BREADCRUMBS,
    NEW_VERSION_POPUP,
    HOME,
    SEARCH,
    CMS_PAGE,
    CART,
    CHECKOUT,
    CHANGE_PASSWORD,
    CREATE_ACCOUNT,
    LOGIN,
    ACCOUNT_FORGOT_PASSWORD,
    FORGOT_PASSWORD,
    CONFIRM_ACCOUNT,
    MY_ACCOUNT,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_WISHLIST,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_NEWSLETTER,
    MENU,
    SHARED_WISHLIST,
    CONTACT_PAGE,
    COMPARE,
    STYLE_GUIDE,
    URL_REWRITES,
    FOOTER,
    COOKIE_POPUP,
    PRINT_ORDER
    // URL_ONLY_MAIN_ITEMS_RENDER
};

export const URL_ONLY_MAIN_ITEMS_RENDER = [
    ...SOURCE_URL_ONLY_MAIN_ITEMS_RENDER,
    '/misc/header',
    '/misc/footer'
];
