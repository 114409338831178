import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { RefType } from 'Type/Common.type';
import { getCartId } from 'Util/Cart';
import { setPaymentToken } from 'Util/PaymentTokenPersistence';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { validateGroup } from 'Util/Validator';

import BNPMercanet from './BNPMercanet.component';
import { BNPMERCANET_CREDIT_CART } from './BNPMercanet.config';

/** @namespace PeggysagePwa/Component/BNPMercanet/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace PeggysagePwa/Component/BNPMercanet/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e)),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error)))
});

/** @namespace PeggysagePwa/Component/BNPMercanet/Container */
export class BNPMercanetContainer extends PureComponent {
    static propTypes = {
        selectedPaymentCode: PropTypes.string.isRequired,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        areTermsAccepted: PropTypes.bool,
        formRef: RefType
    };

    static defaultProps = {
        areTermsAccepted: false,
        formRef: null
    };

    state = {
        isDisabled: true
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this)
    };

    componentDidMount() {
        this.checkIsDisabled();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { areTermsAccepted } = this.props;
        const { areTermsAccepted: prevAreTermsAccepted } = prevProps;

        if (areTermsAccepted !== prevAreTermsAccepted) {
            this.checkIsDisabled();
        }
    }

    containerProps() {
        const { isDisabled } = this.state;

        return {
            isDisabled
        };
    }

    checkIsDisabled() {
        const { areTermsAccepted } = this.props;
        this.setState({ isDisabled: !areTermsAccepted });
    }

    async handleClick() {
        const { areTermsAccepted, formRef } = this.props;

        if (areTermsAccepted) {
            this.placeOrder();

            return;
        }

        if (formRef && formRef.current) {
            validateGroup(formRef.current);
        }
    }

    async placeOrder() {
        const { selectedPaymentCode, setLoading, showErrorNotification } = this.props;

        setLoading(true);

        const cart_id = getCartId();

        if (selectedPaymentCode === BNPMERCANET_CREDIT_CART) {
            try {
                await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                    cart_id,
                    payment_method: {
                        code: selectedPaymentCode,
                        purchase_order_number: null
                    }
                }));
                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
                const { placeOrder: { payment_redirect_url, payment_external_token } } = orderData;

                setLoading(false);

                if (payment_redirect_url) {
                    setPaymentToken(payment_external_token);
                    top.location.href = payment_redirect_url;
                }
            } catch (error) {
                showErrorNotification(error);
                setLoading(false);
            }
        }
    }

    render() {
        return (
            <BNPMercanet
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BNPMercanetContainer);
