/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class FbpQuery {
    _getFBPConfigurationFields = () => ([
        'enabled',
        'fbp_id',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'fbp_general_init',
        'fbp_impressions',
        'fbp_product_click',
        'fbp_product_detail',
        'fbp_product_add_to_cart',
        'fbp_product_remove_from_cart',
        'fbp_purchase',
        'fbp_checkout',
        'fbp_checkout_option',
        'fbp_user_login',
        'fbp_user_register',
        'fbp_not_found',
        'fbp_category_filters',
        'fbp_additional'
    ]);

    getFBPConfiguration = () => new Field('getFbp')
        .setAlias('fbp')
        .addFieldList(this._getFBPConfigurationFields());
}

export default new FbpQuery();
