import PropTypes from 'prop-types';

export const DocumentCategoryType = PropTypes.shape({
    category_id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
    document_count: PropTypes.number
});

export const DocumentType = PropTypes.shape({
    document_id: PropTypes.number,
    title: PropTypes.string,
    customer_group_ids: PropTypes.arrayOf(PropTypes.number),
    creation_time: PropTypes.string,
    thumbnail: PropTypes.string,
    file: PropTypes.string,
    file_size: PropTypes.number,
    type: PropTypes.string,
    video: PropTypes.string,
    page_link: PropTypes.shape({
        identifier: PropTypes.string,
        title: PropTypes.string
    }),
    external_link: PropTypes.string,
    category_link: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string
    }),
    products_count: PropTypes.number,
    single_product: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        sku: PropTypes.string,
        url: PropTypes.string
    })),
    products: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        sku: PropTypes.string,
        url: PropTypes.string,
        small_image: PropTypes.shape({
            url: PropTypes.string
        })
    })),
    document_category: DocumentCategoryType
});
