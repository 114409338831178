import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace PeggysagePwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    trainingCategoryInfo: state.ConfigReducer.trainingCategoryInfo
});

/** @namespace PeggysagePwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    static propTypes = {
        ...SourceUrlRewritesContainer.propTypes,
        trainingCategoryInfo: PropTypes.shape({
            id: PropTypes.number,
            url: PropTypes.string
        })
    };

    static defaultProps = {
        trainingCategoryInfo: {
            // eslint-disable-next-line no-magic-numbers
            id: 159,
            url: '/fr-FR/formations'
        }
    };

    /**
     * Override: add training category ID
     */
    containerProps() {
        const { trainingCategoryInfo: { id: trainingCategoryId } } = this.props;

        return {
            ...super.containerProps(),
            trainingCategoryId
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
