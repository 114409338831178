/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_MATOMO_CHECKOUT = 'matomo_checkout';
export const EVENT_MATOMO_CHECKOUT_OPTION = 'matomo_checkout_option';
export const EVENT_MATOMO_IMPRESSIONS = 'matomo_impressions';
export const EVENT_MATOMO_IMPRESSIONS_PLP = 'matomo_impressions_plp';
export const EVENT_MATOMO_IMPRESSIONS_HOME = 'matomo_impressions_home';
export const EVENT_MATOMO_IMPRESSIONS_CROSS_SELL = 'matomo_impressions_cross_sell';
export const EVENT_MATOMO_IMPRESSIONS_WISHLIST = 'matomo_impressions_wishlist';
export const EVENT_MATOMO_IMPRESSIONS_SEARCH = 'matomo_impressions_search';
export const EVENT_MATOMO_IMPRESSIONS_LINKED = 'matomo_impressions_linked';
export const EVENT_MATOMO_META_UPDATE = 'matomo_meta_update';
export const EVENT_MATOMO_GENERAL_INIT = 'matomo_general_init';
export const EVENT_MATOMO_PRODUCT_ADD_TO_CART = 'matomo_product_add_to_cart';
export const EVENT_MATOMO_PRODUCT_CLICK = 'matomo_product_click';
export const EVENT_MATOMO_PRODUCT_DETAIL = 'matomo_product_detail';
export const EVENT_MATOMO_PRODUCT_REMOVE_FROM_CART = 'matomo_product_remove_from_cart';
export const EVENT_MATOMO_PURCHASE = 'matomo_purchase';
export const EVENT_MATOMO_USER_LOGIN = 'matomo_user_login';
export const EVENT_MATOMO_USER_REGISTER = 'matomo_user_register';
