import {
    ACCOUNT_EMPLOYEE_REGISTRATION_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_PRO_LOGIN_URL,
    ACCOUNT_PRO_REGISTRATION_URL,
    ACCOUNT_REGISTRATION_URL,
    ACCOUNT_STUDENT_LOGIN_URL,
    ACCOUNT_STUDENT_REGISTRATION_URL,
    GROUP_ID_EMPLOYEE,
    GROUP_ID_PRO,
    GROUP_ID_STUDENT
} from 'Route/MyAccount/MyAccount.config';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getStoreCodePath } from 'Util/Request/Request';

/**
 * @param {int} groupId
 * @returns {string}
 * @namespace PeggysagePwa/Util/MyAccount/Index/getLoginUrlByGroupId
 */
export const getLoginUrlByGroupId = (groupId) => {
    switch (groupId) {
    case GROUP_ID_PRO:
    case GROUP_ID_EMPLOYEE:
        return ACCOUNT_PRO_LOGIN_URL;
    case GROUP_ID_STUDENT:
        return ACCOUNT_STUDENT_LOGIN_URL;
    default:
        return ACCOUNT_LOGIN_URL;
    }
};

/**
 * @param {int} groupId
 * @returns {string}
 * @namespace PeggysagePwa/Util/MyAccount/Index/getRegistrationUrlByGroupId
 */
export const getRegistrationUrlByGroupId = (groupId) => {
    switch (groupId) {
    case GROUP_ID_PRO:
        return ACCOUNT_PRO_REGISTRATION_URL;
    case GROUP_ID_EMPLOYEE:
        return ACCOUNT_EMPLOYEE_REGISTRATION_URL;
    case GROUP_ID_STUDENT:
        return ACCOUNT_STUDENT_REGISTRATION_URL;
    default:
        return ACCOUNT_REGISTRATION_URL;
    }
};

/**
 * @namespace PeggysagePwa/Util/MyAccount/Index/handleRedirectToAllowedStore
 */
export const handleRedirectToAllowedStore = () => {
    if (!isSignedIn()) {
        return;
    }

    const { allowed_stores = [] } = BrowserDatabase.getItem(CUSTOMER) || {};
    if (!allowed_stores || !allowed_stores.length) {
        return;
    }

    const storeCode = getStoreCodePath().replace('/', '');

    // Current store is allowed to customer => do nothing
    if (allowed_stores.find((store) => store.code === storeCode)) {
        return;
    }

    // Redirect to default store (if active) or first active store allowed
    const defaultStore = allowed_stores.find((store) => store.is_default_store && store.is_active)
        || allowed_stores.find((store) => store.is_active);

    if (defaultStore && defaultStore.base_link_url) {
        window.location = defaultStore.base_link_url;
    }
};
