import {
    UPDATE_CURRENT_CATEGORY,
    updateCurrentCategory
} from 'SourceStore/Category/Category.action';

export {
    UPDATE_CURRENT_CATEGORY,
    updateCurrentCategory
};

export const RESET_CURRENT_CATEGORY = 'RESET_CURRENT_CATEGORY';

/**
 * @namespace PeggysagePwa/Store/Category/Action/resetCurrentCategory
 */
export const resetCurrentCategory = () => ({
    type: RESET_CURRENT_CATEGORY
});
