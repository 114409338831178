import { connect } from 'react-redux';

import WishlistQuery from 'Query/Wishlist.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ShareWishlistPopupContainer as SourceShareWishlistPopupContainer
} from 'SourceComponent/ShareWishlistPopup/ShareWishlistPopup.container';
import { CustomerType } from 'Type/Account.type';
// import { isSignedIn } from 'Util/Auth';
import { fetchMutation, getErrorMessage } from 'Util/Request';

/** @namespace PeggysagePwa/Component/ShareWishlistPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/ShareWishlistPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer
});

/** @namespace PeggysagePwa/Component/ShareWishlistPopup/Container */
export class ShareWishlistPopupContainer extends SourceShareWishlistPopupContainer {
    static propTypes = {
        ...SourceShareWishlistPopupContainer.propTypes,
        customer: CustomerType.isRequired
    };

    /**
     * Override
     * @return {{isFormLoading: boolean}}
     */
    containerProps() {
        const { customer } = this.props;

        return {
            ...super.containerProps(),
            customer
        };
    }

    /**
     * Override: allow unsigned users
     * @param fields
     */
    handleFormData(fields) {
        const {
            hidePopup, showError, showNotification, goToPreviousNavigationState
        } = this.props;
        const { name = '', message, emails: initialEmails } = fields;
        const emails = initialEmails.split(',').map((email) => email.trim());

        // if (!isSignedIn()) {
        //     return;
        // }

        this.setState({ isLoading: true });

        fetchMutation(WishlistQuery.getShareWishlistMutation({ message, emails, name })).then(
            /** @namespace PeggysagePwa/Component/ShareWishlistPopup/Container/ShareWishlistPopupContainer/handleFormData/fetchMutation/then  */
            () => {
                showNotification(__('Wishlist has been shared'));
                hidePopup();
                this.setState({ isLoading: false });
                goToPreviousNavigationState();
            },
            /** @namespace PeggysagePwa/Component/ShareWishlistPopup/Container/ShareWishlistPopupContainer/handleFormData/fetchMutation/then/catch  */
            (error) => {
                showError(getErrorMessage(error));
                this.setState({ isLoading: false });
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareWishlistPopupContainer);
