import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    addOrderToFavorites(incrementId) {
        return new Field('addOrderToFavorites')
            .addArgument('incrementId', 'String!', incrementId);
    }

    removeOrderFromFavorites(incrementId) {
        return new Field('removeOrderFromFavorites')
            .addArgument('incrementId', 'String!', incrementId);
    }

    /**
     * Override: possibility to change page size
     * @param options
     * @returns {Field}
     * @private
     */
    _getOrdersField(options) {
        const {
            orderId,
            page = 1,
            // eslint-disable-next-line no-magic-numbers
            pageSize = 5,
            is_favorite = null,
            created_at = null,
            number
        } = options || {};
        const ordersField = new Field('orders');

        const filter = {};

        if (orderId) {
            filter.entity_id = { eq: orderId };

            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', filter)
                .addFieldList(this._getOrdersFields(true));
        }

        if (is_favorite) {
            filter.is_favorite = { eq: true };
        }

        if (created_at && Object.keys(created_at).length) {
            filter.created_at = created_at;
        }

        // Increment_id
        if (number) {
            filter.number = number;
        }

        return ordersField
            .addArgument('filter', 'CustomerOrdersFilterInput', filter)
            .addArgument('currentPage', 'Int', page)
            .addArgument('pageSize', 'Int', pageSize)
            .addFieldList(this._getOrdersFields());
    }

    /**
     * Override: add years
     * @param isSingleOrder
     * @returns {(string|*)[]}
     * @private
     */
    _getOrdersFields(isSingleOrder = false) {
        return [
            ...super._getOrdersFields(isSingleOrder),
            'year_filter_options'
        ];
    }

    /**
     * Override: add timeline and products to order list
     */
    _getOrderItemsFields(isSingleOrder) {
        const basicFields = [
            'id',
            'increment_id',
            'order_date',
            'status',
            'can_reorder',
            'rss_link',
            this._getOrderItemTotalField(),
            'is_favorite'
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderFields()];
        }

        return [
            ...basicFields,
            this._getOrderListOrderItemsProductsField(),
            this._getOrderTimelineField()
        ];
    }

    _getOrderListOrderItemsProductsField() {
        return new Field('items')
            .addFieldList(this._getOrderListOrderItemsProductsFields());
    }

    _getOrderListOrderItemsProductsFields() {
        return [
            this._getOrderProductDataField()
        ];
    }

    /**
     * Override: add timeline field
     * @returns {*[]}
     * @private
     */
    _getSingleOrderFields() {
        return [
            ...super._getSingleOrderFields(),
            this._getOrderShippingMethodCodeField(),
            this._getOrderTimelineField(),
            this._getOrderPdfInvoiceField(),
            this._getShipmentsField()
        ];
    }

    _getOrderTimelineField() {
        return new Field('timeline')
            .addFieldList(this._getOrderTimelineFields());
    }

    _getOrderTimelineFields() {
        return [
            'status',
            'date',
            'date_estimated',
            'label'
        ];
    }

    _getOrderPdfInvoiceField() {
        return new Field('pdfInvoice');
    }

    _getOrderShippingMethodCodeField() {
        return new Field('shipping_method_code');
    }

    _getTrackingFields() {
        return [
            'title',
            'carrier',
            'number',
            'tracking_url'
        ];
    }

    _getTrackingField() {
        return new Field('tracking')
            .addFieldList(this._getTrackingFields());
    }

    _getShipmentsFields() {
        return [
            'number',
            this._getTrackingField()
        ];
    }

    _getShipmentsField() {
        return new Field('shipments')
            .addFieldList(this._getShipmentsFields());
    }

    /**
     * Override: add product field
     * @private
     */
    _getOrderItemProductsFields() {
        return [
            ...super._getOrderItemProductsFields(),
            this._getOrderProductDataField()
        ];
    }

    _getOrderProductDataField() {
        return new Field('product')
            .addFieldList([
                'name',
                'sku',
                // 'couleur',
                'contenance_label',
                'code_barre',
                new Field('thumbnail').addFieldList([
                    'label',
                    'url'
                ])
            ]);
    }

    /**
     * Override: add company field
     * @returns {(string|*)[]}
     * @private
     */
    _getOrderAddressFields() {
        return [
            ...super._getOrderAddressFields(),
            'company'
        ];
    }
}

export default new OrderQuery();
