import {
    clearWishlist,
    deleteProperty,
    getInitialState as sourceGetInitialState,
    PRODUCTS_IN_WISHLIST,
    removeItemFromWishlist,
    updateAllProductsInWishlist,
    updateItemOptions
} from 'SourceStore/Wishlist/Wishlist.reducer';

import {
    CLEAR_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS,
    UPDATE_WISHLIST,
    updateWishlist
} from './Wishlist.action';

export {
    clearWishlist,
    deleteProperty,
    PRODUCTS_IN_WISHLIST,
    removeItemFromWishlist,
    updateAllProductsInWishlist,
    updateItemOptions
};

/**
 * Override
 * @returns {{isLoading: boolean, wishlist: {}, productsInWishlist}}
 * @namespace PeggysagePwa/Store/Wishlist/Reducer/getInitialState
 */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    wishlist: {}
});

/**
 * Override
 * @namespace PeggysagePwa/Store/Wishlist/Reducer/WishlistReducer
 */
export const WishlistReducer = (
    state = getInitialState(),
    action
) => {
    const { type, options } = action;

    switch (type) {
    case REMOVE_ITEM_FROM_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...removeItemFromWishlist(action, state)
        };

    case CLEAR_WISHLIST:
        return {
            ...state,
            ...clearWishlist()
        };

    case UPDATE_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...updateWishlist(action).wishlist
        };

    case UPDATE_ALL_PRODUCTS_IN_WISHLIST: {
        return {
            ...state,
            isLoading: false,
            ...updateAllProductsInWishlist(action)
        };
    }

    case UPDATE_ITEM_OPTIONS:
        return {
            ...state,
            ...updateItemOptions(options, state)
        };

    case UPDATE_IS_LOADING_IN_WISHLIST:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default WishlistReducer;
