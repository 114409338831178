import {
    ONE_STAR_SHARE,
    STARS_COUNT
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.config';

export {
    ONE_STAR_SHARE,
    STARS_COUNT
};

export const STARS_GRANULARITY_PERCENT = 10; // for complete stars (10 in source)
export const STARS_GRANULARITY_PERCENT_LIMIT = 5;
