import { UPDATE_PAGES } from './Page.action';

/** @namespace PeggysagePwa/Store/Page/Reducer/getInitialState */
export const getInitialState = () => ({
    pages: [],
    isloaded: false
});

/** @namespace PeggysagePwa/Store/Page/Reducer/PageReducer */
export const PageReducer = (state = getInitialState(), action) => {
    const { type, pages } = action;

    switch (type) {
    case UPDATE_PAGES:
        return {
            pages,
            isloaded: true
        };
    default:
        return state;
    }
};

export default PageReducer;
