/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const EVENT_GENERAL = 'general';
export const EVENT_PURCHASE = 'purchase';
export const DATA_LAYER_NAME = 'dataLayerGoogleMerchant';
export const GROUPED_PRODUCTS_PREFIX = 'GROUPED_PRODUCTS_';
export const GROUPED_PRODUCTS_GUEST = 'GROUPED_PRODUCTS_GUEST';
export const GOOGLE_MERCHANT_INJECTION_TIMEOUT = 4000;
