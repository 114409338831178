import { SET_ISSUE, SET_ISSUES } from './Issue.action';

/** @namespace PeggysagePwa/Store/Issue/Reducer/getInitialState */
export const getInitialState = () => ({
    tickets: [],
    ticket: null
});

/** @namespace PeggysagePwa/Store/Issue/Reducer/IssueReducer */
export const IssueReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_ISSUE:
        const { ticket } = action;
        return {
            ...state,
            ticket
        };
    case SET_ISSUES:
        const { tickets } = action;
        return {
            ...state,
            tickets
        };

    default:
        return state;
    }
};

export default IssueReducer;
