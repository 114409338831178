/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FacebookPixelRouteWrapperComponent from './FacebookPixelRouteWrapper.component';

export const withFBP = (originalRender, fbpRoute) => (
    <FacebookPixelRouteWrapperComponent route={ fbpRoute }>
        { originalRender }
    </FacebookPixelRouteWrapperComponent>
);

export default withFBP;
