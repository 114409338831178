// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { CategoryContentPushType } from 'Type/Category.type';

import './ProductListContentPush.style';

/** @namespace PeggysagePwa/Component/ProductListContentPush/Component */
export class ProductListContentPushComponent extends PureComponent {
    static propTypes = {
        contentPush: CategoryContentPushType.isRequired
    };

    renderVisual() {
        const { contentPush: { image_url } } = this.props;

        if (!image_url) {
            return null;
        }

        return (
            <div block="ProductListContentPush" elem="Visual">
                <Image
                  mix={ { block: 'ProductListContentPush', elem: 'Image' } }
                  src={ image_url }
                  alt=""
                  ratio="square"
                />
            </div>
        );
    }

    renderSuperTitle() {
        const { contentPush: { supertitle } } = this.props;

        if (!supertitle) {
            return null;
        }

        return (
            <div block="ProductListContentPush" elem="SuperTitle">
                { supertitle }
            </div>
        );
    }

    renderTitle() {
        const { contentPush: { title } } = this.props;

        if (!title) {
            return null;
        }

        return (
            <div block="ProductListContentPush" elem="Title">
                { title }
            </div>
        );
    }

    renderContentPush() {
        return (
            <>
                { this.renderVisual() }
                <div block="ProductListContentPush" elem="Content">
                    { this.renderSuperTitle() }
                    { this.renderTitle() }
                </div>
            </>
        );
    }

    renderContent() {
        const { contentPush: { page_identifier } } = this.props;

        if (page_identifier) {
            return (
                <Link
                  block="ProductListContentPush"
                  elem="Link"
                  to={ page_identifier }
                >
                    { this.renderContentPush() }
                </Link>
            );
        }

        return (
            <div block="ProductListContentPush" elem="Inner">
                { this.renderContentPush() }
            </div>
        );
    }

    render() {
        return (
            <li block="ProductListContentPush">
                { this.renderContent() }
            </li>
        );
    }
}

export default ProductListContentPushComponent;
