import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';

export const DOCUMENT_SEARCH_PAGE_SIZE_DEFAULT = 6;

/** @namespace PeggysagePwa/Query/Document/Query */
export class DocumentQuery {
    getDocumentCategories() {
        return new Field('getDocumentCategories')
            .addFieldList(this._getDocumentCategoriesFields());
    }

    getDocuments(categoryId) {
        return new Field('getDocuments')
            .addArgument('category_id', 'Int!', categoryId)
            .addFieldList(this._getDocumentsFields());
    }

    getDocument(documentId, currentPage = 1) {
        return new Field('getDocument')
            .addArgument('document_id', 'Int', documentId)
            .addArgument('current_page', 'Int', currentPage)
            .addFieldList(this._getDocumentsFields(true));
    }

    getRecentDocuments() {
        return new Field('getRecentDocuments')
            .addFieldList(this._getDocumentsFields());
    }

    searchDocuments(options) {
        const {
            args: {
                search = '',
                pageSize = DOCUMENT_SEARCH_PAGE_SIZE_DEFAULT,
                currentPage = 1
            } = {}
        } = options;

        const query = new Field('searchDocuments')
            .addArgument('search', 'String!', search.replaceAll('%22', ''))
            .addArgument('pageSize', 'Int!', pageSize)
            .addArgument('currentPage', 'Int', currentPage);

        query.addFieldList(this._getSearchDocumentsFields());

        return query;
    }

    _getDocumentCategoriesFields() {
        return [
            'category_id',
            'name',
            'image',
            'document_count'
        ];
    }

    _getDocumentsFields(withProducts = false) {
        const fields = [
            'document_id',
            'title',
            'customer_group_ids',
            'creation_time',
            'thumbnail',
            'file',
            'file_size',
            'type',
            'video',
            new Field('page_link').addFieldList([
                'identifier',
                'title'
            ]),
            'external_link',
            new Field('category_link').addFieldList([
                'name',
                'url'
            ]),
            new Field('document_category').addFieldList([
                'category_id',
                'name'
            ]),
            'products_count',
            new Field('single_product').addFieldList([
                'name',
                'sku',
                'url'
            ])
        ];

        if (withProducts) {
            fields.push(new Field('products').addFieldList([
                'name',
                'sku',
                'url',
                ProductListQuery._getProductSmallField()
            ]));
        }

        return fields;
    }

    _getSearchDocumentsFields() {
        return [
            'total_count',
            new Field('items').addFieldList(this._getDocumentsFields()),
            new Field('page_info').addFieldList([
                'current_page',
                'page_size',
                'total_pages'
            ])
        ];
    }
}

export default new DocumentQuery();
