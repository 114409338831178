/* eslint-disable simple-import-sort/sort */
import {
    ORDER_STATUS_CANCELED,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_PROCESSING,
    ORDER_STATUS_SHIPPED
} from 'Component/MyAccountOrderTimeline/MyAccountOrderTimeline.config';
import {
    getFormattedDate,
    formatOrders,
    getOrderItemQtyToArray,
    getProductFromOrder,
    getOrderItemRowDiscount
} from 'SourceUtil/Orders/Orders';

export {
    getFormattedDate,
    formatOrders,
    getOrderItemQtyToArray,
    getProductFromOrder,
    getOrderItemRowDiscount
};

/** @namespace PeggysagePwa/Util/Orders/formatOrderTimeline */
export const formatOrderTimeline = (sourceTimeline = [], isListing = false) => {
    // console.log('sourceTimeline', sourceTimeline);

    const timeline = [];

    const canceledStatus = sourceTimeline.find((item) => item.status === ORDER_STATUS_CANCELED);
    if (canceledStatus) {
        canceledStatus.active = true;
        canceledStatus.label = __('Commande annulée');
        timeline.push(canceledStatus);
        return timeline;
    }

    const pendingStatus = sourceTimeline.find((item) => item.status === ORDER_STATUS_PENDING);
    if (pendingStatus && sourceTimeline.length === 1) {
        pendingStatus.active = true;
        timeline.push(pendingStatus);
        return timeline;
    }

    // If not pending nor canceled, remove those steps from timeline
    timeline.push(...sourceTimeline.filter(
        (item) => ![ORDER_STATUS_CANCELED, ORDER_STATUS_PENDING].includes(item.status)
    ));

    // Update labels
    timeline.forEach((step, index) => {
        const { status } = step;
        const newStep = Object.assign(step);
        switch (status) {
        case ORDER_STATUS_PROCESSING:
            newStep.label = isListing ? __('Commande en préparation') : __('Préparation de votre commande');
            break;
        case ORDER_STATUS_SHIPPED:
            newStep.label = isListing ? __('Comande expédiée') : __('Expédition de votre commande');
            break;
        /*
        // Can't change this label because it's different for PICKUP, and we don't have method type here
        case ORDER_STATUS_ESTIMATED_DROP:
            newStep.label = __('Livraison de votre commande');
            break;
        */
        default:
        }

        if (index === timeline.length - 1) {
            newStep.active = true;
        }

        timeline[index] = newStep;
    });

    return timeline;
};
