/* eslint-disable max-lines */
import CollectionQuery from 'Query/Collection.query';
import ConfigQuery from 'Query/Config.query';
import ProductListQuery from 'Query/ProductList.query';
import {
    CmsPageQuery as SourceCmsPageQuery
} from 'SourceQuery/CmsPage.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/CmsPage/Query */
export class CmsPageQuery extends SourceCmsPageQuery {
    _getCategoryFields() {
        return [
            'name',
            'uid'
        ];
    }

    _getCategoryField() {
        return new Field('category')
            .addFieldList(this._getCategoryFields());
    }

    _getCategoriesFields() {
        return [
            'name',
            'uid'
        ];
    }

    _getCategoriesField() {
        return new Field('categories')
            .addFieldList(this._getCategoriesFields());
    }

    _getReviewsItemsFields() {
        return [
            'created_at',
            'nickname',
            'text',
            'answer',
            'answer_created_at',
            'customer_group_id'
        ];
    }

    _getReviewsItemsField() {
        return [
            new Field('items')
                .addFieldList(this._getReviewsItemsFields())
        ];
    }

    _getReviewsField() {
        return new Field('reviews')
            .addFieldList(this._getReviewsItemsField());
    }

    _getPageTeaserFields() {
        return [
            'identifier',
            'page_type',
            'title',
            'tag_label',
            'limitation_customer_group_ids',
            'content_image',
            'content_video',
            this._getCategoryField()
        ];
    }

    _getTutorialRelatedPagesField() {
        return new Field('tutorial_related_pages')
            .addFieldList(this._getPageTeaserFields());
    }

    _getTutorialProductsField() {
        return new Field('tutorial_products')
            .addFieldList(
                ProductListQuery
                    .setOptions({ isPlp: true, isSingleProduct: false })
                    ._getProductInterfaceFields(false, false, false)
            );
    }

    _getPageFields() {
        // const fields = super._getPageFields();

        return [
            'title',
            'content',
            'page_width',
            'content_heading',
            'meta_title',
            'meta_description',
            'meta_keywords',
            'page_type',
            'limitation_customer_group_ids',
            'content_description',
            'content_description_desktop',
            'content_image',
            'content_video',
            'content_image_desktop',
            'tutorial_skus',
            this._getTutorialProductsField(),
            this._getCategoryField(),
            this._getCategoriesField(),
            'review_count',
            this._getReviewsField(),
            this._getTutorialRelatedPagesField()
        ];
    }

    // PAGES / TUTOS

    getPageList(options) {
        const {
            // eslint-disable-next-line no-magic-numbers
            page_size = 10,
            current_page = 1,
            page_type = '',
            category_uid = ''
        } = options;

        return new Field('getPageList')
            .addArgument('page_size', 'Int', page_size)
            .addArgument('current_page', 'Int', current_page)
            .addArgument('page_type', 'String', page_type)
            .addArgument('category_uid', 'String', category_uid)
            .addFieldList(this._getPageListFields());
    }

    _getPageListFields() {
        return [
            'total_items',
            new Field('all_categories')
                .addFieldList(this._getCategoriesFields()),
            new Field('items')
                .addFieldList(this._getPageListItemsFields())
        ];
    }

    _getPageListItemsFields() {
        return [
            ...this._getPageTeaserFields(),
            'featured_customer_group_ids',
            this._getCategoriesField()
        ];
    }

    // HOMEPAGE

    getHomepagePositions() {
        return new Field('getHomepagePositions')
            .addFieldList(this._getHomepagePositionsFields());
    }

    _getHomepagePositionsFields() {
        return [
            'slider',
            'trend',
            'content',
            'shop',
            'tutorials',
            'bestsellers',
            'training',
            'collection',
            'socials',
            'news',
            'brand',
            'cms_block_1',
            'cms_block_2',
            'cms_block_3'
        ];
    }

    getPushCategoryHomepage() {
        return new Field('getPushCategoryHomepage')
            .addFieldList(this._getPushCategoryHomepageFields());
    }

    _getPushCategoryHomepageFields() {
        return [
            'name',
            'url',
            'image_for_push'
        ];
    }

    getPushContentHomepage() {
        return new Field('getPushContentHomepage')
            .addFieldList(this._getPushContentHomepageFields());
    }

    _getPushContentHomepageFields() {
        return [
            'supertitle',
            'title',
            'url',
            'image'
        ];
    }

    getPushProductHomepage() {
        return new Field('getPushProductHomepage')
            .addFieldList(this._getPushProductHomepageFields());
    }

    _getPushProductHomepageFields() {
        return [
            'name',
            'sku',
            new Field('image').addFieldList([
                'url'
            ])
        ];
    }

    getPushPageHomepage() {
        return new Field('getPushPageHomepage')
            .addFieldList(this._getPageTeaserFields());
    }

    getPushNewsHomepage() {
        return new Field('getPushNewsHomepage')
            .addFieldList(this._getPageTeaserFields());
    }

    getPushTrainingHomepage() {
        return new Field('getPushTrainingHomepage')
            .addFieldList(this._getPushTrainingHomepageFields());
    }

    _getPushTrainingHomepageFields() {
        return [
            'title',
            'description',
            'image',
            new Field('products').addFieldList(
                ProductListQuery
                    .setOptions({ isPlp: true, isSingleProduct: false })
                    ._getProductInterfaceFields(false, false, false)
            ),
            ConfigQuery.getTrainingCategoryInfoField()
        ];
    }

    getBrandHomepage() {
        return new Field('getBrandHomepage')
            .addFieldList([
                'title',
                'description',
                'image',
                'cta_text',
                'cta_color',
                'cta_link'
            ]);
    }

    getPushCollectionHomepage() {
        return new Field('getPushCollectionHomepage')
            .addFieldList([
                new Field('collection').addFieldList(CollectionQuery._getCollectionCardFields()),
                new Field('products').addFieldList(this._getPushProductHomepageFields())
            ]);
    }

    getPushCollectionsHomepage() {
        return new Field('getPushCollectionsHomepage')
            .addFieldList(CollectionQuery._getCollectionCardFields());
    }

    getInstagramPosts() {
        return new Field('instagramPosts')
            .addFieldList(this._getInstagramPostsFields());
    }

    _getInstagramPostsFields() {
        return [
            'version',
            new Field('all').addFieldList(this._getInstagramPostFields()),
            new Field('images').addFieldList(this._getInstagramPostFields()),
            new Field('videos').addFieldList(this._getInstagramPostFields())
        ];
    }

    _getInstagramPostFields() {
        return [
            'media_type',
            'caption',
            'media_url',
            'permalink',
            'thumbnail_url',
            'timestamp'
        ];
    }

    getPromoBannerListWithCart(options, cartId) {
        const { position, page } = options;

        return new Field('getPromoBannerList')
            .addArgument('position', 'String', position)
            .addArgument('page', 'String', page)
            .addArgument('cart_id', 'String', cartId)
            .addFieldList(this._getPromoBannerListFields());
    }

    getPromoBannerList(options) {
        const { position, page } = options;

        return new Field('getPromoBannerList')
            .addArgument('position', 'String', position)
            .addArgument('page', 'String', page)
            .addFieldList(this._getPromoBannerListFields());
    }

    _getPromoBannerListFields() {
        return [
            'banner_id',
            'name',
            'category',
            'type',
            'fullwidth',
            'content_position',
            'banner_image',
            'cms_block_id',
            'popup_image',
            'popup_responsive',
            'floating_image',
            'url',
            'priority',
            'floating_position',
            'page',
            'page_type',
            'auto_close_time',
            'banner_image_desktop',
            'banner_image_desktop_secondary',
            'title',
            'title_color',
            'title_color_desktop',
            'title_shadow',
            'description',
            'background_color',
            'gradient',
            'cta_label',
            'cta_color',
            'sku',
            'youtube_key',
            'additional_block_html',
            'content'
        ];
    }
}

export default new CmsPageQuery();
