/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_MATOMO_PRODUCT_ADD_TO_CART } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const SPAM_PROTECTION_DELAY = 200;
/**
 * Product add to cart event
 */
export class AddToCartEvent extends BaseEvent {
    /**
     * Bind add to cart
     */
    bindEvent() {
        Event.observer(EVENT_MATOMO_PRODUCT_ADD_TO_CART, ({
            product,
            quantity,
            configurableVariantIndex,
            massAddAction = false,
            isFromCart = false,
            isGrouped = false,
            isItem = false
        }) => {
            this.handle(
                { configurableVariantIndex, ...product },
                quantity || 1,
                isItem,
                isGrouped,
                isFromCart,
                massAddAction
            );
        });
    }

    /**
     * Handle product add to cart
     */
    handler(product, quantity, isItem, isGrouped, isFromCart, massAddAction) {
        if (!massAddAction && this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const {
            itemsData: cartItems,
            total: cartTotal
        } = this.prepareCartData();

        if (cartItems && cartItems.length) {
            cartItems.forEach((cartItem) => {
                const {
                    sku,
                    quantity,
                    prices: {
                        price: {
                            value: price
                        }
                    },
                    product: {
                        name
                    },
                } = cartItem;

                this.pushEventData([
                    'addEcommerceItem',
                    sku,
                    name,
                    false,
                    price,
                    quantity
                ]);
            });
        }

        this.pushEventData([
            'trackEcommerceCartUpdate',
            cartTotal
        ]);
    }
}

export default AddToCartEvent;
