/* eslint-disable simple-import-sort/sort */
import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    showNotification,
    hideNotification
} from 'SourceStore/Notification/Notification.action';

export {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    showNotification,
    hideNotification
};

export const HIDE_ALL_NOTIFICATIONS = 'HIDE_ALL_NOTIFICATIONS';

/**
 * Hide all notifications (drop notifications from global list).
 * @return {void}
 * @namespace PeggysagePwa/Store/Notification/Action/hideAllNotifications
 */
export const hideAllNotifications = () => ({
    type: HIDE_ALL_NOTIFICATIONS
});
