import PropTypes from 'prop-types';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace PeggysagePwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    static propTypes = {
        ...SourceMeta.propTypes,
        openGraphMetadata: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                property: PropTypes.string,
                content: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })
        ).isRequired
    };

    renderOpenGraphMeta() {
        const { openGraphMetadata } = this.props;

        return openGraphMetadata.map((tag) => {
            const {
                property = null,
                content = null
            } = tag;

            return (
                <meta
                  key={ property }
                  property={ property }
                  content={ content }
                />
            );
        });
    }

    /**
     * Override: add open graph meta
     * @returns {JSX.Element}
     */
    renderMeta() {
        return (
            <>
                { super.renderMeta() }
                { this.renderOpenGraphMeta() }
            </>
        );
    }
}

export default MetaComponent;
