/* eslint-disable max-lines */
/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Scripts from './Scripts';

/** @namespace ActiveCampaign/Component/ActiveCampaign/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    active_campaign: state.ConfigReducer.active_campaign,
    state
});

/** @namespace ActiveCampaign/Component/ActiveCampaign/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    dispatch
});

/**
 * ActiveCampaign wrapper
 * This should have 1 instance to avoid multiple initializations
 * @namespace ActiveCampaign/Component/ActiveCampaign/Container/ActiveCampaignContainer */
export class ActiveCampaignContainer extends PureComponent {
    static propTypes = {
        active_campaign: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        state: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        dispatch: PropTypes.func
    };

    static defaultProps = {
        active_campaign: {
            enabled: false,
            ac_id: ''
        },
        state: {},
        dispatch: () => {}
    };

    /**
     * Event list used in AC
     * All used events should be registered in this data mapping
     * TODO: 404 page, categoryFilter, additional events
     *
     * @type {{[p: string]: General|Purchase|CheckoutEvent|OrderData|Impression|AddToCartEvent|ProductClickEvent|ProductDetail|CheckoutOptionEvent|RemoveFromCartEvent}}
     */
    static eventList = {};

    /**
     * ActiveCampaign instance
     *
     * @type {ActiveCampaign}
     */
    static instance = null;

    /**
     * Push data to ActiveCampaign
     *
     * @param event
     * @param data
     */
    static pushData(event, data) {

    }

    /**
     * Append Data Layer with new data
     *
     * @param data
     */
    static appendData(data) {

    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|BaseEvent}
     */
    static getEvent(name) {

        return null;
    }

    /**
     * Get ActiveCampaign Instance
     *
     * @return {ActiveCampaign}
     */
    static getInstance() {
        return ActiveCampaignContainer.instance;
    }

    /**
     * Is ActiveCampaign enabled
     *
     * @type {boolean}
     */
    enabled = false;

    /**
     * Prepared Data Layer
     *
     * @type {{}}
     */
    currentDataLayer = {};

    /**
     * Event data object
     *
     * @type {{}}
     */
    events = {};

    /**
     * Data storage for event data
     *
     * @type {{}}
     */
    eventDataStorage = {};

    /**
     * Grouped product storage
     */
    groupedProducts = {};

    /**
     * Did mount
     */
    componentDidMount() {
        this.initialize();
    }

    /**
     * If props is updated
     */
    componentDidUpdate() {
        this.initialize();
    }

    /**
     * Unregister component
     */
    componentWillUnmount() {
        this.destruct();
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|*}
     */
    getEvent(name) {
        return null;
    }

    /**
     * Set event storage
     *
     * @param event
     * @param data
     */
    setEventStorage(event, data) {
        this.eventDataStorage[event] = data;
    }



    /**
     * Get reference to the storage
     *
     * @param event
     * @return {*}
     */
    getEventDataStorage(event) {
        if (typeof this.eventDataStorage[event] === 'undefined') {
            this.resetEventDataStorage(event);
        }

        return this.eventDataStorage[event];
    }

    /**
     * Reset storage data
     *
     * @param event
     */
    resetEventDataStorage(event) {
        this.eventDataStorage[event] = {};
    }

    /**
     * Register ActiveCampaign event handlers
     */
    registerEvents() {
        this.events = Object.entries(ActiveCampaignContainer.eventList).reduce((acc, [name, Event]) => {
            acc[name] = new Event(name, this);
            acc[name].bindEvent();

            return acc;
        }, {});
    }

    /**
     * Unregister/ destruct all parts related to the ActiveCampaign
     */
    destruct() {
        Object.values(this.events).forEach((event, name) => {
            event.destruct();
            // eslint-disable-next-line fp/no-delete
            delete this.events[name];
        });

        this.events = {};
    }


    /**
     * Initialize ActiveCampaign
     */
    initialize() {
        const { active_campaign: { enabled, ac_id: id } } = this.props;

        if (this.enabled || !enabled || ActiveCampaignContainer.getInstance() || !id) {
            return;
        }

        this.enabled = true;
        ActiveCampaignContainer.instance = this;

        this.injectACScripts();

    }

    /**
     * Insert ActiveCampaign scripts to the document
     */
    injectACScripts() {
        const { active_campaign: { ac_id: id } } = this.props;

        const script = document.createElement('script');
        script.innerHTML = Scripts.getScript(
            { id }
        );

        document.head.insertBefore(script, document.head.childNodes[0]);
    }


    /**
     * Skip render
     *
     * @return {null}
     */
    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveCampaignContainer));
