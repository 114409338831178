/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { PRODUCT_BUNDLE_OPTIONS_POPUP_ID } from 'Component/Product/Product.config.js';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductBundleOptionContainer as SourceProductBundleOptionContainer
} from 'SourceComponent/ProductBundleOption/ProductBundleOption.container';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { ProductType } from 'Type/ProductList.type';
import { getBundleId } from 'Util/Product/Product';
import { getQuantityFromSelectedOptions } from 'Util/Product/Transform';

/** @namespace PeggysagePwa/Component/ProductBundleOption/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    payload: state.PopupReducer.popupPayload[PRODUCT_BUNDLE_OPTIONS_POPUP_ID] || {}
});

/** @namespace PeggysagePwa/Component/ProductBundleOption/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hideActivePopup: () => dispatch(hideActivePopup())
});

/** @namespace PeggysagePwa/Component/ProductBundleOption/Container */
export class ProductBundleOptionContainer extends SourceProductBundleOptionContainer {
    static propTypes = {
        ...SourceProductBundleOptionContainer.propTypes,
        optionId: PropTypes.number.isRequired,
        product: ProductType.isRequired,
        selectedOptions: PropTypes.arrayOf(PropTypes.string),
        areFiltersVisible: PropTypes.bool,
        payload: PropTypes.shape({
            currentOptionId: PropTypes.number,
            filteredValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
            selectedOptionUid: PropTypes.string
        }),
        hideActivePopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedOptions: [],
        areFiltersVisible: false,
        payload: {
            currentOptionId: 0,
            filteredValues: {},
            selectedOptionUid: null
        }
    };

    state = {
        ...this.state,
        filters: [],
        filteredValues: {},
        filteredValuesFlat: []
    };

    unavailableSectionRef = createRef();

    containerFunctions = {
        ...this.containerFunctions,
        scrollToUnavailableProducts: this.scrollToUnavailableProducts.bind(this),
        onInputChange: this.onInputChange.bind(this),
        hasDifferentPrices: this.hasDifferentPrices.bind(this),
        onClickFilter: this.onClickFilter.bind(this),
        onClickFilterToggle: this.onClickFilterToggle.bind(this)
    };

    /**
     * Override: handle values from Product Popup payload (for when we are back from ProductPopup),
     * and init quantity state (bug of lost quantity when the component is opened in popup)
     */
    componentDidMount() {
        const { payload: { filteredValues, selectedOptionUid }, updateSelectedValues, areFiltersVisible } = this.props;

        this.setState({
            filteredValues: filteredValues ?? {}
        });

        if (selectedOptionUid) {
            updateSelectedValues({
                uid: selectedOptionUid,
                forceSelectedOption: true
            });
        }

        if (areFiltersVisible) {
            this.getFilters();
        }

        this.initQuantityFromSelectedOptions();

        super.componentDidMount();
    }

    /**
     * Override
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const { filteredValues } = this.state;
        const { filteredValues: prevFilteredValues } = prevState;

        if (filteredValues !== prevFilteredValues) {
            this.setState({
                filteredValuesFlat: filteredValues ? Object.values(filteredValues).flat() : []
            });
        }

        super.componentDidUpdate(prevProps, prevState);
    }

    /**
     * When component is opened in popup, the quantity (state) is emptied,
     * we need to get the quantities from the selected options uids
     */
    initQuantityFromSelectedOptions() {
        const { options, selectedOptions } = this.props;

        this.setState({ quantity: getQuantityFromSelectedOptions(selectedOptions, options) });
    }

    containerProps() {
        const {
            optionId,
            product,
            selectedOptions,
            payload: { currentOptionId = 0 }
        } = this.props;
        const {
            filters,
            filteredValues,
            filteredValuesFlat
        } = this.state;

        return {
            ...super.containerProps(),
            unavailableSectionRef: this.unavailableSectionRef,
            optionId,
            product,
            selectedOptions,
            currentOptionId,
            filters,
            filteredValues,
            filteredValuesFlat
        };
    }

    scrollToUnavailableProducts(e) {
        const elem = this.unavailableSectionRef && this.unavailableSectionRef.current;

        e.preventDefault();

        if (!elem) {
            return;
        }

        const elemToWindowTopDist = elem.getBoundingClientRect().top;
        // const windowToPageTopDist = document.body.getBoundingClientRect().top;
        const windowToPageTopDist = elem.closest('.Popup-Content').getBoundingClientRect().top;
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;

        // window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        elem.closest('.Popup-Content').scrollTo({ top: topToElemDistance, behavior: 'smooth' });
    }

    /**
     * Close popup when an option is selected
     * @param e
     * @param data
     */
    onInputChange(e, data) {
        const { updateSelectedValues, hideActivePopup, options } = this.props;
        const { value: uid = null } = data;

        const option = options.find((option) => getBundleId(option.uid) === getBundleId(uid));

        // Close popup if we can't change the quantity of the selected option
        if (!option || !option.can_change_quantity) {
            setTimeout(() => {
                hideActivePopup();
                // eslint-disable-next-line
            }, 300);
        }

        // default onChange function
        updateSelectedValues(data);
    }

    hasDifferentPrices(options) {
        if (options) {
            // Show price if only one option
            if (options.length === 1) {
                return true;
            }

            // Store different prices in an array
            const prices = options.reduce((accumulator, option) => {
                const { regularOptionPrice } = option;
                if (regularOptionPrice) {
                    const fixedPrice = regularOptionPrice.toFixed(2);
                    if (!accumulator.includes(fixedPrice)) {
                        accumulator.push(fixedPrice);
                    }
                }

                return accumulator;
            }, []);

            return prices.length > 1;
        }

        return false;
    }

    getFilters() {
        const { options, payload: { filteredValues = {} } } = this.props;

        if (!Object.keys(options).length) {
            return;
        }

        const filteredValuesKeys = filteredValues ? Object.keys(filteredValues) : [];

        const filters = [
            {
                key: 'color',
                code: 'colors',
                label: __('Par couleur').toString(),
                options: [],
                isExpanded: filteredValuesKeys.includes('color')
            },
            {
                key: 'texture',
                code: 'texture_attribute_value',
                label: __('Par texture').toString(),
                options: [],
                isExpanded: filteredValuesKeys.includes('texture')
            }
        ];

        // Get all filters from variations
        options.forEach((option) => {
            const { product } = option;

            filters.forEach((filter, indexFilter) => {
                const { code, options } = filter;

                if (product && product[code]) {
                    const productOptions = !Array.isArray(product[code]) ? [product[code]] : product[code];

                    if (productOptions.length > 0) {
                        productOptions.forEach((option) => {
                            const indexFilterOption = options.findIndex((filterOption) => filterOption.value === option.value);
                            if (indexFilterOption !== -1) {
                                // Add 1 to count
                                filters[indexFilter].options[indexFilterOption].count++;
                            } else {
                                // Create filter
                                filters[indexFilter].options.push({
                                    label: option.label,
                                    value: option.value,
                                    count: 1
                                });
                            }
                        });
                    }
                }
            });
        });

        const finalFilters = filters
            // Remove filters with less than 2 options
            .filter((filter) => filter.options.length > 1)
            // Order filters options by count
            .map((filter) => {
                const { options } = filter;

                options.sort((a, b) => b.count - a.count);

                return {
                    ...filter,
                    options
                };
            });

        this.setState({ filters: finalFilters });

        /* const stackedFilters = [];

        // eslint-disable-next-line no-magic-numbers
        const indexOtherFilter = 4;

        // Keep first filters and stack the others
        filters.forEach((filter, i) => {
            if (i < indexOtherFilter) {
                stackedFilters.push(filter);
            } else {
                if (!stackedFilters[indexOtherFilter]) {
                    // Create "Others" filter
                    stackedFilters.push({
                        label: __('Autre'),
                        value: '',
                        count: 0,
                        active: false
                    });
                }
                stackedFilters[indexOtherFilter].count += filter.count;

                if (stackedFilters[indexOtherFilter].value === '') {
                    stackedFilters[indexOtherFilter].value = filter.value;
                } else {
                    // eslint-disable-next-line prefer-template
                    stackedFilters[indexOtherFilter].value += ',' + filter.value;
                }

                stackedFilters[indexOtherFilter].active = filteredValues.includes(filter.value);
            }
        });

        return stackedFilters; */
    }

    onClickFilter(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const filterValue = e ? e.target.dataset.value : '';

        // "All" button
        if (filterValue === '') {
            this.setState({ filteredValues: {} });
            return;
        }

        const { filteredValues } = this.state;

        const [filterKey, filterOptionValue] = filterValue.split('-');

        const newFilteredValues = { ...filteredValues };

        newFilteredValues[filterKey] = []; // for multiple choices, modify here
        newFilteredValues[filterKey].push(parseInt(filterOptionValue, 10));

        this.setState({ filteredValues: newFilteredValues });
    }

    onClickFilterToggle(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { filters } = this.state;

        const filterKey = e ? e.target.dataset.key : '';

        const indexFilter = filters.findIndex((filter) => filter.key === filterKey);
        if (indexFilter !== -1) {
            filters[indexFilter].isExpanded = !filters[indexFilter].isExpanded;

            this.setState({ filters: Array.from(filters) });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBundleOptionContainer);
