/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';

import './Overlay.override.style';

/** @namespace PeggysagePwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    /**
     * Override: fix scrollTo after closing popup for Firefox
     */
    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        // document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    /**
     * Override: fix scrollTo after closing popup for Firefox
     */
    unfreezeScroll() {
        toggleScroll(true);
        // document.body.style.marginTop = 0;
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }
}

export default OverlayComponent;
