/* eslint-disable max-len */
import PropTypes from 'prop-types';

import {
    HeartIcon as SourceHeartIcon
} from 'SourceComponent/HeartIcon/HeartIcon.component';

import './HeartIcon.override.style';

/** @namespace PeggysagePwa/Component/HeartIcon/Component */
export class HeartIconComponent extends SourceHeartIcon {
    static propTypes = {
        ...SourceHeartIcon.propTypes,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        ...SourceHeartIcon.defaultProps,
        isLoading: false
    };

    render() {
        const { isActive, isLoading } = this.props;

        return (
            <svg
              block="HeartIcon"
              mods={ { isActive, isLoading } }
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <mask id="svgmask">
                        <path id="mask" fill="#ffffff" d="M12.9,23.3c0.2,0.2,0.4,0.2,0.6,0c0,0,0,0,0,0l10.3-10.6c2.7-2.8,2.7-7.1,0-9.9c-1.3-1.3-3-2-4.8-2l0,0c-1.8,0-3.6,0.7-4.8,2.1l-0.9,0.9l-0.9-1C9.7,0.2,5.4,0.1,2.8,2.7C2.7,2.8,2.7,2.8,2.6,2.9C0,5.6,0,10,2.6,12.8L12.9,23.3z" />
                    </mask>
                </defs>
                <path strokeWidth="2" d="M12.9,23.3c0.2,0.2,0.4,0.2,0.6,0c0,0,0,0,0,0l10.3-10.6c2.7-2.8,2.7-7.1,0-9.9c-1.3-1.3-3-2-4.8-2l0,0c-1.8,0-3.6,0.7-4.8,2.1l-0.9,0.9l-0.9-1C9.7,0.2,5.4,0.1,2.8,2.7C2.7,2.8,2.7,2.8,2.6,2.9C0,5.6,0,10,2.6,12.8L12.9,23.3z" />
                <g mask="url(#svgmask)" maskUnits="objectBoundingBox">
                    <polygon block="HeartIcon" elem="Fill" points="1 1, 26 1, 26 24, 1 24" />
                </g>
            </svg>
        );
    }
}

export default HeartIconComponent;
