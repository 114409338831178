import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { scrollToTop } from 'Util/Browser';

import { NewWishlistComponent } from './NewWishlist.component';
import { WISHLIST_PAGE_TITLE, WISHLIST_PAGE_URL } from './NewWishlist.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace PeggysagePwa/Route/NewWishlist/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace PeggysagePwa/Route/NewWishlist/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateWishlistData: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWishlistData(dispatch)
    ),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    toggleBreadcrumbs: (isVisible) => dispatch(toggleBreadcrumbs(isVisible)),
    updateMeta: (meta) => dispatch(updateMeta(meta))
});

/** @namespace PeggysagePwa/Route/NewWishlist/Container */
export class NewWishlistContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        updateWishlistData: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    containerFunctions = {
        // ...
    };

    componentDidMount() {
        scrollToTop();
        this.updateWishlist();
        this.updateMeta();
        this.updateBreadcrumbs();
    }

    componentDidUpdate(prevProps) {
        const { isMobile } = this.props;
        const { isMobile: prevIsMobile } = prevProps;

        if (isMobile !== prevIsMobile) {
            this.updateBreadcrumbs();
        }
    }

    containerProps() {
        // ...
    }

    updateMeta() {
        const { updateMeta } = this.props;
        updateMeta({ title: WISHLIST_PAGE_TITLE });
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, toggleBreadcrumbs, isMobile } = this.props;

        if (!isMobile) {
            const breadcrumbs = [
                {
                    url: WISHLIST_PAGE_URL,
                    name: WISHLIST_PAGE_TITLE
                }
            ];

            updateBreadcrumbs(breadcrumbs);
        } else {
            toggleBreadcrumbs(false);
        }
    }

    /**
     * Reload wishlist to get related products
     */
    updateWishlist() {
        const { updateWishlistData } = this.props;

        updateWishlistData();
    }

    render() {
        return (
            <NewWishlistComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewWishlistContainer);
