import CmsPageQuery from 'Query/CmsPage.query';
import ConfigQuery from 'Query/Config.query';
import OrderQuery from 'Query/Order.query';
import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    /**
     * Override: add remember_me param
     * Get SignIn mutation
     * @param {{email: String, password: String, remember_me: Boolean}} options
     * @return {Field}
     * @memberof MyAccount
     */
    getSignInMutation(options) {
        const { email, password, remember_me = false } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('remember_me', 'Boolean', remember_me)
            .addField('token');
    }

    /**
     * Override
     * @param options
     * @returns {Field}
     */
    getCreateAccountMutation(options) {
        const { input, addressInput, customerType } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', input)
            .addArgument('addressInput', 'CustomerAddressInput', addressInput)
            .addArgument('customerType', 'String', customerType)
            .addField(this._getCustomerField());
    }

    /**
     * Override
     * @param options
     * @returns {Field}
     */
    getUpdateInformationMutation(options) {
        const { input, addressInput, customerType } = options;

        return new Field('updateCustomerV2')
            .addArgument('input', 'CustomerUpdateInput!', input)
            .addArgument('addressInput', 'CustomerAddressInput', addressInput)
            .addArgument('customerType', 'String', customerType)
            .addField(this._getCustomerField());
    }

    /**
     * Override
     * @returns {(string|*)[]}
     * @private
     */
    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            'favorite_shop_id',
            'is_student',
            'is_pro',
            'status_pro',
            'is_subscribed',
            'rgpd_offre_peggysage',
            'rgpd_offre_peggysage_sms',
            'rgpd_offre_partenaire',
            'rgpd_offre_partenaire_sms',
            'societe',
            'siret',
            'siret_comment',
            'carte_identite',
            'attestation_avs',
            'kbis',
            'votre_preuve',
            'bulletin_paie',
            new Field('allowed_stores').addFieldList(ConfigQuery._getStoreListFields()),
            'can_change_billing_address',
            'cant_upgrade_to_pro',
            'cant_upgrade_to_student'
        ];
    }

    /**
     * Override: add company field
     * @returns {*[]}
     * @private
     */
    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company'
        ];
    }

    getCompanySuggestions(options) {
        const { postcode, name } = options;

        return new Field('searchEnterprise')
            .addArgument('postcode', 'String!', postcode)
            .addArgument('name', 'String!', name)
            .addFieldList(this._getCompanySuggestionsFields());
    }

    getEnterpriseDetails(siren) {
        return new Field('getEnterpriseDetails')
            .addArgument('siren', 'Int!', siren)
            .addFieldList(this._getEnterpriseDetailsFields());
    }

    getIsSiretAlreadyExists(siret) {
        return new Field('getIsSiretAlreadyExists')
            .addArgument('siret', 'String!', siret);
    }

    _getCompanySuggestionsFields() {
        return [
            'name',
            'siren',
            'ape',
            'apetexte',
            'postcode',
            'city'
        ];
    }

    _getEnterpriseDetailsFields() {
        return [
            'name',
            'siren',
            'siret',
            'ape',
            'apetexte',
            'street',
            'postcode',
            'city'
        ];
    }

    getRegisterAgreements() {
        return new Field('getRegisterAgreements')
            .addFieldList(this._getRegisterAgreementsFields());
    }

    _getRegisterAgreementsFields() {
        return [
            'link',
            'display_mode',
            'checkbox_text'
        ];
    }

    getPushPageAccount() {
        return new Field('getPushPageAccount')
            .addFieldList(CmsPageQuery._getPageTeaserFields());
    }

    getLastCurrentOrder() {
        return new Field('getLastCurrentOrder')
            .addFieldList(OrderQuery._getOrderItemsFields(true));
    }

    validateProFields(fields) {
        return new Field('validateProFields')
            .addArgument('fields', '[ProFieldsValidationInput]', fields)
            .addFieldList(this._validateProFieldsFields());
    }

    _validateProFieldsFields() {
        return [
            'errors'
        ];
    }

    getCityListForZipCode(zip_code) {
        return new Field('getCityListForZipCode')
            .addArgument('zip_code', 'String', zip_code)
            .addFieldList(this._getCityListForZipCodeFields());
    }

    _getCityListForZipCodeFields() {
        return [
            'zip_code',
            'place_name'
        ];
    }

    generateCustomerTokenByAdmin(secret) {
        return new Field('generateCustomerTokenByAdmin')
            .addArgument('secret', 'String!', secret)
            .addField('token');
    }
}

export default new MyAccountQuery();
