/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import { GROUPED_PRODUCTS_GUEST } from '../component/GoogleMerchant/GoogleMerchant.config';
import GoogleMerchantContainer from '../component/GoogleMerchant/GoogleMerchant.container';
import ProductHelper from '../component/GoogleMerchant/utils/Product';
import GoogleMerchantQuery from '../query/GoogleMerchant.query';

export const handle_syncCartWithBEError = (args, callback) => callback(...args)
    .then(
        (result) => {
            GoogleMerchantContainer.getInstance().setGroupedProducts({});
            return result;
        }
    );

export const addGoogleMerchantConfigQuery = (args, callback) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        GoogleMerchantQuery.getGoogleMerchantConfiguration()
    ];
};

export const addGoogleMerchantToConfigReducerInitialState = (args, callback) => {
    const { googleMerchant } = BrowserDatabase.getItem('config') || { googleMerchant: {} };

    return {
        ...callback(...args),
        googleMerchant
    };
};

export const addGoogleMerchantToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { googleMerchant } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        googleMerchant
    };
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addGoogleMerchantConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addGoogleMerchantToConfigReducerInitialState
    },
    'PeggysagePwa/Store/Config/Reducer/ConfigReducer': {
        function: addGoogleMerchantToConfigUpdate
    }
};
