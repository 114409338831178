import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import Html from 'Component/Html';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import { addInsecableSpaceBeforePunctuation } from 'Util/Common';

import './ExpandableContent.override.style';

/** @namespace PeggysagePwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        ...SourceExpandableContent.propTypes,
        toggleExpand: PropTypes.func.isRequired,
        groupId: PropTypes.string.isRequired,
        expandableContentRef: PropTypes.oneOfType([
            // Either a function
            PropTypes.func,
            // Or the instance of a DOM native element (see the note about SSR)
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired
    };

    renderSubHeading() {
        const { subHeading, mix } = this.props;

        if (!subHeading) {
            return null;
        }

        return (
            <div
              block="ExpandableContent"
              elem="SubHeading"
              mix={ { ...mix, elem: 'ExpandableContentSubHeading' } }
            >
                { subHeading }
            </div>
        );
    }

    /**
     * Override: add subheading
     * @returns {JSX.Element}
     */
    renderButton() {
        const {
            heading,
            mix,
            toggleExpand,
            isContentExpanded
        } = this.props;

        // Replace spaces before punctuation signs by non-breaking html space
        const formattedHeading = heading ? addInsecableSpaceBeforePunctuation(heading.toString()) : '';

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ toggleExpand }
              onKeyDown={ toggleExpand }
            >
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                    { typeof heading === 'string' && !heading ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        <Html content={ formattedHeading } />
                    ) }
                </div>
                { this.renderSubHeading() }
                { this.renderButtonIcon() }
            </div>
        );
    }

    /**
     * Override: Render Icon on desktop too
     * @returns {JSX.Element|*}
     */
    renderButtonIcon() {
        const {
            isArrow,
            device: { isMobile },
            isContentExpanded
        } = this.props;

        if (!isMobile) {
            // return null;
        }

        if (isArrow) {
            return (
                <span block="ExpandableContent" elem="ButtonIcon">
                    <span block="ExpandableContent" elem="ButtonIconLabel">
                        { __('View') }
                    </span>
                    <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />
                </span>
            );
        }

        return this.renderTogglePlusMinus();
    }

    /**
     * Override: replace state by prop
     * @returns {JSX.Element}
     */
    renderTogglePlusMinus() {
        const { isContentExpanded } = this.props;

        return (
            <>
                <span block="ExpandableContent" elem="ToggleIcon" mods={ { isVisible: isContentExpanded } }>
                    <MinusIcon />
                </span>
                <span block="ExpandableContent" elem="ToggleIcon" mods={ { isVisible: !isContentExpanded } }>
                    <AddIcon />
                </span>
            </>
        );
    }

    /**
     * Override: replace state by prop
     * @returns {JSX.Element}
     */
    renderContent() {
        const { children, mix, isContentExpanded } = this.props;
        const mods = { isContentExpanded };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }

    /**
     * Override: Add data attribute group if provided
     * @returns {JSX.Element}
     */
    render() {
        const {
            mix,
            mods,
            groupId,
            expandableContentRef
        } = this.props;

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ expandableContentRef }
              data-group={ groupId || null }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;
