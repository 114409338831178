/* eslint-disable simple-import-sort/sort */
import getStore from 'Util/Store';

import {
    DISPLAY_CART_TAX_IN_SUBTOTAL,
    DISPLAY_CART_TAX_IN_SHIPPING,
    DISPLAY_CART_TAX_IN_PRICE,
    getCartSubtotal,
    getCartSubtotalSubPrice,
    getCartItemPrice,
    getCartItemSubPrice,
    // getCartShippingPrice,
    getCartShippingSubPrice,
    getCartShippingItemPrice,
    getCartShippingItemSubPrice,
    getCartTotalSubPrice,
    getItemsCountLabel,
    getAllCartItemsSku,
    trimCrossSellDuplicateItems
} from 'SourceUtil/Cart/Cart';

export {
    DISPLAY_CART_TAX_IN_SUBTOTAL,
    DISPLAY_CART_TAX_IN_SHIPPING,
    DISPLAY_CART_TAX_IN_PRICE,
    getCartSubtotal,
    getCartSubtotalSubPrice,
    getCartItemPrice,
    getCartItemSubPrice,
    // getCartShippingPrice,
    getCartShippingSubPrice,
    getCartShippingItemPrice,
    getCartShippingItemSubPrice,
    getCartTotalSubPrice,
    getItemsCountLabel,
    getAllCartItemsSku,
    trimCrossSellDuplicateItems
};

export const DISPLAY_CART_TAX_IN_TOTAL = {
    INCL_TAX: 'INCL_TAX',
    EXCL_TAX: 'EXCL_TAX'
};

/** @namespace PeggysagePwa/Util/Cart/getCheapestShippingMethod */
export const getCheapestShippingMethod = () => {
    const store = getStore();
    const {
        ConfigReducer: {
            cheapestShippingMethod
        } = {}
    } = store.getState();

    return cheapestShippingMethod;
};

/** @namespace PeggysagePwa/Util/Cart/isCheapestShippingMethodDefined */
export const isCheapestShippingMethodDefined = () => !!getCheapestShippingMethod();

/**
 * Override: get shipping price from prices when address is empty
 * @param state
 * @returns {number}
 * @namespace PeggysagePwa/Util/Cart/getCartShippingPrice
 */
export const getCartShippingPrice = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_shipping_amount = ''
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                shipping_addresses: {
                    selected_shipping_method = {}
                } = [],
                prices: {
                    estimated_shipping_amount: {
                        value: estimated_shipping_amount = 0
                    } = {}
                } = {}
            } = {}
        } = {}
    } = state;

    if (!selected_shipping_method || selected_shipping_method.amount === undefined) {
        return estimated_shipping_amount;
    }

    const {
        amount: {
            value: shipping_amount = 0
        } = {},
        amount_incl_tax: shipping_incl_tax = 0
    } = selected_shipping_method || {};

    if (display_tax_in_shipping_amount === DISPLAY_CART_TAX_IN_SHIPPING.EXCL_TAX) {
        return shipping_amount;
    }

    return shipping_incl_tax;
};
