// import PropTypes from 'prop-types';
import {
    CategorySort as SourceCategorySort
} from 'SourceComponent/CategorySort/CategorySort.component';

import './CategorySort.override.style';

/** @namespace PeggysagePwa/Component/CategorySort/Component */
export class CategorySortComponent extends SourceCategorySort {
    // ...
}

export default CategorySortComponent;
