/* eslint-disable simple-import-sort/sort */
import { PRODUCT_TYPE } from 'Component/Product/Product.config';

import {
    DEFAULT_MIN_PRODUCTS,
    DEFAULT_MAX_PRODUCTS,
    MIN_SALE_QTY,
    MAX_SALE_QTY,
    SALABLE_QTY,
    getFieldQty,
    getQuantity,
    getMinQuantity,
    getMaxQuantity,
    getName,
    getProductInStock,
    getGroupedProductsInStockQuantity,
    getBundleOption,
    getPrice,
    // getAdjustedPrice,
    getSubLabelFromMaxCharacters,
    getImage,
    getThumbnailImage,
    getSmallImage,
    getBaseImage
} from 'SourceUtil/Product/Extract';

export {
    DEFAULT_MIN_PRODUCTS,
    DEFAULT_MAX_PRODUCTS,
    MIN_SALE_QTY,
    MAX_SALE_QTY,
    SALABLE_QTY,
    getFieldQty,
    getQuantity,
    getMinQuantity,
    getMaxQuantity,
    getName,
    getProductInStock,
    getGroupedProductsInStockQuantity,
    getBundleOption,
    getPrice,
    // getAdjustedPrice,
    getSubLabelFromMaxCharacters,
    getImage,
    getThumbnailImage,
    getSmallImage,
    getBaseImage
};

/**
 * Override: add verification
 *
 * Generates adjusted price from entered product options
 *
 * @param product
 * @param downloadableLinks
 * @param enteredOptions
 * @param selectedOptions
 * @namespace PeggysagePwa/Util/Product/Extract/getAdjustedPrice
 */
export const getAdjustedPrice = (product, downloadableLinks, enteredOptions, selectedOptions) => {
    const {
        downloadable_product_links = [],
        options = [],
        items = [],
        dynamic_price: dynamicPrice = false,
        type_id: typeId
    } = product;

    const adjustedPrice = {
        downloadable: {
            exclTax: 0,
            inclTax: 0,
            requiresDiscountCalculations: true,
            hasDiscountCalculated: false
        },
        bundle: {
            exclTax: 0,
            inclTax: 0,
            requiresDiscountCalculations: true,
            hasDiscountCalculated: true
        },
        config: {
            exclTax: 0,
            inclTax: 0,
            requiresDiscountCalculations: false
        }
    };

    // #region DOWNLOADABLE
    if (typeId === PRODUCT_TYPE.downloadable) {
        downloadableLinks.forEach((uid) => {
            const link = downloadable_product_links.find(({ uid: linkUid }) => linkUid === uid);

            if (link) {
                const { price } = link;

                adjustedPrice.downloadable.exclTax += price;
                adjustedPrice.downloadable.inclTax += price;
            }
        });
    }
    // #endregion

    // #region BUNDLE
    if (typeId === PRODUCT_TYPE.bundle) {
        selectedOptions.forEach((uid) => {
            items.forEach(({ options = [] }) => {
                const uidParts = atob(uid).split('/');
                const option = Array.isArray(options) && getBundleOption(uid, options);
                const quantity = +uidParts[uidParts.length - 1];

                if (option) {
                    const {
                        finalOptionPrice,
                        finalOptionPriceExclTax,
                        product: {
                            price_range: optionProductPriceRange = {},
                            type_id: optionProductType,
                            dynamic_price: optionProductDynamic
                        },
                        can_change_quantity: canChangeQuantity = false
                    } = option;

                    if (!dynamicPrice) {
                        const multiplier = canChangeQuantity ? quantity : quantity;

                        adjustedPrice.bundle.exclTax += finalOptionPriceExclTax * multiplier;
                        adjustedPrice.bundle.inclTax += finalOptionPrice * multiplier;
                    } else {
                        const {
                            price: {
                                finalPrice: { value: priceInclTax = 0 } = {},
                                finalPriceExclTax: { value: priceExclTax = 0 } = {}
                            }
                        } = getPrice(
                            optionProductPriceRange, optionProductDynamic, {}, optionProductType
                        ) || {};

                        adjustedPrice.bundle.inclTax += priceInclTax * quantity;
                        adjustedPrice.bundle.exclTax += priceExclTax * quantity;
                    }
                }
            });
        });
    }
    // #endregion

    // #region CONFIGURABLE
    if (enteredOptions && options) {
        enteredOptions.forEach(({ uid }) => {
            const option = options.find(({ uid: linkUid }) => linkUid === uid);

            if (option) {
                const { value: { priceExclTax = 0, priceInclTax = 0 } = {} } = option;

                adjustedPrice.config.inclTax += priceInclTax;
                adjustedPrice.config.exclTax += priceExclTax;
            }
        });
    }

    if (selectedOptions && options) {
        selectedOptions.forEach((uid) => {
            options.forEach(({ value = [] }) => {
                const option = Array.isArray(value) && value.find(({ uid: linkedUid }) => linkedUid === uid);

                if (option) {
                    const { priceExclTax = 0, priceInclTax = 0 } = option;

                    adjustedPrice.config.inclTax += priceInclTax;
                    adjustedPrice.config.exclTax += priceExclTax;
                }
            });
        });
    }
    // #endregion

    return adjustedPrice;
};
