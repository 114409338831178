/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import moment from 'moment';
import { roundPrice } from 'Util/Price';

import Event, { EVENT_GOOGLEMERCHANT_PURCHASE } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 0;

/**
 * On order success page "Purchase"
 */
export class Purchase extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GOOGLEMERCHANT_PURCHASE, ({ orderID: orderId, totals, customer_email, shipping_address, timeline}) => {
            this.handle(
                orderId,
                totals,
                customer_email,
                shipping_address,
                timeline
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, totals, customer_email, shipping_address, timeline ) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const countryId = shipping_address.country_id;
        let data = {
            // REQUIRED FIELDS
            "merchant_id": this.getGoogleMerchant().getMerchantId(),
            "order_id": orderId,
            "email": customer_email,
            "delivery_country": countryId,
            "estimated_delivery_date": this.getEstimatedDrop(timeline),
            "products": this.getProducts(totals)
        };

        if (typeof window.gapi !== 'undefined') {
            try {
                window.gapi.load('surveyoptin', function () {
                    window.gapi.surveyoptin.render(data);
                });
            } catch (e) {

            }
        }
    }

    /**
     * Get order information
     *
     * @return {{revenue: number, coupon_discount_abs: string, coupon: string, shipping: number, affiliation: string, coupon_discount_amount: string, tax: number, id: *}}
     */
    getActionFields(orderId = '', {
        tax_amount, grand_total, shipping_amount, discount_amount, coupon_code = ''
    }) {
        return {
            id: orderId,
            tax: +roundPrice(tax_amount),
            coupon: coupon_code,
            revenue: +roundPrice(grand_total),
            shipping: +roundPrice(shipping_amount),
            coupon_discount_amount: +roundPrice(discount_amount)
        };
    }

    /**
     * Get product detail
     *
     * @param totals
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, category: string, brand: string, url: string}[]}
     * @param cartData
     */
    getProducts({ items }) {
        const products = items.reduce((acc, item) => (
            [
                ...acc,
                {
                    gtin: item.product ? item.product.code_barre : null
                }
            ]
        ), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => products.push(data));
        return products;
    }

    getEstimatedDrop( timeline) {
        const date = timeline[timeline.length - 1];
        const fulldate = date.date;
        const convertedDate = moment(fulldate).format("YYYY-MM-DD");

        return convertedDate;
    }
}

export default Purchase;
