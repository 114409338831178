// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductTag from 'Component/ProductTag';
import { MixType } from 'Type/Common.type';
import { ProductTagsType } from 'Type/ProductList.type';

import './ProductTags.style';

/** @namespace PeggysagePwa/Component/ProductTags/Component */
export class ProductTagsComponent extends PureComponent {
    static propTypes = {
        tags: ProductTagsType.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    render() {
        const { tags, mix } = this.props;

        if (!tags || !tags.length) {
            return null;
        }

        return (
            <div block="ProductTags" mix={ mix }>
                { tags.map((tag, index) => (
                    <ProductTag
                      tag={ tag }
                      // eslint-disable-next-line react/no-array-index-key
                      key={ `tag-${index}` }
                    />
                )) }
            </div>
        );
    }
}

export default ProductTagsComponent;
