import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import { ORDER_ID } from 'Component/MyAccountOrder/MyAccountOrder.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    CartDispatcher,
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS,
    ProductCompareDispatcher,
    WishlistDispatcher
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { resetCurrentCategory } from 'Store/Category/Category.action';
import {
    updateCustomerDetails,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLocked
} from 'Store/MyAccount/MyAccount.action';
import { hideAllNotifications, showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
// import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { resetProductListItems } from 'Store/ProductList/ProductList.action';
import {
    deleteAuthorizationToken,
    getAuthorizationToken,
    GRAPHQL_AUTH, GRAPHQL_AUTHORIZATION,
    isSignedIn,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId, getCartId, setCartId } from 'Util/Cart';
// import { removeUid } from 'Util/Compare';
import { saveCustomerFavoriteShopFromCookie } from 'Util/FavoriteShop';
import { handleRedirectToAllowedStore } from 'Util/MyAccount';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';

export {
    CartDispatcher,
    CUSTOMER,
    ONE_MONTH_IN_SECONDS,
    ProductCompareDispatcher,
    WishlistDispatcher
};

export const ConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
);

/** @namespace PeggysagePwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    /**
     * Override: do not show authorization error
     * @param dispatch
     * @returns {Promise<Request>}
     */
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        return executePost(prepareQuery([query])).then(
            /** @namespace PeggysagePwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace PeggysagePwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
            (error) => {
                const { extensions: { category } } = error[0];

                if (category === GRAPHQL_AUTH) {
                    dispatch(updateIsLocked(true));
                }

                if (category !== GRAPHQL_AUTHORIZATION) {
                    dispatch(showNotification('error', getErrorMessage(error)));
                }
            }
        );
    }

    /**
     * Override: change options layout
     * @param {{input: Object, addressInput: Object, customerType: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const { input: { email, password } } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);

        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace PeggysagePwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                sessionStorage.setItem(ORDER_ID, '');

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace PeggysagePwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));

                return false;
            }
        );
    }

    async getCustomerToken(options, dispatch) {
        const { secret } = options;

        if (secret) {
            // Login as customer

            const mutation = MyAccountQuery.generateCustomerTokenByAdmin(secret);

            try {
                const { generateCustomerTokenByAdmin: { token = '' } = {} } = await fetchMutation(mutation);

                return token;
            } catch (error) {
                dispatch(showNotification('error', getErrorMessage(error)));

                return Promise.reject(error);
            }
        }

        // Standard login

        const formattedOptions = {
            ...options,
            // Transform checkbox value into Boolean
            remember_me: options.remember_me !== false
        };

        const mutation = MyAccountQuery.getSignInMutation(formattedOptions);

        try {
            const result = await fetchMutation(mutation);
            const { generateCustomerToken: { token } } = result;

            return token;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Override:
     * - update favorite shop from cookie when signing in
     * - hide all previous notifications
     * - reload config
     * - redirect to allowed store if needed
     * - get token from secret key instead of login/password for "login as customer" feature
     * @param options
     * @param dispatch
     * @returns {Promise<boolean>}
     */
    async signIn(options = {}, dispatch) {
        const { token: tokenLoginAsCustomer } = options;

        const token = tokenLoginAsCustomer || await this.getCustomerToken(options, dispatch);

        setAuthorizationToken(token);

        /* ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        ); */

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getCartId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setCartId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(hideAllNotifications());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        // CHANGE HERE
        saveCustomerFavoriteShopFromCookie();

        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );

        // Reset category & product list state to force new request if we go back to previous category page (for prices)
        dispatch(resetCurrentCategory());
        dispatch(resetProductListItems());

        handleRedirectToAllowedStore();
        // END CHANGE

        return true;
    }

    /**
     * Sign in as a customer from the back-office of Magento.
     * Before signing in, we logout the current user or we empty the wishlist and the cart for guests (only if we
     * manage to get a token)
     */
    async signInAsCustomer(options, dispatch) {
        const { secret = '' } = options;

        const mutation = MyAccountQuery.generateCustomerTokenByAdmin(secret);

        try {
            const { generateCustomerTokenByAdmin: { token = '' } = {} } = await fetchMutation(mutation);

            if (token) {
                if (!isSignedIn()) {
                    const clearWishlistAction = WishlistDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.clearWishlist(dispatch)
                    );
                    const emptyCurrentCartAction = CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.emptyCurrentCart(dispatch)
                    );

                    await Promise.all([clearWishlistAction, emptyCurrentCartAction]);
                } else {
                    this.logout(false, false, dispatch);
                }

                return await this.signIn({ token }, dispatch);
            }

            return Promise.reject();
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject(error);
        }
    }

    /**
     * Override: await for fetchMutation of getRevokeAccountToken + reload config
     * @param authTokenExpired
     * @param isWithNotification
     * @param dispatch
     */
    async logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        if (authTokenExpired) {
            if (isWithNotification) {
                dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            }

            this.handleForceRedirectToLoginPage();
        } else {
            if (isSignedIn()) {
                await fetchMutation(MyAccountQuery.getRevokeAccountToken());
                deleteAuthorizationToken();
            }

            if (isWithNotification) {
                dispatch(showNotification('success', __('You are successfully logged out!')));
            }
        }

        deleteCartId();
        BrowserDatabase.deleteItem(CUSTOMER);
        // removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );

        // dispatch(clearComparedProducts());
        // dispatch(updateCustomerDetails({}));

        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );

        // Reset category & product list state to force new request if we go back to previous category page (for prices)
        dispatch(resetCurrentCategory());
        dispatch(resetProductListItems());
    }

    /**
     * Override: verify if current store is allowed to customer, else redirect
     * @param dispatch
     */
    handleCustomerDataOnInit(dispatch) {
        handleRedirectToAllowedStore();

        super.handleCustomerDataOnInit(dispatch);
    }
}

export default new MyAccountDispatcher();
