import PropTypes from 'prop-types';

import {
    CustomErrorMessagesType,
    DateType,
    EventsType,
    FieldAttrType,
    FieldGroupValidationResponseType,
    // FieldOptionsType,
    LabelType,
    OptionType,
    ValidationRuleType
} from 'SourceType/Field.type';

export {
    CustomErrorMessagesType,
    DateType,
    EventsType,
    FieldAttrType,
    FieldGroupValidationResponseType,
    // FieldOptionsType,
    LabelType,
    OptionType,
    ValidationRuleType
};

export const FileType = PropTypes.shape({
    lastModified: PropTypes.number,
    lastModifiedDate: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    webkitRelativePath: PropTypes.string
});

/**
 * Override: add group to handle optgroup
 */
export const FieldOptionsType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: LabelType,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        group: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string
        })
    })
);

export const FieldGroupedOptionsType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: LabelType,
        options: FieldOptionsType
    })
);
