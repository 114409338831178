// TODO update this import when renamed
import { GET_SHOP, GET_SHOPS, SET_LOADING } from './FavoriteShop.action';

/** @namespace PeggysagePwa/Store/FavoriteShop/Reducer/getInitialState */
export const getInitialState = () => ({
    shop: null,
    shops: [],
    loading: true
});

/** @namespace PeggysagePwa/Store/FavoriteShop/Reducer/FavoriteShopReducer */
export const FavoriteShopReducer = (state = getInitialState(), action) => {
    const {
        type, shop, shops, loading
    } = action;

    switch (type) {
    case GET_SHOP:
        return {
            ...state,
            shop,
            loading
        };
    case GET_SHOPS:
        return {
            ...state,
            shops
        };
    case SET_LOADING:
        return {
            ...state,
            shops: loading ? [] : state.shops,
            loading
        };
    default:
        return state;
    }
};

export default FavoriteShopReducer;
