// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import MyAccountMyWishlist from 'Component/MyAccountMyWishlist';

import './NewWishlist.style';

/** @namespace PeggysagePwa/Route/NewWishlist/Component */
export class NewWishlistComponent extends PureComponent {
    renderContent() {
        return (
            <MyAccountMyWishlist />
        );
    }

    render() {
        return (
            <main block="NewWishlist" aria-label="Wishlist Page">
                <ContentWrapper
                  wrapperMix={ { block: 'NewWishlist', elem: 'Wrapper' } }
                  label="Wishlist page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default NewWishlistComponent;
