/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names, spaced-comment, max-lines */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import FieldContainer from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { PRODUCT_BUNDLE_OPTIONS_POPUP_ID } from 'Component/Product/Product.config.js';
import ProductBundleButton from 'Component/ProductBundleButton';
import { BUNDLE_TYPE } from 'Component/ProductBundleOption/ProductBundleOption.config';
import ProductBundleOptions from 'Component/ProductBundleOptions';
import ProductPopup from 'Component/ProductPopup';
import ProductReviewRating from 'Component/ProductReviewRating';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';
import { ShippingMethodType } from 'Type/Checkout.type';
import { getProductInStock } from 'Util/Product/Extract';
import { getQuantityFromSelectedOptions } from 'Util/Product/Transform';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import './Product.override.style';

/** @namespace PeggysagePwa/Component/Product/Component */
export class Product extends SourceProduct {
    static propTypes = {
        ...SourceProduct.propTypes,
        selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        isInCart: PropTypes.bool.isRequired,
        isLoadingChangeQty: PropTypes.bool.isRequired,
        cheapestShippingMethod: ShippingMethodType,
        setQuantityFromControls: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        isYotpoEnabled: PropTypes.bool.isRequired,
        yotpo: PropTypes.shape({
            rating_summary: PropTypes.number,
            review_count: PropTypes.number
        }).isRequired
    };

    static defaultProps = {
        ...SourceProduct.defaultProps,
        cheapestShippingMethod: null
    };

    //#region PRODUCT OPTIONS
    renderBundleButtons() {
        const {
            product,
            product: {
                items = []
            } = {},
            selectedOptions
        } = this.props;

        const optionsAuChoix = items.filter((option) => option.type !== BUNDLE_TYPE.obligatoire && option.type !== BUNDLE_TYPE.gift);
        const optionsObligatoires = items.filter((option) => option.type === BUNDLE_TYPE.obligatoire || option.type === BUNDLE_TYPE.gift);
        const options = optionsAuChoix.length ? optionsAuChoix : optionsObligatoires;

        return (
            <div block={ this.className } elem="BundleButtons">
                { options.map((option) => (
                    <ProductBundleButton
                      product={ product }
                      optionGroup={ option }
                      selectedOptions={ selectedOptions }
                      key={ option.option_id }
                    />
                )) }
            </div>
        );
    }

    /**
     * Override: add product to parameters of ProductBundleOptions
     * @returns {JSX.Element}
     */
    renderBundleOptions(areFiltersVisible = false) {
        const {
            product,
            product: {
                items
            } = {},
            updateSelectedValues,
            selectedOptions
        } = this.props;

        return (
            <ProductBundleOptions
              updateSelectedValues={ updateSelectedValues }
              options={ items }
              product={ product }
              selectedOptions={ selectedOptions }
              areFiltersVisible={ areFiltersVisible }
            />
        );
    }

    /**
     * Override: add button to show Bundle Options + popup
     * @returns {JSX.Element}
     */
    renderCustomAndBundleOptions() {
        const { product: { type_id }, configFormRef, activeOverlay } = this.props;

        // Bundle: remove form from DOM when bundle options popup is opened (since it also contains the form)
        if (type_id === PRODUCT_TYPE.bundle) {
            return (
                <>
                    { this.renderBundleButtons() }
                    { activeOverlay !== PRODUCT_BUNDLE_OPTIONS_POPUP_ID && (
                        <form ref={ configFormRef }>
                            { this.renderBundleOptions() }
                            { this.renderCustomizableOptions() }
                        </form>
                    ) }
                    <Popup
                      id={ PRODUCT_BUNDLE_OPTIONS_POPUP_ID }
                      // isCloseOnOutsideClick={ false }
                      mix={ { block: 'ProductBundleOptionsPopup' } }
                    >
                        <form ref={ configFormRef }>
                            { this.renderBundleOptions(true) }
                            { this.renderCustomizableOptions() }
                        </form>
                    </Popup>
                    <ProductPopup />
                </>
            );
        }

        return (
            <form ref={ configFormRef }>
                { this.renderCustomizableOptions() }
            </form>
        );
    }
    //#endregion

    //#region BUTTONS
    /**
     * Override: disable button on bundle with mandatory choice of products
     * @param layout
     * @returns {JSX.Element}
     */
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            selectedOptions,
            product: {
                type_id,
                items = [],
                bundle_tous_obligatoires
            },
            isInCart
        } = this.props;

        if (isInCart && type_id !== PRODUCT_TYPE.bundle) {
            return null;
        }

        // eslint-disable-next-line
        let hasBundleError = false;
        // eslint-disable-next-line
        let buttonSubLabel = '';

        if (type_id === PRODUCT_TYPE.bundle) {
            // Required products have not been selected
            const requiredGroups = items.filter((group) => group.required
                && [BUNDLE_TYPE.kit, BUNDLE_TYPE.couleur, BUNDLE_TYPE.produit].includes(group.type));

            // const requiredGroupsWithSelectedOptions = requiredGroups.filter(
            //     (group) => group.options.filter((option) => selectedOptions.includes(option.uid)).length
            // );
            const requiredGroupsWithSelectedOptions = requiredGroups.filter(
                (group) => {
                    const quantity = getQuantityFromSelectedOptions(selectedOptions, group.options);
                    return Object.keys(quantity).length;
                }
            );

            if (requiredGroups.length && requiredGroups.length !== requiredGroupsWithSelectedOptions.length) {
                hasBundleError = true;
                buttonSubLabel = requiredGroups.length === 1 ? __('Veuillez sélectionner votre produit')
                    : __('Veuillez sélectionner vos %s produits', requiredGroups.length);
            }

            // Some products are unavailable
            const incompleteGroups = items.filter((group) => (group.type === BUNDLE_TYPE.obligatoire || group.type === BUNDLE_TYPE.gift)
                && group.options.filter((option) => !getProductInStock(option.product)).length);

            if (incompleteGroups.length && bundle_tous_obligatoires) {
                hasBundleError = true;
                buttonSubLabel = __('Certains produits sont indisponibles');
            }
        }

        return (
            <AddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              addToCart={ addToCart }
              isDisabled={ !inStock || hasBundleError }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              product={ getActiveProduct() }
              subLabel={ buttonSubLabel }
            />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    quantityClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * Override: prevent click event bubbling to allow qty edition
     * @returns {JSX.Element|null}
     */
    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantityFromControls,
            inStock,
            product: { type_id },
            isInCart,
            isLoadingChangeQty
        } = this.props;

        if (!isInCart || type_id === PRODUCT_TYPE.grouped || type_id === PRODUCT_TYPE.bundle) {
            return null;
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              block={ this.className }
              elem="QtyWrapper"
              onClick={ this.quantityClickHandler }
            >
                <Loader isLoading={ isLoadingChangeQty } />
                <span block={ this.className } elem="QtyLabel">
                    { __('Quantity:') }
                </span>
                <FieldContainer
                  type={ FIELD_TYPE.numberWithControls }
                  attr={ {
                      id: 'item_qty',
                      name: 'item_qty',
                      defaultValue: quantity,
                      max: maxQuantity,
                      min: minQuantity
                  } }
                  // value={ quantity }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                      isRequired: true,
                      range: {
                          min: minQuantity,
                          max: maxQuantity
                      }
                  } }
                  isDisabled={ !inStock }
                  mix={ { block: this.className, elem: 'Qty' } }
                  events={ { onChange: setQuantityFromControls } }
                  validateOn={ ['onChange'] }
                />
            </div>
        );
    }
    //#endregion

    /**
     * Override: use Yotpo if enabled
     * @returns {JSX.Element|null}
     */
    renderRatingSummary() {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {}
            },
            yotpo: {
                rating_summary: yotpoRatingSummary,
                review_count: yotpoReviewCount
            },
            isYotpoEnabled
        } = this.props;

        const ratingSummary = isYotpoEnabled ? yotpoRatingSummary : rating_summary;
        const reviewCount = isYotpoEnabled ? yotpoReviewCount : review_count;

        if (!ratingSummary) {
            return null;
        }

        return <ProductReviewRating summary={ ratingSummary || 0 } count={ reviewCount } />;
    }

    /**
     * Override: check existence of attributes
     * @param withMeta
     * @returns {JSX.Element|null}
     */
    renderBrand(withMeta = false) {
        const {
            product
        } = this.props;

        if (!product.attributes) {
            return null;
        }

        return super.renderBrand(withMeta);
    }

    /**
     * Override: replace In Stock string by shipping delay
     * @returns {JSX.Element}
     */
    renderStock() {
        const { inStock, cheapestShippingMethod } = this.props;

        if (inStock) {
            if (!cheapestShippingMethod) {
                return null;
            }

            return (
                <span
                  block={ this.className }
                  elem="ShippingDelay"
                >
                    { cheapestShippingMethod?.delivery_date }
                </span>
            );
        }

        return (
            <span
              block={ this.className }
              elem="Stock"
              className="unavailable"
            >
                { __('This product is not available') }
            </span>
        );
    }
}

export default Product;
