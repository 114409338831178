import postscribe from 'postscribe';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import ExternalScript from './ExternalScript.component';

/** @namespace PeggysagePwa/Component/ExternalScript/Container */
export class ExternalScriptContainer extends PureComponent {
    static propTypes = {
        src: PropTypes.string.isRequired,
        attrId: PropTypes.string.isRequired
    };

    containerFunctions = {
        // ...
    };

    elemRef = createRef();

    componentDidMount() {
        const { src } = this.props;
        const { current } = this.elemRef;

        if (current) {
            postscribe(
                current,
                `<script src="${src}"></script>`
            );
        }
    }

    containerProps() {
        const { src, attrId } = this.props;

        return {
            src,
            attrId,
            elemRef: this.elemRef
        };
    }

    render() {
        return (
            <ExternalScript
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ExternalScriptContainer;
