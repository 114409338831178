// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FavoriteShopComponent } from './FavoriteShop.component';

import './FavoriteShop.style';

export const FavoriteShopDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/FavoriteShop/FavoriteShop.dispatcher'
);

/** @namespace PeggysagePwa/Route/FavoriteShop/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    shop: state.FavoriteShopReducer.shop,
    isLoading: state.FavoriteShopReducer.loading,
    shops: state.FavoriteShopReducer.shops
});

/** @namespace PeggysagePwa/Route/FavoriteShop/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getShop: () => FavoriteShopDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getFavoriteShop(dispatch)
    ),
    setShopWithLocation: (location) => FavoriteShopDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setFavoriteShop(dispatch, null, location)
    ),
    setShopWithId: (id) => FavoriteShopDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setFavoriteShop(dispatch, id, null)
    ),
    searchShop: (id) => FavoriteShopDispatcher.then(
        ({ default: dispatcher }) => dispatcher.searchShop(dispatch, id)
    )
});

/** @namespace PeggysagePwa/Route/FavoriteShop/Container */
export class FavoriteShopContainer extends PureComponent {
    static propTypes = {
        getShop: PropTypes.func.isRequired,
        setShopWithId: PropTypes.func.isRequired,
        setShopWithLocation: PropTypes.func.isRequired,
        searchShop: PropTypes.func.isRequired,
        shop: PropTypes.isRequired,
        shops: PropTypes.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    componentDidMount() {
        const { isLoading } = this.props;
        const {
            getShop
        } = this.props;

        if (isLoading) {
            getShop();
        }
    }

    containerProps() {
        const {
            searchShop, isLoading, setShopWithId, setShopWithLocation, shop, shops
        } = this.props;

        return {
            search: searchShop, setShopWithId, setShopWithLocation, shop, shops, isLoading
        };
    }

    render() {
        return <FavoriteShopComponent { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteShopContainer);
