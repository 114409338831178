import PropTypes from 'prop-types';

import {
    ContentWrapper as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

/** @namespace PeggysagePwa/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends SourceContentWrapper {
    static propTypes = {
        ...SourceContentWrapper.propTypes,
        id: PropTypes.string
    };

    static defaultProps = {
        ...SourceContentWrapper.defaultProps,
        id: null
    };

    /**
     * Override: add id attribute
     * @returns {JSX.Element|*}
     */
    render() {
        const {
            mix, label, isNotSection, id
        } = this.props;

        if (isNotSection) {
            return this.renderContentWrapper();
        }

        return (
            <section mix={ mix } aria-label={ label } id={ id }>
                { this.renderContentWrapper() }
            </section>
        );
    }
}

export default ContentWrapperComponent;
