/* eslint-disable simple-import-sort/sort */
import PropTypes from 'prop-types';

import {
    RegionType,
    Addresstype as SourceAddresstype,
    AddressesType,
    // CustomerType,
    MY_ACCOUNT,
    MY_ORDERS,
    MY_ORDER,
    MY_DOWNLOADABLE,
    MY_WISHLIST,
    ADDRESS_BOOK,
    NEWSLETTER_SUBSCRIPTION,
    ACCOUNT_INFORMATION,
    FIRST_SECTION,
    SECOND_SECTION,
    THIRD_SECTION,
    INFORMATION_EDIT,
    PASSWORD_EDIT,
    EMAIL_EDIT,
    ActiveTabType,
    TabType,
    TabMapType,
    SignInStateType
} from 'SourceType/Account.type';

export {
    RegionType,
    // Addresstype,
    AddressesType,
    // CustomerType,
    MY_ACCOUNT,
    MY_ORDERS,
    MY_ORDER,
    MY_DOWNLOADABLE,
    MY_WISHLIST,
    ADDRESS_BOOK,
    NEWSLETTER_SUBSCRIPTION,
    ACCOUNT_INFORMATION,
    FIRST_SECTION,
    SECOND_SECTION,
    THIRD_SECTION,
    INFORMATION_EDIT,
    PASSWORD_EDIT,
    EMAIL_EDIT,
    ActiveTabType,
    TabType,
    TabMapType,
    SignInStateType
};

export const MY_FAVORITES_ORDERS = 'my-favorites-orders';
export const MY_PROFILE = 'my-profile';
export const CUSTOMER_SERVICE = 'customer-service';
export const CUSTOMER_SERVICE_ISSUE = 'customer-service-issue';
export const QUICK_ORDER = 'quick-order';
export const MY_DOCUMENTS = 'my-documents';

/**
 * Override
 */
export const Addresstype = PropTypes.shape({
    ...SourceAddresstype,
    company: PropTypes.string
});

export const CustomerType = PropTypes.shape({
    AddressesType,
    created_at: PropTypes.string,
    default_billing: PropTypes.string,
    default_shipping: PropTypes.string,
    // dob: PropTypes.date,
    email: PropTypes.string,
    firstname: PropTypes.string,
    group_id: PropTypes.number,
    id: PropTypes.number,
    is_subscribed: PropTypes.bool,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    taxvat: PropTypes.string
});

export const CompanySearchType = PropTypes.shape({
    name: PropTypes.string,
    siren: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ape: PropTypes.string,
    apetexte: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string
});

export const CompanyDetailsType = PropTypes.shape({
    name: PropTypes.string,
    siren: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    siret: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ape: PropTypes.string,
    apetexte: PropTypes.string,
    street: PropTypes.arrayOf(
        PropTypes.string
    ),
    postcode: PropTypes.string,
    city: PropTypes.string
});

export const AgreementType = PropTypes.shape({
    link: PropTypes.string,
    display_mode: PropTypes.string,
    checkbox_text: PropTypes.string
});

export const CustomerFormValuesType = PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    // lastGuestOrder:
    PropTypes.shape({
        number: PropTypes.string,
        id: PropTypes.string,
        email: PropTypes.string
    })
]));
