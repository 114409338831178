import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore/index';
import FavoriteShopReducer from 'Store/FavoriteShop/FavoriteShop.reducer';
import GdprReducer from 'Store/Gdpr/Gdpr.reducer';
import IssueReducer from 'Store/Issue/Issue.reducer';
import MenuReducer from 'Store/Menu/Menu.reducer';
import OrderReducer from 'Store/Order/Order.reducer';
import PageReducer from 'Store/Page/Page.reducer';
import PerfectCorpProductReducer from 'Store/PerfectCorpProduct/PerfectCorpProduct.reducer';
import QuickOrderReducer from 'Store/QuickOrder/QuickOrder.reducer';
import WidgetReducer from 'Store/Widget/Widget.reducer';
import YotpoReducer from 'Store/Yotpo/Yotpo.reducer';

/** @namespace PeggysagePwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    FavoriteShopReducer,
    GdprReducer,
    PageReducer,
    QuickOrderReducer,
    IssueReducer,
    OrderReducer,
    MenuReducer,
    WidgetReducer,
    PerfectCorpProductReducer,
    YotpoReducer
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}
