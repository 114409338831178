/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React from 'react';

import CategoryConfigurableAttributes from 'Component/CategoryConfigurableAttributes';
import CategorySort from 'Component/CategorySort';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import FilterIcon from 'Component/FilterIcon';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import ProductCard from 'Component/ProductCard';
import ProductListPage from 'Component/ProductListPage';
import { WISHLIST_PAGE_TITLE } from 'Route/NewWishlist/NewWishlist.config';
import {
    MyAccountMyWishlist as SourceMyAccountMyWishlist
} from 'SourceComponent/MyAccountMyWishlist/MyAccountMyWishlist.component';
import { CustomerType } from 'Type/Account.type';
import { SelectedFiltersType } from 'Type/Category.type';
import { SortDirectionType } from 'Type/Direction.type';
import { AttributesType, ProductType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';
import history from 'Util/History';

import { WISHLIST_FILTER_POPUP_ID } from './MyAccountMyWishlist.config';

import './MyAccountMyWishlist.override.style';

/** @namespace PeggysagePwa/Component/MyAccountMyWishlist/Component */
export class MyAccountMyWishlistComponent extends SourceMyAccountMyWishlist {
    static propTypes = {
        ...SourceMyAccountMyWishlist.propTypes,
        sortFields: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string
        })).isRequired,
        selectedSort: PropTypes.shape({
            sortDirection: SortDirectionType,
            sortKey: PropTypes.string
        }).isRequired,
        filters: AttributesType.isRequired,
        customFiltersValues: SelectedFiltersType.isRequired,
        relatedProducts: PropTypes.arrayOf(ProductType),
        customer: CustomerType.isRequired,
        sortWishlistItems: PropTypes.func.isRequired,
        onFilterButtonClick: PropTypes.func.isRequired,
        onFilterSubmitButtonClick: PropTypes.func.isRequired,
        toggleCustomFilter: PropTypes.func.isRequired
    };

    static defaultProps = {
        relatedProducts: []
    };

    /**
     * Override: disable
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setActionLineHeight() {
        return null;
    }

    /**
     * Override
     * @param id
     * @param product
     * @returns {JSX.Element}
     */
    renderProduct([id, product]) {
        return (
            <ProductCard product={ product } key={ id } />
        );
    }

    /**
     * Override: sort items + add verification (security)
     * @returns {unknown[]|*[]}
     */
    renderProducts() {
        const {
            isWishlistLoading,
            isWishlistEmpty,
            wishlistItems
        } = this.props;

        if (isWishlistLoading && isWishlistEmpty) {
            return this.renderPlaceholders();
        }

        if (!wishlistItems) {
            return this.renderNoProductsFound();
        }

        return Object.entries(wishlistItems)
            .sort((a, b) => a[1].sort - b[1].sort)
            .map(this.renderProduct.bind(this));
    }

    renderBackButton() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <div block="MyAccountMyWishlist" elem="Back">
                <button
                  type="button"
                  block="MyAccountMyWishlist"
                  elem="BackButton"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => history.goBack() }
                >
                    <ChevronIcon direction={ LEFT } />
                    { __('Back') }
                </button>
            </div>
        );
    }

    renderFilterButton() {
        const { filters, onFilterButtonClick } = this.props;

        if (!filters) {
            return null;
        }

        return (
            <div block="MyAccountMyWishlist" elem="LayoutWrapper">
                <button
                  block="MyAccountMyWishlist"
                  elem="FilterBtn"
                  onClick={ onFilterButtonClick }
                >
                    <FilterIcon />
                    <span>{ __('Filtrer') }</span>
                </button>
            </div>
        );
    }

    renderCategorySort() {
        const {
            isLoading, sortFields, selectedSort: { sortDirection, sortKey }, sortWishlistItems
        } = this.props;

        return (
            <div block="MyAccountMyWishlist" elem="LayoutWrapper">
                <CategorySort
                  isCurrentCategoryLoaded={ !isLoading }
                  isMatchingInfoFilter
                  onSortChange={ sortWishlistItems }
                  sortFields={ sortFields }
                  sortKey={ sortKey }
                  sortDirection={ sortDirection }
                />
            </div>
        );
    }

    renderOptionsBar() {
        const {
            isWishlistEmpty,
            isWishlistLoading
        } = this.props;

        if (isWishlistEmpty && !isWishlistLoading) {
            return null;
        }

        return (
            <aside block="MyAccountMyWishlist" elem="Miscellaneous">
                <div block="MyAccountMyWishlist" elem="MiscellaneousLayoutWrapper">
                    { this.renderFilterButton() }
                    { this.renderCategorySort() }
                </div>
            </aside>
        );
    }

    renderPageTitle() {
        return (
            <h1 block="MyAccountMyWishlist" elem="Heading">
                { WISHLIST_PAGE_TITLE }
            </h1>
        );
    }

    renderFilterPopupContent() {
        const {
            filters,
            customFiltersValues,
            toggleCustomFilter,
            onFilterSubmitButtonClick
        } = this.props;

        return (
            <>
                <div block="MyAccountMyWishlist" elem="PopupAttributes">
                    <CategoryConfigurableAttributes
                      // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [1, 1] }
                      mix={ { block: 'MyAccountMyWishlist', elem: 'Attributes' } }
                      configurable_options={ filters }
                      parameters={ customFiltersValues }
                      updateConfigurableVariant={ toggleCustomFilter }
                      isContentExpanded
                    />
                </div>
                <div block="MyAccountMyWishlist" elem="PopupActions">
                    <button
                      type="button"
                      block="MyAccountMyWishlist"
                      elem="PopupSubmitButton"
                      mix={ { block: 'Button' } }
                      onClick={ onFilterSubmitButtonClick }
                    >
                        { __('Voir les résultats') }
                    </button>
                </div>
            </>
        );
    }

    renderFilterPopup() {
        const { filters } = this.props;

        if (!filters) {
            return null;
        }

        return (
            <Popup
              id={ WISHLIST_FILTER_POPUP_ID }
              mix={ { block: 'MyAccountMyWishlistFilterPopup' } }
            >
                { this.renderFilterPopupContent() }
            </Popup>
        );
    }

    renderRelatedProducts() {
        const { relatedProducts, isWishlistLoading, customer: { firstname = '' } = {} } = this.props;

        if (!relatedProducts.length && !isWishlistLoading) {
            return null;
        }

        const title = __('En manque d\'inspiration %s ?', firstname);

        const products = isWishlistLoading && !relatedProducts?.length
            ? Array.from({ length: 2 }, () => ({})) : relatedProducts;

        return (
            <div block="MyAccountMyWishlist" elem="RelatedProducts">
                <h2 block="MyAccountMyWishlist" elem="RelatedProductsTitle">
                    { title }
                </h2>
                <div block="MyAccountMyWishlist" elem="RelatedProductsContent">
                    <ProductListPage
                      isInfiniteLoaderEnabled={ false }
                      isLoading={ isWishlistLoading }
                      isVisible
                      updatePages={ noopFn }
                      items={ products }
                      isPlp
                    />
                </div>
            </div>
        );
    }

    /**
     * Override: show actions on mobile too
     * @returns {JSX.Element}
     */
    renderActionBarMobile() {
        return (
            <>
                <div block="MyAccountMyWishlist" elem="ActionBarActionWrapper">
                    { this.renderShareWishlistButton() }
                    { this.renderClearWishlist() }
                </div>
                <div
                  ref={ this.actionLineMobileRef }
                  block="MyAccountMyWishlist"
                  elem="ActionBar"
                >
                    { this.renderAddAllToCart() }
                </div>
            </>
        );
    }

    /**
     * Override: hide actions if wishlist is empty
     * @returns {null|*}
     */
    renderActionBar() {
        const {
            isWishlistLoading,
            isWishlistEmpty
        } = this.props;

        if (isWishlistEmpty && !isWishlistLoading) {
            return null;
        }

        return super.renderActionBar();
    }

    /**
     * Override: modify wrapper
     * @returns {JSX.Element|*}
     */
    renderContent() {
        const {
            isWishlistLoading,
            isWishlistEmpty,
            isLoading
        } = this.props;

        if (isWishlistEmpty && !isWishlistLoading) {
            return this.renderNoProductsFound();
        }

        return (
            <div block="MyAccountMyWishlist" elem="Products" ref={ this.productsRef }>
                <Loader isLoading={ isLoading } />
                <ul className="ProductListPage">
                    { this.renderProducts() }
                </ul>
            </div>
        );
    }

    /**
     * Override
     * @returns {JSX.Element}
     */
    render() {
        const { isWishlistLoading } = this.props;

        return (
            <div block="MyAccountMyWishlist">
                { this.renderFilterPopup() }
                { this.renderShareWishlist() }
                { this.renderBackButton() }
                { this.renderPageTitle() }
                <div block="MyAccountMyWishlist" elem="Main">
                    <Loader isLoading={ isWishlistLoading } />
                    { this.renderOptionsBar() }
                    { this.renderContent() }
                    { this.renderActionBar() }
                </div>
                { this.renderRelatedProducts() }
            </div>
        );
    }
}

export default MyAccountMyWishlistComponent;
