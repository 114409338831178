import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumberWithControls as SourceFieldNumberWithControls
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import './FieldNumberWithControls.override.style';

/** @namespace PeggysagePwa/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControls {
    static propTypes = {
        ...SourceFieldNumberWithControls.propTypes,
        onInputFocus: PropTypes.func.isRequired,
        onInputBlur: PropTypes.func.isRequired,
        onInputKeyDown: PropTypes.func.isRequired
    };

    /**
     * Override: pass event as parameter to button click handler
     * @returns {JSX.Element}
     */
    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            // events,
            setRef,
            stateValue,
            handleValueChange,
            onInputFocus,
            onInputBlur,
            onInputKeyDown,
            isDisabled
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  onFocus={ onInputFocus }
                  onBlur={ onInputBlur }
                  onKeyDown={ onInputKeyDown }
                  // value={ value }
                  type={ FIELD_TYPE.number }
                  // readOnly
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                />
                <button
                  disabled={ max === 1 || numberValue >= max || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleValueChange(numberValue + 1, e) }
                  aria-label={ __('Add') }
                  type={ FIELD_TYPE.button }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleValueChange(numberValue - 1, e) }
                  aria-label={ __('Subtract') }
                  type={ FIELD_TYPE.button }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
