import {
    // convertStringToDate,
    getTimeInCurrentTimezone,
    MILLISECONDS_PER_MINUTE
} from 'SourceUtil/Manipulations/Date';
import getStore from 'Util/Store';

export {
    // convertStringToDate,
    getTimeInCurrentTimezone,
    MILLISECONDS_PER_MINUTE
};

/**
 * Override: use store config language instead of navigator
 * @param stringDate
 * @param options
 * @returns {string}
 * @namespace PeggysagePwa/Util/Manipulations/Date/convertStringToDate
 */
export const convertStringToDate = (stringDate, options) => {
    const defaultOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const date = new Date(stringDate.replace(/\s/, 'T'));

    const state = getStore().getState();
    const { code: configLanguage } = state.ConfigReducer;

    const navigatorLanguage = navigator.languages ? navigator.languages[0] : navigator.language;

    const language = configLanguage || navigatorLanguage;

    return date.toLocaleDateString(language, options || defaultOptions);
};

/**
 * Convert DD/MM/YYYY to YYYY-MM-DD
 * @param stringDate
 * @returns {string|*}
 * @namespace PeggysagePwa/Util/Manipulations/Date/formatDate
 */
export const formatDate = (stringDate) => {
    if (stringDate.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)) { // DD/MM/YYYY
        // eslint-disable-next-line no-magic-numbers
        const year = stringDate.substr(6, 4);
        // eslint-disable-next-line no-magic-numbers
        const month = stringDate.substr(3, 2);
        // eslint-disable-next-line no-magic-numbers
        const day = stringDate.substr(0, 2);

        return `${year}-${month}-${day}`; // YYYY-MM-DD
    }

    return stringDate;
};

/** @namespace PeggysagePwa/Util/Manipulations/Date/getTimestampFromDate */
export const getTimestampFromDate = (stringDate) => {
    const formattedDate = formatDate(stringDate);

    return Date.parse(formattedDate);
};

/** @namespace PeggysagePwa/Util/Manipulations/Date/convertStringToTime */
export const convertStringToTime = (stringDate, options) => {
    const defaultOptions = {};
    const date = new Date(stringDate.replace(/\s/, 'T'));

    const state = getStore().getState();
    const { code: configLanguage } = state.ConfigReducer;

    const navigatorLanguage = navigator.languages ? navigator.languages[0] : navigator.language;

    const language = configLanguage || navigatorLanguage;

    return date.toLocaleTimeString(language, options || defaultOptions);
};
