import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { GROUP_ID_EMPLOYEE, GROUP_ID_PRO, GROUP_ID_STUDENT } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { CustomerType } from 'Type/Account.type';

/** @namespace PeggysagePwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    trainingAttributeSetId: state.ConfigReducer.training,
    customer: state.MyAccountReducer.customer,
    isCustomerTypeStudentEnabled: state.ConfigReducer.students_active
});

/** @namespace PeggysagePwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        trainingAttributeSetId: PropTypes.number.isRequired,
        customer: CustomerType.isRequired,
        isCustomerTypeStudentEnabled: PropTypes.bool.isRequired,
        isSkuVisible: PropTypes.bool
    };

    static defaultProps = {
        ...SourceProductCardContainer.defaultProps,
        isSkuVisible: false
    };

    componentDidMount() {
        super.componentDidMount();

        this.setState({
            isPictureSliderEnabled: !document.querySelector('.CmsPage_tutorial')
        });
    }

    getTrainingAttributeSetId() {
        const { trainingAttributeSetId } = this.props;

        return trainingAttributeSetId;
    }

    /**
     * Override: add other thumbnails
     * @returns {{otherThumbnails: ([]|*)}}
     */
    containerProps() {
        const {
            product, isSkuVisible, customer: { group_id = 0 } = {}, isCustomerTypeStudentEnabled
        } = this.props;
        const { isPictureSliderEnabled } = this.state;

        return {
            ...super.containerProps(),
            otherThumbnails: this.getOtherThumbnails(this.getActiveProduct()) || this.getOtherThumbnails(product),
            trainingAttributeSetId: this.getTrainingAttributeSetId(),
            imageSecondaire: this.getImageSecondaire(this.getActiveProduct()) || this.getImageSecondaire(product),
            isPictureSliderEnabled,
            isSkuVisible,
            isProEtu: [GROUP_ID_EMPLOYEE, GROUP_ID_PRO, GROUP_ID_STUDENT].includes(group_id),
            isCustomerTypeStudentEnabled
        };
    }

    getOtherThumbnails(product) {
        const { device } = this.props;

        if (!product || !product.media_gallery_entries || product.media_gallery_entries.length === 0) {
            return [];
        }

        return product.media_gallery_entries
            .filter((image) => !image.disabled && !image.types.some((type) => type === 'small_image'))
            .map((image) => image.base.url)
            .filter((image, index) => !(!device.isMobile && index > 0));
    }

    getImageSecondaire(product) {
        if (product.image_secondaire) {
            return [product.image_secondaire];
        }

        return [];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
