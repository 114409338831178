import Loader from 'Component/Loader';
import UploadIcon from 'Component/UploadIcon';
import {
    FieldFile as SourceFieldFile
} from 'SourceComponent/FieldFile/FieldFile.component';

/** @namespace PeggysagePwa/Component/FieldFile/Component */
export class FieldFileComponent extends SourceFieldFile {
    /**
     * Override: add class on label when a file is selected
     * @returns {JSX.Element}
     */
    renderFileLabel() {
        const {
            attr: { id = '', multiple = false } = {},
            fileName = '',
            isLoading = false,
            resetFieldValue
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (fileName) {
            return (
                <label htmlFor={ id } block="FieldFile" elem="Label" mods={ { hasFile: true } }>
                    <p>{ fileName }</p>
                    <button onClick={ resetFieldValue }>{ __('Remove file') }</button>
                </label>
            );
        }

        const dropLabel = multiple ? __('Drop files here or') : __('Drop file here or');
        const selectLabel = multiple ? __('Select files') : __('Select file');

        return (
            <label htmlFor={ id } block="FieldFile" elem="Label">
                <UploadIcon />
                <p>{ dropLabel }</p>
                <span block="Field" elem="SelectFileBtn">{ selectLabel }</span>
            </label>
        );
    }
}

export default FieldFileComponent;
