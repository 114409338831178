export const SET_ISSUES = 'SET_ISSUES';
export const SET_ISSUE = 'SET_ISSUE';

/** @namespace PeggysagePwa/Store/Issue/Action/setClientTickets */
export const setClientTickets = (payload) => ({
    type: SET_ISSUES,
    ...payload
});

/** @namespace PeggysagePwa/Store/Issue/Action/setClientTicket */
export const setClientTicket = (payload) => ({
    type: SET_ISSUE,
    ...payload
});
