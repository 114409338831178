import {
    FieldFileContainer as SourceFieldFileContainer
} from 'SourceComponent/FieldFile/FieldFile.container';

/** @namespace PeggysagePwa/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    // ...
}

export default FieldFileContainer;
