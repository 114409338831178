import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/ExpandableContent/ExpandableContent.container';
import { getFixedElementHeight } from 'Util/CSS';

import ExpandableContent from './ExpandableContent.component';

/** @namespace PeggysagePwa/Component/ExpandableContent/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace PeggysagePwa/Component/ExpandableContent/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/ExpandableContent/Container */
export class ExpandableContentContainer extends PureComponent {
    static propTypes = {
        ...SourceExpandableContent.propTypes,
        groupId: PropTypes.string,
        subHeading: PropTypes.string
    };

    static defaultProps = {
        ...SourceExpandableContent.defaultProps,
        groupId: '',
        subHeading: ''
    };

    containerFunctions = {
        toggleExpand: this.toggleExpand.bind(this)
    };

    expandableContentRef = createRef();

    componentDidUpdate(prevProps) {
        const { isContentExpanded } = this.props;
        const { isContentExpanded: prevIsContentExpanded } = prevProps;

        if (isContentExpanded !== prevIsContentExpanded) {
            this.setState({ isContentExpanded });
        }
    }

    __construct(props) {
        super.__construct(props);

        const { isContentExpanded } = props;

        this.state = {
            isContentExpanded: isContentExpanded || false
        };
    }

    containerProps() {
        const { isContentExpanded } = this.state;
        const {
            isArrow,
            heading,
            children,
            mix,
            mods,
            device,
            onClick,
            groupId,
            subHeading
        } = this.props;

        return {
            isContentExpanded,
            isArrow,
            heading,
            children,
            mix,
            mods,
            device,
            onClick,
            groupId,
            subHeading,
            expandableContentRef: this.expandableContentRef
        };
    }

    /**
     * From SourceExpandableContent
     */
    scrollToExpandedContent() {
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if (isContentExpanded && !elem) {
            return;
        }

        const elemToWindowTopDist = elem.getBoundingClientRect().top;
        const windowToPageTopDist = document.body.getBoundingClientRect().top;
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    /**
     * If a groupId is provided, close the other Contents of the group
     * when current is expanded
     */
    toggleExpand() {
        const { onClick, groupId } = this.props;
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if (onClick) {
            onClick();

            return;
        }

        if (!isContentExpanded && groupId) {
            const siblings = Array.from(document.querySelectorAll(`[data-group="${groupId}"]`))
                .filter((element) => element !== elem);

            if (siblings.length > 0) {
                siblings.forEach((element) => {
                    const button = element.querySelector('.ExpandableContent-Button');
                    if (button.classList.contains('ExpandableContent-Button_isContentExpanded')) {
                        button.click();
                    }
                });
            }
        }

        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent()
        );
    }

    render() {
        return (
            <ExpandableContent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableContentContainer);
