import { DataContainer as SourceDataContainer } from 'SourceUtil/Request/DataContainer';
import { noopFn } from 'Util/Common';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executeGet, listenForBroadCast } from 'Util/Request';
import { hash } from 'Util/Request/Hash';

import { getMagentoCacheId } from '../../plugin/UtilRequest.plugin';

/** @namespace PeggysagePwa/Util/Request/DataContainer */
export class DataContainer extends SourceDataContainer {
    fetchData(rawQueries, onSuccess = noopFn, onError = noopFn, takeFromWindowCache = false) {
        const magentoCacheId = getMagentoCacheId();
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables)) + (magentoCacheId || '');
        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (takeFromWindowCache && window.dataCache[queryHash]) {
            onSuccess(window.dataCache[queryHash]);

            return;
        }

        this.promise = makeCancelable(
            executeGet(preparedQuery, this.dataModelName, this.cacheTTL)
        );

        this.promise.promise.then(
            /** @namespace PeggysagePwa/Util/Request/DataContainer/DataContainer/fetchData/then */
            (response) => {
                window.dataCache[queryHash] = response;
                onSuccess(response);
            },
            /** @namespace PeggysagePwa/Util/Request/DataContainer/DataContainer/fetchData/then/onError/catch */
            (err) => onError(err)
        );

        if (this.isShouldListenForBroadcast) {
            listenForBroadCast(this.dataModelName).then(
                /** @namespace PeggysagePwa/Util/Request/DataContainer/DataContainer/fetchData/listenForBroadCast/then/onSuccess */
                onSuccess
            );
        }
    }
}

export default DataContainer;
