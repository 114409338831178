/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class GoogleMerchantQuery {
    _getGoogleMerchantConfigurationFields = () => ([
        'enabled',
        'merchant_id',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'google_merchant_general_init',
        'google_merchant_purchase'
    ]);

    getGoogleMerchantConfiguration = () => new Field('getGoogleMerchant')
        .setAlias('googleMerchant')
        .addFieldList(this._getGoogleMerchantConfigurationFields());
}

export default new GoogleMerchantQuery();
