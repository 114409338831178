import PropTypes from 'prop-types';
import { Fragment } from 'react';

import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';
import { FieldGroupedOptionsType } from 'Type/Field.type';

import './FieldSelect.override.style';

/** @namespace PeggysagePwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    static propTypes = {
        ...SourceFieldSelect.propTypes,
        isMobile: PropTypes.bool.isRequired,
        groupedOptions: FieldGroupedOptionsType.isRequired,
        selectedOptionValue: PropTypes.string.isRequired
    };

    renderNativeOptionGroup(key) {
        const { groupedOptions } = this.props;

        if (!groupedOptions[key]) {
            return null;
        }

        const { id, label, options } = groupedOptions[key];

        if (!label) {
            return (
                <Fragment key={ key }>
                    { options.map(this.renderNativeOption.bind(this)) }
                </Fragment>
            );
        }

        return (
            <optgroup key={ id } label={ label }>
                { options.map(this.renderNativeOption.bind(this)) }
            </optgroup>
        );
    }

    /**
     * Override: handle option group
     * @returns {JSX.Element}
     */
    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, groupedOptions, handleSelectListOptionClick, isSelectedOptionAvailable
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              mods={ { isDisabled: !isSelectedOptionAvailable } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events }
              onChange={ handleSelectListOptionClick }
            >
                { Object.keys(groupedOptions).map(this.renderNativeOptionGroup.bind(this)) }
            </select>
        );
    }

    renderOptionGroupLabel(group) {
        const { id, label } = group;

        if (!label) {
            return null;
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isGroup: true } }
              key={ id }
            >
                { label }
            </li>
        );
    }

    /**
     * Override: add class on selected option
     * @param option
     * @param index
     * @returns {JSX.Element}
     */
    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered = false,
            isAvailable = true,
            value
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            selectedOptionValue
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
                  isSelected: value.toString() === selectedOptionValue
              } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }

    /**
     * Override: handle option group
     * @returns {JSX.Element}
     */
    renderOptions() {
        const {
            groupedOptions,
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ {
                  isExpanded,
                  isDropdownOpenUpwards,
                  isNotScrollable: !isScrollable
              } }
            >
                <div
                  block="FieldSelect"
                  elem="OptionsWrapper"
                  role="menu"
                  mods={ { isExpanded } }
                >
                    { Object.keys(groupedOptions).map((key) => (
                        <Fragment key={ key }>
                            { this.renderOptionGroupLabel(groupedOptions[key]) }
                            { groupedOptions[key].options.map(this.renderOption.bind(this)) }
                        </Fragment>
                    )) }
                </div>
            </ul>
        );
    }

    /**
     * Override: different label on desktop
     * @returns {JSX.Element|null}
     */
    renderSortSelect() {
        const { isSortSelect, isMobile } = this.props;

        if (!isSortSelect) {
            return null;
        }

        const label = isMobile ? __('Sort') : __('Sort by');

        return (
            <div block="FieldSelect" elem="SortSelect">{ label }</div>
        );
    }
}

export default FieldSelectComponent;
