export const QUICKORDER_GET_PRODUCTS = 'QUICKORDER_GET_PRODUCTS';
export const QUICKORDER_ADD_PRODUCT = 'QUICKORDER_ADD_PRODUCT';
export const QUICKORDER_REMOVE_PRODUCT = 'QUICKORDER_REMOVE_PRODUCT';
export const QUICKORDER_EMPTY_SELECTED = 'QUICKORDER_EMPTY_SELECTED';
export const QUICKORDER_INIT_SEARCH = 'QUICKORDER_INIT_SEARCH';

/** @namespace PeggysagePwa/Store/QuickOrder/Action/updateSuggestedProducts */
export const updateSuggestedProducts = (payload) => ({
    type: QUICKORDER_GET_PRODUCTS,
    ...payload
});

/** @namespace PeggysagePwa/Store/QuickOrder/Action/initSearchLoad */
export const initSearchLoad = () => ({
    type: QUICKORDER_INIT_SEARCH
});

/** @namespace PeggysagePwa/Store/QuickOrder/Action/addProductToSelected */
export const addProductToSelected = (payload) => ({
    type: QUICKORDER_ADD_PRODUCT,
    ...payload
});

/** @namespace PeggysagePwa/Store/QuickOrder/Action/removeProductFromSelected */
export const removeProductFromSelected = (payload) => ({
    type: QUICKORDER_REMOVE_PRODUCT,
    ...payload
});

/** @namespace PeggysagePwa/Store/QuickOrder/Action/emptySelected */
export const emptySelected = () => ({
    type: QUICKORDER_EMPTY_SELECTED
});
