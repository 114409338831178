import {
    BILLING_STEP,
    BILLING_URL,
    BILLING_URL_STEP,
    CHECKOUT_URL,
    CHECKOUT_URL_REGEX,
    DETAILS_STEP,
    DETAILS_URL_STEP,
    PAYMENT_TOTALS,
    SHIPPING_STEP,
    SHIPPING_URL,
    SHIPPING_URL_STEP,
    UPDATE_EMAIL_CHECK_FREQUENCY
} from 'SourceRoute/Checkout/Checkout.config';

export {
    BILLING_STEP,
    BILLING_URL,
    BILLING_URL_STEP,
    CHECKOUT_URL,
    CHECKOUT_URL_REGEX,
    DETAILS_STEP,
    DETAILS_URL_STEP,
    PAYMENT_TOTALS,
    SHIPPING_STEP,
    SHIPPING_URL,
    SHIPPING_URL_STEP,
    UPDATE_EMAIL_CHECK_FREQUENCY
};

export const CUSTOMER_STEP = 'CUSTOMER_STEP';
export const EXTERNAL_PAYMENT_STEP = 'EXTERNAL_PAYMENT_STEP';

export const EXTERNAL_PAYMENT_URL_STEP = 'externalpayment';

// from urls in stepMap of Checkout component
export const CHECKOUT_CUSTOMER_URL = `${CHECKOUT_URL}/customer`;
export const CHECKOUT_CUSTOMER_CREATE_URL = `${CHECKOUT_URL}/customer/create`;
export const CHECKOUT_SHIPPING_URL = `${CHECKOUT_URL}/shipping`;
export const CHECKOUT_BILLING_URL = `${CHECKOUT_URL}/billing`;
export const CHECKOUT_SUCCESS_URL = `${CHECKOUT_URL}/success`;
export const CHECKOUT_EXTERNAL_PAYMENT_URL = `${CHECKOUT_URL}/externalpayment`;
