import PropTypes from 'prop-types';

import {
    ProductBundleOptionsContainer as SourceProductBundleOptionsContainer
} from 'SourceComponent/ProductBundleOptions/ProductBundleOptions.container';
import { ProductType } from 'Type/ProductList.type';

/** @namespace PeggysagePwa/Component/ProductBundleOptions/Container */
export class ProductBundleOptionsContainer extends SourceProductBundleOptionsContainer {
    static propTypes = {
        ...SourceProductBundleOptionsContainer.propTypes,
        product: ProductType.isRequired,
        selectedOptions: PropTypes.arrayOf(PropTypes.string),
        areFiltersVisible: PropTypes.bool
    };

    static defaultProps = {
        selectedOptions: [],
        areFiltersVisible: false
    };

    containerProps() {
        const { product, selectedOptions, areFiltersVisible } = this.props;

        return {
            ...super.containerProps(),
            product,
            selectedOptions,
            areFiltersVisible
        };
    }
}

export default ProductBundleOptionsContainer;
