/* eslint-disable simple-import-sort/sort */

import {
    formatCurrency,
    // formatPrice,
    calculateFinalPrice,
    calculateTierDiscountOverSpecialPrice,
    roundPrice,
    getLowestPriceTiersPrice
} from 'SourceUtil/Price/Price';
import getStore from 'Util/Store';

export {
    formatCurrency,
    // formatPrice,
    calculateFinalPrice,
    calculateTierDiscountOverSpecialPrice,
    roundPrice,
    getLowestPriceTiersPrice
};

/**
 * Override: Default currency to EUR
 * @namespace PeggysagePwa/Util/Price/formatPrice
 */
export const formatPrice = (price, currency = 'EUR') => {
    const state = getStore().getState();
    const { code: configLanguage } = state.ConfigReducer;

    const navigatorLanguage = navigator.languages ? navigator.languages[0] : navigator.language;

    const language = configLanguage || navigatorLanguage;

    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};
