import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace PeggysagePwa/Component/FieldSelect/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace PeggysagePwa/Component/FieldSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PeggysagePwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    static propTypes = {
        ...SourceFieldSelectContainer.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    state = {
        ...this.state,
        selectedOptionValue: ''
    };

    /**
     * Override: call fn to initially set selected option value
     */
    componentDidMount() {
        super.componentDidMount();

        this.isSelectedOptionAvailable();
    }

    /**
     * Override: set selected option value
     */
    isSelectedOptionAvailable() {
        const options = this.getOptions();
        const selectedOptionIndex = this.fieldRef.options.selectedIndex;
        const selectedOption = options[selectedOptionIndex];
        const isAvailable = selectedOption && selectedOption.isAvailable !== false;

        this.setState({
            selectedOptionIndex,
            selectedOptionValue: this.fieldRef.value,
            isSelectedOptionAvailable: isAvailable
        });
    }

    /**
     * Override
     * @returns {{isMobile}}
     */
    containerProps() {
        const { isMobile } = this.props;
        const { selectedOptionValue } = this.state;

        return {
            ...super.containerProps(),
            isMobile,
            groupedOptions: this.getGroupedOptions(),
            selectedOptionValue
        };
    }

    /**
     * Override: do not hide select options when click on group label (fake optgroup)
     * @param event
     */
    handleSelectExpand(event) {
        if (!this.isSelectDisabled()) {
            if (!event) {
                this.setState({ isExpanded: false });

                return;
            }

            const clickedItem = event.target;

            if (clickedItem === this.fieldRef.parentElement) {
                this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
            } else if ((clickedItem.localName === 'li' || clickedItem.parentElement.localName === 'li')
                && !clickedItem.classList.contains('FieldSelect-Option_isGroup')) {
                this.setState({ isExpanded: false });
            }
        }
        this.handleDropdownOpenDirection();
    }

    getGroupedOptions() {
        const options = this.getOptions();

        return options.reduce((acc, option) => {
            const { group, ...props } = option;

            if (!group) {
                if (!acc.default) {
                    acc.default = {
                        options: []
                    };
                }
                acc.default.options.push(props);

                return acc;
            }

            if (!acc[group.id]) {
                acc[group.id] = {
                    id: group.id,
                    label: group.label,
                    options: []
                };
            }

            acc[group.id].options.push(props);

            return acc;
        }, []);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldSelectContainer);
