/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const MAX_NUMBER_FILTERS = 1000;
export const MAX_NUMBER_POSITION = 10;
export const GOOGLEMERCHANT_TRACKER = 'GOOGLEMERCHANT_TRACKER';
