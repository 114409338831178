/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    CUSTOMER_STEP
} from 'Route/Checkout/Checkout.config';
import {
    CheckoutStepType as SourceCheckoutStepType,
    PaymentMethodsType,
    PaymentMethodType,
    ShippingMethodsType,
    ShippingMethodType,
    StoreType
} from 'SourceType/Checkout.type';

export {
    PaymentMethodType, PaymentMethodsType, ShippingMethodType, ShippingMethodsType, StoreType
};

export const CheckoutStepType = PropTypes.oneOfType([
    SourceCheckoutStepType,
    PropTypes.oneOf([CUSTOMER_STEP])
]);
