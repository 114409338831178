import {
    APPEND_PAGE,
    appendPage,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS,
    updateLoadStatus,
    updatePageLoadingStatus,
    updateProductListItems
} from 'SourceStore/ProductList/ProductList.action';

export {
    APPEND_PAGE,
    appendPage,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS,
    updateLoadStatus,
    updatePageLoadingStatus,
    updateProductListItems
};

export const RESET_PRODUCT_LIST_ITEMS = 'RESET_PRODUCT_LIST_ITEMS';

/** @namespace PeggysagePwa/Store/ProductList/Action/resetProductListItems */
export const resetProductListItems = () => ({
    type: RESET_PRODUCT_LIST_ITEMS
});
