/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * GoogleMerchant scripts
 */
export class Scripts {
    static getScript() {
        return `(function(w,d,s){var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s);j.async=true;j.src=
        'https://apis.google.com/js/platform.js';f.parentNode.insertBefore(j,f);
        })(window,document,'script');`;
    }
}

export default Scripts;
