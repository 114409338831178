/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    ClickOutside as SourceClickOutside
} from 'SourceComponent/ClickOutside/ClickOutside.component';

/** @namespace PeggysagePwa/Component/ClickOutside/Component */
export class ClickOutsideComponent extends SourceClickOutside {
    /**
     * Override: pass event to callback function
     * @param event
     */
    handleClick(event) {
        const { target } = event;
        const { onClick } = this.props;

        if (this.childrenRefs.every(
            (ref) => {
                const elementRef = ref.current?.overlayRef?.current || ref.current;

                return !elementRef.contains(target);
            }
        )) {
            onClick(event);
        }
    }
}

export default ClickOutsideComponent;
