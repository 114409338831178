import {
    CLEAR_WISHLIST,
    clearWishlist,
    REMOVE_ITEM_FROM_WISHLIST,
    removeItemFromWishlist,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS,
    updateAllProductsInWishlist,
    updateIsLoading,
    updateItemOptions
} from 'SourceStore/Wishlist/Wishlist.action';

export {
    CLEAR_WISHLIST,
    clearWishlist,
    REMOVE_ITEM_FROM_WISHLIST,
    removeItemFromWishlist,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS,
    updateAllProductsInWishlist,
    updateIsLoading,
    updateItemOptions
};

export const UPDATE_WISHLIST = 'UPDATE_WISHLIST';

/** @namespace PeggysagePwa/Store/Wishlist/Action/updateWishlist */
export const updateWishlist = (wishlist) => ({
    type: UPDATE_WISHLIST,
    wishlist
});
