/* eslint-disable simple-import-sort/sort */
import PropTypes from 'prop-types';

import { DocumentType } from 'Type/Document.type';
import { FaqType } from 'Type/FaqCategory.type';
import { PriceType } from 'Type/Price.type';

import {
    AttributeType,
    AttributesType,
    // FilterAttributeType as SourceFilterAttributeType,
    BreadcrumbsType,
    ImageType,
    CategoryType,
    CategoriesType,
    ThumbnailType,
    MediaItemType,
    MediaType,
    ProductLinksType,
    ReviewSummaryShape,
    RatingVoteType,
    ReviewsType,
    DescriptionType,
    StockItemType,
    OptionValueType,
    OptionsType,
    ValueType,
    PriceTierType,
    CustomizableOptionShape,
    CustomizableOptionType,
    InputOptionType,
    FileOptionType,
    CustomizableOptionsType,
    ItemShape as SourceItemShape,
    ItemType,
    ItemsType,
    PagesType,
    // ItemOptionsType,
    // ProductItemsType,
    ProductType as SourceProductType,
    DownloadableSamplesType,
    PriceConfiguration,
    MagentoProductType,
    ProductCardPropsType,
    OptionsListType,
    LinkedProductsType,
    QuantityType
} from 'SourceType/ProductList.type';

export {
    AttributeType,
    AttributesType,
    // FilterAttributeType,
    BreadcrumbsType,
    ImageType,
    CategoryType,
    CategoriesType,
    ThumbnailType,
    MediaItemType,
    MediaType,
    ProductLinksType,
    ReviewSummaryShape,
    RatingVoteType,
    ReviewsType,
    DescriptionType,
    StockItemType,
    OptionValueType,
    OptionsType,
    ValueType,
    PriceTierType,
    CustomizableOptionShape,
    CustomizableOptionType,
    InputOptionType,
    FileOptionType,
    CustomizableOptionsType,
    // ItemShape,
    ItemType,
    ItemsType,
    PagesType,
    // ItemOptionsType,
    // ProductItemsType,
    // ProductType,
    DownloadableSamplesType,
    PriceConfiguration,
    MagentoProductType,
    ProductCardPropsType,
    OptionsListType,
    LinkedProductsType,
    QuantityType
};

export const ProductTagType = PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.bool,
    label: PropTypes.string
});

export const ProductTagsType = PropTypes.arrayOf(ProductTagType);

export const ItemShape = {
    ...SourceItemShape,
    variation_products_count: PropTypes.number,
    tags: ProductTagsType,
    produit_pro: PropTypes.number,
    produit_etudiant: PropTypes.number
};

export const ProductVariationsItemType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sku: PropTypes.string,
    url_key: PropTypes.string,
    stock_status: PropTypes.string,
    colors: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string
        })
    ),
    variation_thumbnail_url: PropTypes.string
});

export const ProductVariationsType = PropTypes.arrayOf(ProductVariationsItemType);

export const ProductType = PropTypes.shape({
    ...SourceProductType,
    regroupement_produit: PropTypes.string,
    variation_products: ProductVariationsType,
    related_tutorials: PropTypes.arrayOf(
        PropTypes.shape({
            identifier: PropTypes.string,
            title: PropTypes.string,
            content_image: PropTypes.string
        })
    ),
    related_faqs: PropTypes.arrayOf(FaqType),
    inci: PropTypes.string,
    attribute_set_id: PropTypes.number,
    contenance_label: PropTypes.string,
    conseils_utilisation: PropTypes.string,
    precautions: PropTypes.string,
    afficher_produit_reserve_pro: PropTypes.bool,
    info_tri: PropTypes.objectOf(PropTypes.string),
    qce: PropTypes.string,
    actifs: PropTypes.string,
    efficacite_prouvee: PropTypes.string,
    frontend_attributes: AttributesType,
    product_type_for_rating: PropTypes.string,
    collection_text: PropTypes.objectOf(PropTypes.string)
});

/**
 * Override: add image
 */
export const FilterAttributeType = PropTypes.shape({
    attribute_code: PropTypes.string,
    attribute_label: PropTypes.string,
    attribute_position: PropTypes.number,
    attribute_values: PropTypes.arrayOf(PropTypes.string),
    attribute_type: PropTypes.string,
    attribute_options: PropTypes.objectOf(PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
        value_string: PropTypes.string,
        swatch_data: PropTypes.shape({ type: PropTypes.string, value: PropTypes.string }),
        image: PropTypes.string // NEW
    })),
    is_boolean: PropTypes.bool,
    has_swatch: PropTypes.bool
});

export const ItemOptionProductType = PropTypes.shape({
    name: PropTypes.string,
    stock_status: PropTypes.string,
    price_range: PriceType,
    // NEW FROM HERE
    uid: PropTypes.string,
    id: PropTypes.number,
    sku: PropTypes.string,
    type_id: PropTypes.string,
    url: PropTypes.string,
    salable_qty: PropTypes.number,
    thumbnail: ImageType,
    stock_item: StockItemType,
    review_summary: PropTypes.shape(ReviewSummaryShape),
    inci: PropTypes.string,
    top_category: PropTypes.shape({
        uid: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string
    }),
    colors: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string
        })
    ),
    documents: PropTypes.arrayOf(DocumentType)
});

/**
 * Override: change product vars
 */
export const ItemOptionsType = PropTypes.arrayOf(
    PropTypes.shape({
        can_change_quantity: PropTypes.bool,
        id: PropTypes.number,
        is_default: PropTypes.bool,
        label: PropTypes.string,
        position: PropTypes.number,
        price: PropTypes.number,
        price_type: PropTypes.string,
        quantity: PropTypes.number,
        uid: PropTypes.string,
        product: ItemOptionProductType,
        regularOptionPrice: PropTypes.number,
        regularOptionPriceExclTax: PropTypes.number,
        finalOptionPrice: PropTypes.number,
        finalOptionPriceExclTax: PropTypes.number
    })
);

export const ProductItemType = PropTypes.shape({
    option_id: PropTypes.number,
    options: ItemOptionsType,
    position: PropTypes.number,
    required: PropTypes.bool,
    sku: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
});

/**
 * Override: add sub type
 */
export const ProductItemsType = PropTypes.arrayOf(ProductItemType);
