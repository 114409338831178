import { Field, prepareQuery } from 'SourceUtil/Query';
import { executeGet, executePost } from 'Util/Request/Request';

/** @namespace PeggysagePwa/Util/Request/Query/fetchQuery */
// eslint-disable-next-line import/prefer-default-export
export const fetchQuery = (rawQueries, signal) => {
    const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

    return executePost(prepareQuery(queries, true), signal);
};
/** @namespace PeggysagePwa/Util/Request/Query/fetchQueryGet */
export const fetchQueryGet = (rawQueries, name, cacheLifetime, signal) => {
    const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

    return executeGet(prepareQuery(queries, true), name, cacheLifetime, signal);
};
