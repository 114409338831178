import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const TOKEN_KEY = 'external_payment_token';

/** @namespace PeggysagePwa/Util/PaymentTokenPersistence/Index/getPaymentToken */
export const getPaymentToken = () => BrowserDatabase.getItem(TOKEN_KEY);

/** @namespace PeggysagePwa/Util/PaymentTokenPersistence/Index/setPaymentToken */
export const setPaymentToken = (token) => {
    if (token) {
        BrowserDatabase.setItem(token, TOKEN_KEY, ONE_MONTH_IN_SECONDS);
    } else {
        BrowserDatabase.deleteItem(TOKEN_KEY);
    }
};
