/* eslint-disable spaced-comment */
import PropTypes from 'prop-types';
import React from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import ExpandableContent from 'Component/ExpandableContent';
import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import ProductBundleOptionDetails from 'Component/ProductBundleOptionDetails';
import {
    ProductBundleOption as SourceProductBundleOption
} from 'SourceComponent/ProductBundleOption/ProductBundleOption.component';
import { ProductType } from 'Type/ProductList.type';
import {
    DEFAULT_MAX_PRODUCTS, DEFAULT_MIN_PRODUCTS,
    getMaxQuantity, getMinQuantity, getProductInStock
} from 'Util/Product/Extract';
import { getEncodedBundleUid } from 'Util/Product/Transform';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import { BUNDLE_TYPE } from './ProductBundleOption.config';

import './ProductBundleOption.override.style';

/** @namespace PeggysagePwa/Component/ProductBundleOption/Component */
export class ProductBundleOptionComponent extends SourceProductBundleOption {
    static propTypes = {
        ...SourceProductBundleOption.propTypes,
        unavailableSectionRef: PropTypes.oneOfType([
            // Either a function
            PropTypes.func,
            // Or the instance of a DOM native element (see the note about SSR)
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired,
        scrollToUnavailableProducts: PropTypes.func.isRequired,
        onInputChange: PropTypes.func.isRequired,
        hasDifferentPrices: PropTypes.func.isRequired,
        onClickFilter: PropTypes.func.isRequired,
        onClickFilterToggle: PropTypes.func.isRequired,
        optionId: PropTypes.number.isRequired,
        product: ProductType.isRequired,
        selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        currentOptionId: PropTypes.number.isRequired,
        filters: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            code: PropTypes.string,
            label: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.shape({
                active: PropTypes.bool,
                count: PropTypes.number,
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            })),
            isExpanded: PropTypes.bool
        })).isRequired,
        filteredValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
        filteredValuesFlat: PropTypes.arrayOf(PropTypes.number).isRequired
    };

    renderMap = {
        ...this.renderMap,
        [BUNDLE_TYPE.couleur]: this.renderCouleurValues.bind(this),
        [BUNDLE_TYPE.kit]: this.renderKitValues.bind(this),
        [BUNDLE_TYPE.produit]: this.renderProduitValues.bind(this),
        [BUNDLE_TYPE.obligatoire]: this.renderObligatoireValues.bind(this),
        [BUNDLE_TYPE.gift]: this.renderObligatoireValues.bind(this)
    };

    /**
     * Override: fix field value
     * @param uid
     * @param quantity
     * @param product
     */
    renderQuantityChange(uid, quantity, product = null) {
        const min = !product ? DEFAULT_MIN_PRODUCTS : getMinQuantity(product);
        const max = !product ? DEFAULT_MAX_PRODUCTS : getMaxQuantity(product);
        // eslint-disable-next-line no-nested-ternary
        const rangedQty = quantity < min ? min : quantity > max ? max : quantity;

        if (rangedQty !== quantity) {
            this.setQuantity(uid, rangedQty);
        }

        return (
            <Field
              type={ FIELD_TYPE.numberWithControls }
              attr={ {
                  id: `item_qty_${uid}`,
                  name: `item_qty_${uid}`,
                  defaultValue: rangedQty,
                  // value: rangedQty,
                  min,
                  max
              } }
              value={ rangedQty }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                  isRequired: true,
                  range: {
                      min,
                      max
                  }
              } }
              events={ { onChange: this.setQuantity.bind(this, uid) } }
              validateOn={ ['onChange'] }
            />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _isOptionVisible(option) {
        const { product } = option;
        const { filters, filteredValues, filteredValuesFlat } = this.props;

        const filterKeys = Object.keys(filteredValues);

        if (!filterKeys.length) {
            return true;
        }

        const productOptions = {};

        filterKeys.forEach((key) => {
            // Get product field name (code) from filters array
            const code = filters.find((filter) => filter.key === key)?.code || null;

            if (code && product[code]) {
                const options = !Array.isArray(product[code]) ? [product[code]] : product[code];
                productOptions[key] = options?.map((opt) => opt.value) || [];
            } else {
                productOptions[key] = [];
            }
        });

        // Single array with filter values
        const productOptionsFlat = Object.values(productOptions).flat();

        // Option is visible if product options contains all filtered values
        return filteredValuesFlat.every((value) => productOptionsFlat.includes(value));
    }

    //#region RADIO
    renderRadioCustom(name, option, type, showPrice = true) {
        const {
            uid,
            can_change_quantity: canChangeQuantity,
            quantity: defaultQuantity = 1,
            product,
            is_default
        } = option;

        const {
            // updateSelectedValues,
            onInputChange,
            quantity: { [uid]: quantity = defaultQuantity },
            product: { dynamic_price: hasDynamicPrice },
            filteredValues,
            title: parentOptionTitle,
            optionId: parentOptionId,
            selectedOptions
        } = this.props;

        if (!product) {
            return '';
        }
        const label = this.getLabel(option);
        const stock = getProductInStock(product);
        const topCategory = product.top_category?.id || 0;

        const isSelected = [uid, getEncodedBundleUid(uid, quantity)].some((uid) => selectedOptions.includes(uid));

        const isVisible = this._isOptionVisible(option);

        const parentOption = {
            title: parentOptionTitle,
            option_id: parentOptionId
        };

        return (
            <div
              block="ProductBundleItem"
              elem="Radio"
              mods={ {
                  customQuantity: canChangeQuantity,
                  isChecked: isSelected,
                  isHidden: !isVisible
              } }
              key={ uid }
            >
                <Field
                  type={ FIELD_TYPE.radio }
                  label={ label }
                  attr={ {
                      id: `option-${ uid }`,
                      value: canChangeQuantity ? getEncodedBundleUid(uid, quantity) : uid,
                      name: `option-${ name }`,
                      defaultChecked: (is_default || isSelected) && stock,
                      disabled: !stock,
                      category: topCategory
                  } }
                  events={ {
                      onChange: onInputChange
                  } }
                  validationRule={ {
                      match: this.getError.bind(this, quantity, stock)
                  } }
                  validateOn={ ['onChange'] }
                />
                <ProductBundleOptionDetails
                  option={ option }
                  bundleType={ type }
                  hasDynamicPrice={ hasDynamicPrice && showPrice }
                  isSelected={ isSelected }
                  parentOption={ parentOption }
                  filteredValues={ filteredValues }
                />
                { canChangeQuantity && isSelected && this.renderQuantityChange(uid, quantity, product) }
            </div>
        );
    }
    //#endregion

    renderFilterOption(filterKey, option) {
        const { label, value, count } = option;
        const { filteredValues, onClickFilter } = this.props;

        const isActive = filteredValues[filterKey] ? filteredValues[filterKey].includes(value) : false;

        return (
            <button
              block="ProductBundleItem"
              elem="Filter"
              mods={ { isActive } }
              data-value={ `${ filterKey }-${ value }` }
              onClick={ onClickFilter }
              key={ value }
            >
                { `${ label } (${ count })` }
            </button>
        );
    }

    renderFilter(filter) {
        const {
            filters, onClickFilterToggle
        } = this.props;
        const {
            key, label, options = [], isExpanded
        } = filter;

        return (
            <div
              block="ProductBundleItem"
              elem="FilterGroup"
              mods={ { hasToggle: filters.length > 1, isExpanded } }
              key={ key }
            >
                <button
                  type="button"
                  block="ProductBundleItem"
                  elem="FilterGroupToggle"
                  data-key={ key }
                  onClick={ onClickFilterToggle }
                >
                    { label }
                    <ChevronIcon direction={ isExpanded ? LEFT : RIGHT } />
                </button>
                <div block="ProductBundleItem" elem="FilterGroupOptions">
                    { options.map(this.renderFilterOption.bind(this, key)) }
                </div>
            </div>
        );
    }

    renderFilterAll() {
        const {
            options, filteredValuesFlat, onClickFilter
        } = this.props;

        const isActive = !filteredValuesFlat.length;

        return (
            <button
              block="ProductBundleItem"
              elem="Filter"
              mods={ { isActive, isAll: true } }
              data-value=""
              onClick={ onClickFilter }
              key="filter-all"
            >
                { `${ __('All') } (${ options.length })` }
            </button>
        );
    }

    renderFilters() {
        const { filters } = this.props;

        if (!filters.length) {
            return null;
        }

        return (
            <div block="ProductBundleItem" elem="Filters">
                <div block="ProductBundleItem" elem="FiltersInner">
                    { this.renderFilterAll() }
                    { filters.map(this.renderFilter.bind(this)) }
                </div>
            </div>
        );
    }

    //#region kits
    renderKitValues(options) {
        const {
            isRequired, uid, type, hasDifferentPrices
        } = this.props;

        const showPrice = hasDifferentPrices(options);

        return (
            <>
                { this.renderFilters() }
                <FieldGroup
                  validationRule={ {
                      isRequired,
                      selector: '[type="radio"]'
                  } }
                  validateOn={ ['onChange'] }
                  mods={ { type } }
                >
                    { options.map((option) => this.renderRadioCustom(uid, option, type, showPrice)) }
                </FieldGroup>
            </>
        );
    }
    //#endregion

    //#region couleur
    renderCouleurValues(options) {
        return this.renderKitValues(options);
    }
    //#endregion

    //#region produit
    renderProduitValues(options) {
        const {
            isRequired, uid, type, hasDifferentPrices
        } = this.props;

        const optionsByCategories = options.reduce((result, option) => {
            const _result = result;
            const { product: { top_category: category } } = option;
            if (category) {
                const categoryIndex = _result.findIndex((item) => item.category.id === category.id);
                if (categoryIndex === -1) {
                    _result.push({ category, options: [option] });
                } else {
                    _result[categoryIndex].options.push(option);
                }
            }

            return _result;
        }, []);

        // Multiple categories => Toggles
        if (optionsByCategories.length > 1) {
            return (
                <FieldGroup
                  validationRule={ {
                      isRequired,
                      selector: '[type="radio"]'
                  } }
                  validateOn={ ['onChange'] }
                  mods={ { type } }
                >
                    { optionsByCategories.map((item) => {
                        const { category, options } = item;

                        const subheading = options.length > 1 ? __('%s products', options.length) : __('1 product');

                        const showPrice = hasDifferentPrices(options);

                        return (
                            <ExpandableContent
                              key={ category.uid }
                              heading={ category.name }
                              subHeading={ subheading }
                              mix={ { block: 'ProductBundleItem', elem: 'Category' } }
                              device={ { isMobile: true } }
                            >
                                { options.map((option) => this.renderRadioCustom(uid, option, type, showPrice)) }
                            </ExpandableContent>
                        );
                    }) }
                </FieldGroup>
            );
        }

        const showPrice = hasDifferentPrices(options);

        // Single categories => no toggle
        return (
            <FieldGroup
              validationRule={ {
                  isRequired,
                  selector: '[type="radio"]'
              } }
              validateOn={ ['onChange'] }
              mods={ { type } }
            >
                { options.map((option) => this.renderRadioCustom(uid, option, type, showPrice)) }
            </FieldGroup>
        );
    }
    //#endregion

    //#region obligatoire
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _renderObligatoire(option, isAvailableOnly) {
        const {
            uid,
            can_change_quantity: canChangeQuantity,
            product,
            quantity: defaultQuantity = 1
            // is_default
        } = option;

        const {
            onInputChange,
            // updateSelectedValues,
            quantity: { [uid]: quantity = defaultQuantity },
            product: { dynamic_price: hasDynamicPrice },
            title: parentOptionTitle,
            optionId: parentOptionId,
            type
        } = this.props;

        if (!product) {
            return '';
        }
        const label = this.getLabel(option);
        const min = getMinQuantity(product);
        const max = getMaxQuantity(product);
        const stock = getProductInStock(product);
        const topCategory = product.top_category?.id || 0;

        const parentOption = {
            title: parentOptionTitle,
            option_id: parentOptionId
        };

        if (stock === isAvailableOnly) {
            return (
                <div
                  block="ProductBundleItem"
                  elem="Checkbox"
                  mods={ { customQuantity: canChangeQuantity } }
                  key={ uid }
                >
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      label={ label }
                      attr={ {
                          id: `option-${uid}`,
                          value: canChangeQuantity ? getEncodedBundleUid(uid, quantity) : uid,
                          name: `option-${uid}`,
                          defaultChecked: stock,
                          disabled: !stock,
                          category: topCategory
                      } }
                      events={ {
                          onChange: onInputChange
                      } }
                      validationRule={ {
                          match: this.getError.bind(this, quantity, stock, min, max)
                      } }
                      validateOn={ ['onChange'] }
                      // isDisabled
                    />
                    <ProductBundleOptionDetails
                      option={ option }
                      bundleType={ type }
                      hasDynamicPrice={ hasDynamicPrice }
                      parentOption={ parentOption }
                    />
                    { canChangeQuantity && this.renderQuantityChange(uid, quantity, product) }
                </div>
            );
        }

        return null;
    }

    renderObligatoireNonDisponiblesMsg(unavailableCount) {
        if (!unavailableCount) {
            return null;
        }

        const content = unavailableCount > 1 ? __('%s produits indisponibles', unavailableCount)
            : __('1 produit indisponible');

        return (
            <p block="ProductBundleItem" elem="UnavailableTitle">
                { content }
            </p>
        );
    }

    renderObligatoireNonDisponiblesLink(unavailableCount) {
        const { scrollToUnavailableProducts } = this.props;

        if (!unavailableCount) {
            return null;
        }

        return (
            <div block="ProductBundleItem" elem="Unavailable">
                { this.renderObligatoireNonDisponiblesMsg(unavailableCount) }
                <button
                  type="button"
                  block="ProductBundleItem"
                  elem="UnavailableLink"
                  mix={ { block: 'Button', mods: { likeLink: true } } }
                  onClick={ scrollToUnavailableProducts }
                >
                    { __('Voir les produits') }
                </button>
            </div>
        );
    }

    renderObligatoireNonDisponibles(options) {
        const { unavailableSectionRef } = this.props;

        return (
            <div
              block="ProductBundleItem"
              elem="Section"
              mods={ { type: 'unavailable' } }
              ref={ unavailableSectionRef }
            >
                <div block="ProductBundleItem" elem="SectionHeader">
                    <div block="ProductBundleItem" elem="SectionTitle">
                        { __('Produits indisponibles') }
                    </div>
                    <div block="ProductBundleItem" elem="SectionMessage">
                        { /* eslint-disable-next-line max-len */ }
                        { __('Ces produits ne sont pas disponibles pour le moment mais vous pouvez commander le reste du kit.') }
                    </div>
                </div>
                <div block="ProductBundleItem" elem="SectionContent">
                    { options.map((option) => this._renderObligatoire(option, false)) }
                </div>
            </div>
        );
    }

    renderObligatoire(options) {
        return (
            <div block="ProductBundleItem" elem="Section" mods={ { type: 'available' } }>
                <div block="ProductBundleItem" elem="SectionContent">
                    { options.map((option) => this._renderObligatoire(option, true)) }
                </div>
            </div>
        );
    }

    renderObligatoireValues(options) {
        const { isRequired, type } = this.props;

        const unavailableCount = options.filter((option) => !getProductInStock(option.product)).length;

        return (
            <FieldGroup
              validationRule={ {
                  isRequired,
                  selector: '[type="checkbox"]'
              } }
              validateOn={ ['onChange'] }
              mods={ { type } }
            >
                { !!unavailableCount && this.renderObligatoireNonDisponiblesLink(unavailableCount) }
                { this.renderObligatoire(options) }
                { !!unavailableCount && this.renderObligatoireNonDisponibles(options) }
            </FieldGroup>
        );
    }
    //#endregion

    /**
     * Override
     * @returns {JSX.Element|null}
     */
    render() {
        const {
            optionId,
            // title,
            options,
            type,
            currentOptionId
        } = this.props;
        const render = this.renderMap[type];

        if (!render) {
            return null;
        }

        return (
            <div
              block="ProductBundleItem"
              elem="Wrapper"
              mods={ { isVisible: optionId === currentOptionId } }
            >
                { /* title && this.renderOptionGroupTitle(title) */ }
                { options && render(options) }
            </div>
        );
    }
}

export default ProductBundleOptionComponent;
