import { isSignedIn, refreshAuthorizationToken } from 'SourceUtil/Auth';
import { refreshUid } from 'SourceUtil/Compare';
import {
    appendTokenToHeaders, checkForErrors, getGraphqlEndpoint, handleConnectionError
} from 'SourceUtil/Request/Request';

export * from 'SourceUtil/Request/Request';

/**
 *
 * @param {String} graphQlURI
 * @param {String} queryObject
 * @param {String} variables
 * @param {AbortSignal} signal
 * @returns {Promise<Response>}
 * @namespace PeggysagePwa/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables, signal) => fetch(graphQlURI,
    {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }),
        signal
    });

/**
 * Parse response and check wether it contains errors
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace PeggysagePwa/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace PeggysagePwa/Util/Request/parseResponse/Promise/promise/then */
        (res) => res.json().then(
            /** @namespace PeggysagePwa/Util/Request/parseResponse/Promise/promise/then/json/then/resolve */
            (res) => resolve(checkForErrors(res)),
            /** @namespace PeggysagePwa/Util/Request/parseResponse/Promise/promise/then/json/then/catch */
            () => {
                handleConnectionError('Can not transform JSON!');
                return reject();
            }
        ),
        /** @namespace PeggysagePwa/Util/Request/parseResponse/Promise/promise/then/catch */
        (err) => {
            if (!(err instanceof DOMException && err.name === 'AbortError')) {
                handleConnectionError('Can not establish connection!');
            }

            return reject(err);
        }
    );
});

/**
 * Make POST request to endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param {AbortSignal} signal from an AbortController
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace PeggysagePwa/Util/Request/executePost */
export const executePost = (queryObject, signal) => {
    const { query, variables } = queryObject;

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables, signal));
};
