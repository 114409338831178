import PropTypes from 'prop-types';

import CartToWishlistIcon from 'Component/CartToWishlistIcon';
import HeartIcon from 'Component/HeartIcon';
import {
    ProductWishlistButton as SourceProductWishlistButton
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace PeggysagePwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    static propTypes = {
        ...SourceProductWishlistButton.propTypes,
        isInCart: PropTypes.bool.isRequired,
        isWishListToggle: PropTypes.bool.isRequired
    };

    renderButtonIcon() {
        const { isInWishlist, isInCart, isWishListToggle } = this.props;

        if (isInCart) {
            return <CartToWishlistIcon />;
        }

        return (
            <HeartIcon isActive={ isInWishlist } isLoading={ isWishListToggle } />
        );
    }

    /**
     * Override: allow guests, different message in cart
     * @returns {*}
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getTitle() {
        const { isInWishlist, isInCart } = this.props;

        if (isInCart) {
            return __('Move to Wishlist');
        }

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        return __('Add to Wishlist');
    }

    /**
     * Override: add rendering function for icon
     * @returns {JSX.Element}
     */
    renderButton() {
        const {
            isInWishlist, isDisabled, isInCart, mix
        } = this.props;

        if (isInCart && isInWishlist) {
            return null;
        }

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled, isInCart } }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                { this.renderButtonIcon() }
            </button>
        );
    }
}

export default ProductWishlistButtonComponent;
