import {
    decodeString,
    noopFn
} from 'SourceUtil/Common';

export {
    decodeString,
    noopFn
};

/**
 * Unescape HTML entities
 * @namespace PeggysagePwa/Util/Common/Index/decodeHtml */
export const decodeHtml = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
};

/**
 * @namespace PeggysagePwa/Util/Common/Index/formatBytes */
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) {
        return '0 octets'; // 0 Bytes
    }

    // eslint-disable-next-line no-magic-numbers
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const sizes = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${ parseFloat((bytes / k ** i).toFixed(dm)) } ${ sizes[i] }`;
};

/**
 * @namespace PeggysagePwa/Util/Common/Index/addInsecableSpaceBeforePunctuation */
export const addInsecableSpaceBeforePunctuation = (string) => (
    string.replace(/(\s[?|!|;|:])/g, (str) => str.replace(/\s/g, '&nbsp;'))
);
