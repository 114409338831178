/* eslint-disable max-lines */
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CategoryConfigurableAttributesContainer as SourceCategoryConfigurableAttributesContainer,
    mapDispatchToProps as sourceMapDispatchToProps
    // mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CategoryConfigurableAttributes/CategoryConfigurableAttributes.container';
// eslint-disable-next-line max-len
import ProductConfigurableAttributesContainer from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

/** @namespace PeggysagePwa/Component/CategoryConfigurableAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // ...sourceMapStateToProps(state)
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
    showProductCount: state.ConfigReducer.layered_navigation_product_count_enabled,
    childrenCategories: state.CategoryReducer?.category?.children || []
});

/** @namespace PeggysagePwa/Component/CategoryConfigurableAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/CategoryConfigurableAttributes/Container */
export class CategoryConfigurableAttributesContainer extends SourceCategoryConfigurableAttributesContainer {
    static propTypes = {
        ...ProductConfigurableAttributesContainer.propTypes,
        groupId: PropTypes.string,
        isContentExpanded: PropTypes.bool,
        isArrow: PropTypes.bool
    };

    static defaultProps = {
        ...ProductConfigurableAttributesContainer.defaultProps,
        groupId: '',
        isContentExpanded: false,
        isArrow: false
    };

    state = {
        priceRangeMinValue: 0,
        priceRangeMaxValue: 0,
        priceRangeStepValue: 1,
        priceRangeStartValue: null,
        priceRangeEndValue: null,
        priceFilterRanges: []
    };

    containerFunctions = {
        ...this.containerFunctions,
        onPriceRangeSliderChange: this.onPriceRangeSliderChange.bind(this),
        onPriceRangeSliderChangeCommitted: this.onPriceRangeSliderChangeCommitted.bind(this),
        priceRangeSliderConstraint: this.priceRangeSliderConstraint.bind(this),
        onPriceRangeSliderInputChange: this.onPriceRangeSliderInputChange.bind(this),
        onPriceRangeSliderResetButtonClick: this.onPriceRangeSliderResetButtonClick.bind(this)
    };

    // eslint-disable-next-line
    debouncedUpdatePriceFilter = debounce(this.updatePriceFilter, 500);

    componentDidMount() {
        this.updatePriceRangeMinMax();
    }

    componentDidUpdate(prevProps) {
        const {
            configurable_options: { price: priceOption },
            parameters: { price: priceParameter }
        } = this.props;
        const {
            configurable_options: { price: prevPriceOption },
            parameters: { price: prevPriceParameter }
        } = prevProps;

        if (priceOption !== prevPriceOption) {
            this.updatePriceRangeMinMax();
        }

        const priceParam = priceParameter ? priceParameter[0] : null;
        const prevPriceParam = prevPriceParameter ? prevPriceParameter[0] : null;

        if (priceParam !== prevPriceParam) {
            if (!priceParam) {
                // Reset manually set values
                this.setState({
                    priceRangeStartValue: null,
                    priceRangeEndValue: null
                });
            }
        }
    }

    setPriceRangeValuesFromParameters() {
        const { parameters: { price: priceParameter } } = this.props;
        const { priceRangeMinValue, priceRangeMaxValue } = this.state;

        if (priceParameter && priceParameter.length) {
            const [startValue, endValue] = priceParameter[0].split('_');

            const start = +startValue < priceRangeMinValue ? priceRangeMinValue : +startValue;
            const end = +endValue > priceRangeMaxValue ? priceRangeMaxValue : +endValue;

            this.setState({
                priceRangeStartValue: start,
                priceRangeEndValue: end
            });
        }
    }

    updatePriceRangeMinMax() {
        const { configurable_options: { price: priceOption } } = this.props;
        const { priceRangeMaxValue, priceRangeStartValue, priceRangeEndValue } = this.state;

        // Set first time only to have the max
        if (priceRangeMaxValue) {
            // return;
        }

        if (!priceOption) {
            return;
        }

        const { attribute_options, attribute_values } = priceOption;
        if (attribute_options) {
            if (attribute_options['*_*']) {
                return;
            }

            // Store the ranges to calculate slider constraints

            const priceFilterRanges = attribute_values.map((values) => {
                const [min, max] = values.split('_');

                return [+min, +max];
            });

            // Calculation of the min and max ranges

            const [minValue] = attribute_values[0].split('_');
            const [, maxValue] = attribute_values[attribute_values.length - 1].split('_');

            const min = Math.round(+minValue);

            // eslint-disable-next-line no-magic-numbers
            const max = maxValue === '*' ? 500 : Math.round(+maxValue);

            /* eslint-disable no-magic-numbers */
            // eslint-disable-next-line fp/no-let
            /* let step = 1;
            switch (true) {
            case (max > 200):
                step = 10;
                break;
            case (max > 50):
                step = 5;
                break;
            default:
            } */
            /* eslint-enable no-magic-numbers */

            const step = Math.round(priceFilterRanges[0][1] - priceFilterRanges[0][0]);

            const newState = {
                priceRangeMinValue: min,
                priceRangeMaxValue: max,
                priceRangeStepValue: step,
                priceFilterRanges
            };

            // Security
            if (priceRangeStartValue !== null && priceRangeStartValue < min) {
                newState.priceRangeStartValue = min;
            }
            if (priceRangeEndValue !== null && priceRangeEndValue > max) {
                newState.priceRangeEndValue = max;
            }

            this.setState(newState, this.setPriceRangeValuesFromParameters);
        }
    }

    containerProps() {
        const {
            groupId,
            isContentExpanded,
            isArrow
        } = this.props;
        const {
            priceRangeMinValue,
            priceRangeMaxValue,
            priceRangeStepValue,
            priceRangeStartValue,
            priceRangeEndValue
        } = this.state;

        return {
            ...super.containerProps(),
            groupId,
            isContentExpanded,
            isArrow,
            priceRangeMinValue,
            priceRangeMaxValue,
            priceRangeStepValue,
            priceRangeStartValue,
            priceRangeEndValue
        };
    }

    updatePriceFilter() {
        const { priceRangeStartValue, priceRangeEndValue } = this.state;

        this.handleOptionClick({
            attribute_code: 'price',
            attribute_value: `${priceRangeStartValue}_${priceRangeEndValue}`
        });
    }

    onPriceRangeSliderChange([start, end]) {
        const { priceRangeStartValue, priceRangeEndValue } = this.state;

        if (priceRangeStartValue !== start || priceRangeEndValue !== end) {
            this.setState({
                priceRangeStartValue: start,
                priceRangeEndValue: end
            }, this.debouncedUpdatePriceFilter);
        }
    }

    /**
     * Problem with onChangeCommitted event: it only listens the mouseUp event on the slider, so it's not triggered
     * if we release the mouse elsewhere...
     */
    onPriceRangeSliderChangeCommitted(/* [start, end] */) {
        /* console.log('onPriceRangeSliderChangeCommitted', { start, end });
        this.setState({
            priceRangeStartValue: start,
            priceRangeEndValue: end
        }, this.debouncedUpdatePriceFilter); */
    }

    priceRangeSliderConstraint([start, end]) {
        const { priceFilterRanges } = this.state;

        if (start === end) {
            return false;
        }

        if (priceFilterRanges.length === 1) {
            return true;
        }

        // Find the range containing start value
        const startRange = priceFilterRanges.find(([min, max]) => start >= min && start <= max);
        if (!startRange) {
            // The value is not in a range, get inferior and superior range
            // eslint-disable-next-line fp/no-let
            let startRangeDownIndex = 0;
            priceFilterRanges.forEach(([min, _max], index) => {
                if (start > min) {
                    startRangeDownIndex = index;
                }
            });
            if (startRangeDownIndex && priceFilterRanges[startRangeDownIndex + 1]) {
                const [, startRangeDownMax] = priceFilterRanges[startRangeDownIndex];
                const [startRangeUpMin, startRangeUpMax] = priceFilterRanges[startRangeDownIndex + 1];

                // Allow value in the bounds of previous and next ranges,
                // or allow value in the gap + the range superior (to be sure to have results)
                return (start <= startRangeDownMax && end >= startRangeUpMin)
                    || (start > startRangeDownMax && end > startRangeUpMax);
            }
        }

        return true;
    }

    onPriceRangeSliderInputChange(event) {
        const {
            priceRangeMinValue,
            priceRangeMaxValue,
            priceRangeStartValue,
            priceRangeEndValue
        } = this.state;

        // @TODO debounce input change ?

        const value = +event.target.value;

        switch (event.target.name) {
        case 'start':
            if (value === priceRangeStartValue || value > priceRangeEndValue || value < priceRangeMinValue) {
                return;
            }
            this.setState({ priceRangeStartValue: value }, this.debouncedUpdatePriceFilter);
            break;
        case 'end':
            if (value === priceRangeEndValue || value < priceRangeStartValue || value > priceRangeMaxValue) {
                return;
            }
            this.setState({ priceRangeEndValue: value }, this.debouncedUpdatePriceFilter);
            break;
        default:
        }
    }

    onPriceRangeSliderResetButtonClick() {
        const { updateConfigurableVariant, parameters: { price: priceParameterValues } } = this.props;

        if (!priceParameterValues) {
            return;
        }

        updateConfigurableVariant('price', priceParameterValues[0]);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryConfigurableAttributesContainer);
