/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import { GROUPED_PRODUCTS_GUEST } from '../component/Matomo/Matomo.config';
import MatomoContainer from '../component/Matomo/Matomo.container';
import ProductHelper from '../component/Matomo/utils/Product';
import MatomoQuery from '../query/Matomo.query';

export const handle_syncCartWithBEError = (args, callback) => callback(...args)
    .then(
        (result) => {
            MatomoContainer.getInstance().setGroupedProducts({});
            return result;
        }
    );

export const addMatomoConfigQuery = (args, callback) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        MatomoQuery.getMatomoConfiguration()
    ];
};

export const addMatomoToConfigReducerInitialState = (args, callback) => {
    const { matomo } = BrowserDatabase.getItem('config') || { matomo: {} };

    return {
        ...callback(...args),
        matomo
    };
};

export const addMatomoToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { matomo } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        matomo
    };
};

export const afterRequestCustomerData = (args, callback) => {
    const matomo = MatomoContainer.getInstance();

    /** transfer grouped products data from guest to logged in user */
    const transferGroupedProductsData = (id) => {
        if (!matomo || matomo.groupedProductsStorageName !== GROUPED_PRODUCTS_GUEST) {
            return;
        }


        const guestGroupedProducts = matomo.getGroupedProducts();
        matomo.setGroupedProducts({});
        matomo.updateGroupedProductsStorageName(id);

        const userGroupedProducts = matomo.getGroupedProducts();
        const result = ProductHelper.mergeGroupedProducts(guestGroupedProducts, userGroupedProducts);

        matomo.setGroupedProducts(result);
    };

    return callback(...args)
        .then((result) => {
            if (matomo) {
                let customer = BrowserDatabase.getItem('customer');
                transferGroupedProductsData(customer.id);
                matomo.updateGroupedProductsStorageName(customer.id);
            }

            return result;
        });
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addMatomoConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addMatomoToConfigReducerInitialState
    },
    'PeggysagePwa/Store/Config/Reducer/ConfigReducer': {
        function: addMatomoToConfigUpdate
    },
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            requestCustomerData: afterRequestCustomerData
        }
    }
};
