/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import ChevronIcon from '@scandipwa/scandipwa/src/component/ChevronIcon';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import history from 'Util/History';

import './FavoriteShop.style';

/** @namespace PeggysagePwa/Route/FavoriteShop/Component */
export class FavoriteShopComponent extends PureComponent {
    static propTypes = {
        setShopWithId: PropTypes.func.isRequired,
        search: PropTypes.func.isRequired,
        shop: PropTypes.isRequired,
        isLoading: PropTypes.bool.isRequired,
        setShopWithLocation: PropTypes.func.isRequired,
        shops: PropTypes.isRequired
    };

    state = {
        edit: false
    };

    renderTitle() {
        return (
            <div block="FavoriteShop" elem="MiscellaneousLayoutWrapper">
                <div
                  block="FavoriteShop"
                  elem="LayoutWrapper"
                >
                    <div
                      block="FavoriteShop"
                      elem="Title"
                    >
                        { __('My Favorite Shop') }
                    </div>
                </div>
            </div>
        );
    }

    toggleEdit() {
        const { edit } = this.state;
        this.state.edit = !edit;
        this.forceUpdate();
    }

    renderBackButton() {
        return (
            // eslint-disable-next-line react/jsx-no-bind
            <div block="FavoriteShop">
                <button onClick={ () => history.goBack() } block="FavoriteShop" elem="BackButton">
                    <div className="ChevronIcon_direction_left">
                        <ChevronIcon />
                    </div>
                    <span>{ __('Back') }</span>
                </button>
            </div>
        );
    }

    renderPageBar() {
        return (
            <div>
                <div>
                    { this.renderBackButton() }
                </div>
                <div block="Title">
                    { this.renderTitle() }
                </div>
            </div>
        );
    }

    renderGetFavoriteShop() {
        const {
            shop: {
                name, city, street, postcode
            }
        } = this.props;

        return (
            <div block="FavoriteShopElement" className="Shop">
                <div block="EelementName">
                    { name }
                </div>
                <div>
                    { street }
                </div>
                <div>
                    { postcode }
                    { ' ' }
                    { city }
                </div>
                <div block="EelementTel">
                    { __('Tel') }
                    { ' ' }
                    :
                    { ' ' }
                    04 85 21 30 00
                </div>
                <div block="EelementLocate">
                    <a onClick={ () => this.toggleEdit() } className="Locate">
                        { __('update') }
                    </a>
                </div>
            </div>
        );
    }

    searchShop(e) {
        const { search } = this.props;
        // eslint-disable-next-line radix
        const postCode = parseInt(e.target.value, null);
        if (postCode) {
            search(postCode);
        } else {
            search(null);
        }
    }

    setFavoriteShop(id) {
        const { setShopWithId, setShopWithLocation } = this.props;
        const { edit } = this.state;
        if (!id) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    setShopWithLocation(position.coords);
                }
                if (edit) {
                    this.toggleEdit();
                }
            });
        } else {
            setShopWithId(id);
            this.toggleEdit();
        }
    }

    renderSetFavoriteShop() {
        const { edit } = this.state;
        const { shops } = this.props;
        return (
            <div block="FavoriteShop" elem="SetContainer">
                <div block="FavoriteShop" className="Description">
                    { __('Search to find a Peggy Sage shop near to your location') }
                </div>
                <div block="FavoriteShop" elem="InputContainer">
                    <input block="FavoriteShop" className="Input" pattern="[0-9]{1,5}" onChange={ (e) => this.searchShop(e) } />
                    { shops.length > 0
                        && (
                            <div block="FavoriteShop" elem="Suggestions">
                                { shops.map((shop) => (
                                    <a onClick={ () => this.setFavoriteShop(shop.id) } className="Suggestion">
                                        { shop.name }
                                    </a>
                                )) }
                            </div>
                        ) }
                </div>
                <div block="FavoriteShop" elem="ActionContainer">
                    <div>
                        <a onClick={ () => this.setFavoriteShop() } className="Locate">
                            { __('Locate me') }
                        </a>
                    </div>
                    { edit && (
                        <div>
                            <a onClick={ () => this.toggleEdit() } className="Locate">
                                { __('Cancel') }
                            </a>
                        </div>
                    ) }
                </div>
            </div>
        );
    }

    renderContent() {
        const { shop } = this.props;
        const { edit } = this.state;

        if (!shop || edit) {
            return this.renderSetFavoriteShop();
        }

        return this.renderGetFavoriteShop();
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div block="FavoriteShop">
                <div block="FavoriteShop">
                    { this.renderPageBar() }
                </div>
                <div block="FavoriteShop">
                    { isLoading ? (<div block="FavoriteShop" elem="Loader"><Loader /></div>) : this.renderContent() }
                </div>
            </div>
        );
    }
}

export default FavoriteShopComponent;
