import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';
import history from 'Util/History';

/** @namespace PeggysagePwa/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace  PeggysagePwa/Component/Popup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace  PeggysagePwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...SourcePopupContainer.propTypes,
        payload: PropTypes.objectOf(
            PropTypes.shape({
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({ value: PropTypes.string }) // TranslatedValue
                ])
            })
        ).isRequired,
        hasBackButton: PropTypes.bool,
        onBackButtonClick: PropTypes.func
    };

    static defaultProps = {
        ...SourcePopupContainer.defaultProps,
        hasBackButton: false,
        onBackButtonClick: null
    };

    containerFunctions = {
        ...this.containerFunctions,
        onBackButtonClick: this.onBackButtonClick.bind(this)
    };

    /**
     * Override: always return a string (from TranslatedValue) to prevent console warning (wrong type)
     * @returns {string|*}
     * @private
     */
    _getPopupTitle() {
        const { payload, activeOverlay } = this.props;
        const { title } = payload[activeOverlay] || {};

        return title ? title.toString() : '';
    }

    onBackButtonClick() {
        const { onBackButtonClick } = this.props;

        if (onBackButtonClick) {
            onBackButtonClick();
            return;
        }

        history.goBack();
    }

    containerProps() {
        const { hasBackButton } = this.props;

        return {
            ...super.containerProps(),
            hasBackButton
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
