import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import { TotalsType } from 'Type/MiniCart.type';

import { PAYPAL_SCRIPT } from './PayPal.config';

import './PayPal.style';

/**
 * *Note*
 * This component currently can be rendered only once
 * Please try to have no more than 1 component per page and use isDisabled to hide it.
 * @namespace PeggysagePwa/Component/PayPal/Component
*/
export class PayPalComponent extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        paypal: PropTypes.oneOfType([PropTypes.object]).isRequired,
        clientId: PropTypes.string.isRequired,
        cartTotals: TotalsType.isRequired,
        onInit: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onApprove: PropTypes.func.isRequired,
        createOrder: PropTypes.func.isRequired,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        environment: PropTypes.oneOf([
            'production',
            'sandbox'
        ]).isRequired
    };

    static defaultProps = {
        isDisabled: false
    };

    getClientId = () => {
        const {
            clientId,
            environment
        } = this.props;

        if (environment === 'sandbox') {
            return 'sb';
        }

        return clientId;
    };

    getPayPalScript = () => {
        const {
            cartTotals: {
                prices: {
                    quote_currency_code = null
                } = {}
            }
        } = this.props;

        const params = {
            currency: quote_currency_code,
            // TODO implement action fetch from BE
            intent: 'capture',
            'client-id': this.getClientId()
        };

        const paramsString = (Object.entries(params).map(([key, value]) => `${key}=${value}`)).join('&');
        return `<script defer id="${PAYPAL_SCRIPT}" src="https://www.paypal.com/sdk/js?${paramsString}"></script>`;
    };

    renderButtons() {
        const {
            cartTotals: {
                prices: {
                    quote_currency_code = null
                } = {}
            },
            paypal,
            onInit,
            onClick,
            onError,
            onCancel,
            onApprove,
            createOrder,
            setDetailsStep,
            setLoading,
            isDisabled
        } = this.props;

        if (!paypal) {
            return <Loader isLoading />;
        }

        return (
            <PayPalScriptProvider options={ { 'client-id': this.getClientId(), intent: 'capture', currency: quote_currency_code } }>
                <PayPalButtons
                  onInit={ onInit }
                  onClick={ onClick }
                  onError={ onError }
                  onCancel={ onCancel }
                  onApprove={ onApprove }
                  createOrder={ createOrder }
                  setDetailsStep={ setDetailsStep }
                  setLoading={ setLoading }
                  style={ {
                      layout: 'horizontal',
                      label: 'pay'
                  } }
                  disabled={ isDisabled }
                />
            </PayPalScriptProvider>
        );
    }

    render() {
        const { isDisabled } = this.props;

        return (
            <div block="Checkout-StickyButtonWrapper">
                <div block="PayPal" mods={ { isDisabled } }>
                    { this.renderButtons() }
                </div>
            </div>
        );
    }
}

export default PayPalComponent;
