import { getInitialState } from 'SourceStore/Notification/Notification.reducer';

import { HIDE_ALL_NOTIFICATIONS, HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './Notification.action';

export { getInitialState };

/** @namespace PeggysagePwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const { msgType, msgText, msgDebug } = action;

        notifications[Date.now()] = { msgType, msgText, msgDebug };

        return {
            ...state,
            notifications
        };

    case HIDE_NOTIFICATION:
        // eslint-disable-next-line no-unused-vars
        const { [action.id]: id, ...shownNotifications } = notifications;

        return {
            ...state,
            notifications: shownNotifications
        };

    case HIDE_ALL_NOTIFICATIONS:
        return getInitialState();

    default:
        return state;
    }
};

export default NotificationReducer;
