import ProductListQuery from 'Query/ProductList.query';
import {
    WishlistQuery as SourceWishlistQuery
} from 'SourceQuery/Wishlist.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/Wishlist/Query */
export class WishlistQuery extends SourceWishlistQuery {
    // #region MUTATION
    /**
     * Override
     * @param wishlistItems
     * @returns {Field}
     */
    addProductsToWishlist(wishlistItems) {
        return new Field('s_saveWishlistItem')
            .addArgument('wishlistItem', 'WishlistItemInput!', wishlistItems[0])
            .addFieldList(this._getWishlistItemsFields('sku'));
    }
    // #endregion

    /**
     * Override
     * @param sharingCode
     * @param sort
     * @param filters
     * @param isWishlistPage
     * @returns {Field}
     */
    getWishlistQuery(sharingCode, sort, filters, isWishlistPage = false) {
        const field = new Field('s_wishlistGet')
            .addFieldList(this._getWishlistFields(isWishlistPage));

        if (sharingCode) {
            field.addArgument('sharing_code', 'ID', sharingCode);
        }

        if (sort) {
            field.addArgument('order_by', 'String', sort);
        }

        if (filters) {
            field.addArgument('filter_by', '[WishlistInputFilter]', filters);
        }

        return field;
    }

    /**
     * Override
     * @returns {(string|*|Field)[]}
     * @private
     */
    _getWishlistFields(isWishlistPage = false) {
        ProductListQuery.setOptions({ isPlp: true, isSingleProduct: false });

        const fields = [
            ...super._getWishlistFields(),
            'suggestion_skus',
            new Field('filters').addFieldList([
                'label',
                'value',
                new Field('values').addFieldList([
                    'label',
                    'value'
                ])
            ]),
            new Field('orders').addFieldList([
                'value',
                'label'
            ])
        ];

        if (isWishlistPage) {
            fields.push(
                new Field('related_products').addFieldList(
                    ProductListQuery._getProductInterfaceFields(false, false, false)
                )
            );
        }

        return fields;
    }
}

export default new WishlistQuery();
