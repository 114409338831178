import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import {
    DEFAULT_STATE,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';

export {
    DEFAULT_STATE
};

/** @namespace PeggysagePwa/Component/NavigationAbstract/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace PeggysagePwa/Component/NavigationAbstract/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/NavigationAbstract/Container */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    static propTypes = {
        ...SourceNavigationAbstractContainer.propTypes,
        activeOverlay: PropTypes.string.isRequired
    };

    /**
     * Override: set navigation state on desktop too, to handle back link in the header
     * @param history
     * @returns {{}|{prevPathname}}
     */
    handleDesktopRouteChange(history) {
        const {
            hideActiveOverlay,
            setNavigationState,
            navigationState: { name },
            activeOverlay
        } = this.props;
        const { prevPathname } = this.state;
        const { pathname } = history;

        // Do not close category filter overlay when history change
        if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID && activeOverlay !== undefined) {
            hideActiveOverlay();
        }

        if (prevPathname === pathname) {
            return {};
        }

        // Find the new state name
        const newNavigationState = this.getNavigationState();

        // Update the state if new name is set
        if (name !== newNavigationState.name) {
            setNavigationState(newNavigationState);
        }

        return { prevPathname: pathname };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
