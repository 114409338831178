import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { RefType } from 'Type/Common.type';
import { getCartId } from 'Util/Cart';
import { setPaymentToken } from 'Util/PaymentTokenPersistence';
import { fetchMutation } from 'Util/Request';
import { validateGroup } from 'Util/Validator';

import Worldline from './Worldline.component';
import { WORDLINE_CREDIT_CARD, WORDLINE_HOSTED_CHECKOUT, WORDLINE_REDIRECT_PAYMENT } from './Worldline.config';

/** @namespace PeggysagePwa/Component/Worldline/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace PeggysagePwa/Component/Worldline/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e))
});

/** @namespace PeggysagePwa/Component/Worldline/Container */
export class WorldlineContainer extends PureComponent {
    static propTypes = {
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        areTermsAccepted: PropTypes.bool,
        formRef: RefType
    };

    static defaultProps = {
        areTermsAccepted: false,
        formRef: null
    };

    state = {
        isDisabled: true
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this)
    };

    componentDidMount() {
        this.checkIsDisabled();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { areTermsAccepted } = this.props;
        const { areTermsAccepted: prevAreTermsAccepted } = prevProps;

        if (areTermsAccepted !== prevAreTermsAccepted) {
            this.checkIsDisabled();
        }
    }

    containerProps() {
        const { isDisabled } = this.state;

        return {
            isDisabled
        };
    }

    checkIsDisabled() {
        const { areTermsAccepted } = this.props;
        this.setState({ isDisabled: !areTermsAccepted });
    }

    async handleClick() {
        const { areTermsAccepted, formRef } = this.props;

        if (areTermsAccepted) {
            this.placeOrder();

            return;
        }

        if (formRef && formRef.current) {
            validateGroup(formRef.current);
        }
    }

    async placeOrder() {
        const { selectedPaymentCode, setLoading } = this.props;

        setLoading(true);

        const cart_id = getCartId();

        if (selectedPaymentCode === WORDLINE_CREDIT_CARD) {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id,
                payment_method: {
                    code: selectedPaymentCode,
                    [selectedPaymentCode]: { is_active_payment_token_enabler: false },
                    purchase_order_number: null
                }
            }));
            await fetchMutation(CheckoutQuery.getProcessCCCreateRequest({
                cart_id,
                payment_method: {
                    code: WORDLINE_CREDIT_CARD
                }
            }));

            setLoading(false);
        } else if (selectedPaymentCode === WORDLINE_HOSTED_CHECKOUT) {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id,
                payment_method: {
                    code: selectedPaymentCode,
                    purchase_order_number: null
                }
            }));
            const wordlineData = await fetchMutation(CheckoutQuery.getProcessHCRedirectRequestMutation({
                cart_id,
                payment_method: {
                    code: WORDLINE_HOSTED_CHECKOUT
                }
            }));

            setLoading(false);
            setPaymentToken(wordlineData.processHCRedirectRequest.reserved_order_id);
            window.location.href = wordlineData.processHCRedirectRequest.redirect_url;
        } else if (selectedPaymentCode === WORDLINE_REDIRECT_PAYMENT) {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id,
                payment_method: {
                    code: selectedPaymentCode,
                    purchase_order_number: null
                }
            }));
            await fetchMutation(CheckoutQuery.getProcessRPRedirectRequestMutation({
                cart_id,
                payment_method: {
                    code: WORDLINE_REDIRECT_PAYMENT
                }
            }));

            setLoading(false);
        }
    }

    render() {
        return (
            <Worldline
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorldlineContainer);
