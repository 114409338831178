import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import OGImage from 'Style/images/meta/peggy-sage-partage.jpg';

/** @namespace PeggysagePwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    ogType: state.MetaReducer.ogType
});

/** @namespace PeggysagePwa/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...SourceMetaContainer.propTypes,
        ogType: PropTypes.string
    };

    static defaultProps = {
        ...SourceMetaContainer.defaultProps,
        default_ogType: 'website',
        ogType: ''
    };

    _getOpenGraphType() {
        const { ogType, default_ogType } = this.props;

        return String(ogType || default_ogType);
    }

    _getTwitterSummaryCard() {
        return {
            'twitter:card': 'summary',
            'twitter:title': this._getTitle(),
            'twitter:description': this._getDescription(),
            'twitter:image': `${ location.origin }${ OGImage }`
        };
    }

    _getOpenGraphMetadata() {
        const openGraphs = {
            'og:type': this._getOpenGraphType(),
            'og:title': this._getTitle(),
            'og:description': this._getDescription(),
            'og:site_name': 'PEGGY SAGE',
            'og:url': location.href,
            'og:image': `${ location.origin }${ OGImage }`,
            'og:image:width': 1200,
            'og:image:height': 630,
            'og:image:type': 'image/jpeg'
        };

        return this._generateMetaFromMetadata(openGraphs, 'property');
    }

    /**
     * Override: add open graph meta
     * @returns {[string, unknown]}
     * @private
     */
    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            ...this._getTwitterSummaryCard()
        };

        return this._generateMetaFromMetadata(meta);
    }

    /**
     * Override: add open graph + force canonical url from window location
    */
    containerProps() {
        const { origin, pathname } = location;

        return {
            ...super.containerProps(),
            canonical_url: `${ origin }${ pathname }`,
            openGraphMetadata: this._getOpenGraphMetadata()
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
