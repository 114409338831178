import PropTypes from 'prop-types';
import React from 'react';
import RangeSlider from 'rsuite/RangeSlider';

import ExpandableContent from 'Component/ExpandableContent';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { ONE_HUNDRED_PERCENT } from 'Component/ProductActions/ProductActions.config';
import {
    CategoryConfigurableAttributes as SourceCategoryConfigurableAttributes
} from 'SourceComponent/CategoryConfigurableAttributes/CategoryConfigurableAttributes.component';
import { formatCurrency } from 'Util/Price/Price';

import './CategoryConfigurableAttributes.override.style';

/** @namespace PeggysagePwa/Component/CategoryConfigurableAttributes/Component */
export class CategoryConfigurableAttributesComponent extends SourceCategoryConfigurableAttributes {
    static propTypes = {
        ...SourceCategoryConfigurableAttributes.propTypes,
        groupId: PropTypes.string.isRequired,
        isArrow: PropTypes.bool.isRequired,
        priceRangeMinValue: PropTypes.number.isRequired,
        priceRangeMaxValue: PropTypes.number.isRequired,
        priceRangeStepValue: PropTypes.number.isRequired,
        priceRangeStartValue: PropTypes.number,
        priceRangeEndValue: PropTypes.number,
        onPriceRangeSliderChange: PropTypes.func.isRequired,
        onPriceRangeSliderChangeCommitted: PropTypes.func.isRequired,
        priceRangeSliderConstraint: PropTypes.func.isRequired,
        onPriceRangeSliderInputChange: PropTypes.func.isRequired,
        onPriceRangeSliderResetButtonClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        priceRangeStartValue: null,
        priceRangeEndValue: null
    };

    /**
     * Override: range slider instead of checkboxes
     * @param option
     * @returns {JSX.Element|null}
     */
    renderPriceSwatch(option) {
        const {
            currencyCode,
            onPriceRangeSliderChange,
            onPriceRangeSliderChangeCommitted,
            priceRangeSliderConstraint,
            priceRangeMinValue: minValue,
            priceRangeMaxValue: maxValue,
            priceRangeStepValue: stepValue,
            priceRangeStartValue,
            priceRangeEndValue,
            onPriceRangeSliderInputChange,
            onPriceRangeSliderResetButtonClick,
            parameters
        } = this.props;
        // eslint-disable-next-line no-unused-vars
        const { attribute_options, ...priceOption } = option;

        if (!maxValue) {
            return null;
        }

        const startValue = priceRangeStartValue ?? minValue;
        const endValue = priceRangeEndValue ?? maxValue;

        const inputStartOffset = ((startValue - minValue) / (maxValue - minValue)) * ONE_HUNDRED_PERCENT;
        const inputEndOffset = ((endValue - minValue) / (maxValue - minValue)) * ONE_HUNDRED_PERCENT;

        const isResetButtonVisible = priceOption.attribute_values.length === 1
            || Object.keys(parameters).find((key) => key === 'price');

        return (
            <div block="ProductConfigurableAttributes" elem="PriceRange" key={ priceOption.attribute_code }>
                <div block="ProductConfigurableAttributes" elem="PriceRangeTitle">
                    { priceOption.attribute_label }
                </div>
                <div
                  block="ProductConfigurableAttributes"
                  elem="PriceRangeContent"
                  mods={ { isDisabled: priceOption.attribute_values.length === 1 } }
                >
                    <RangeSlider
                      progress
                      min={ minValue }
                      max={ maxValue }
                      step={ stepValue }
                      value={ [startValue, endValue] }
                      onChange={ onPriceRangeSliderChange }
                      onChangeCommitted={ onPriceRangeSliderChangeCommitted }
                      constraint={ priceRangeSliderConstraint }
                    />
                    <div
                      block="rs-slider"
                      elem="tooltip"
                      mods={ { start: true } }
                      style={ {
                          '--rs-slider-input-start-left': `${inputStartOffset}%`,
                          '--rs-slider-input-length': startValue.toString().length
                      } }
                    >
                        <Field
                          type={ FIELD_TYPE.number }
                          attr={ {
                              name: 'start',
                              value: startValue,
                              min: minValue,
                              max: endValue
                          } }
                          events={ {
                              onChange: onPriceRangeSliderInputChange
                          } }
                        />
                        <span className="currency">{ formatCurrency(currencyCode) }</span>
                    </div>
                    <div
                      block="rs-slider"
                      elem="tooltip"
                      mods={ { end: true } }
                      style={ {
                          '--rs-slider-input-end-left': `${inputEndOffset}%`,
                          '--rs-slider-input-length': endValue.toString().length
                      } }
                    >
                        <Field
                          type={ FIELD_TYPE.number }
                          attr={ {
                              name: 'end',
                              value: endValue,
                              min: startValue,
                              max: maxValue
                          } }
                          events={ {
                              onChange: onPriceRangeSliderInputChange
                          } }
                        />
                        <span className="currency">{ formatCurrency(currencyCode) }</span>
                    </div>
                </div>
                { isResetButtonVisible && (
                    <div block="ProductConfigurableAttributes" elem="PriceRangeReset">
                        <button
                          type="button"
                          block="Button"
                          mods={ { likeLink: true } }
                          mix={ { block: 'ProductConfigurableAttributes', elem: 'PriceRangeResetButton' } }
                          onClick={ onPriceRangeSliderResetButtonClick }
                        >
                            { __('Reset') }
                        </button>
                    </div>
                ) }
            </div>
        );
    }

    /**
     * Override: Add groupId; remove subHeading
     * @param option
     * @returns {JSX.Element}
     */
    renderDropdownOrSwatch(option) {
        const {
            isContentExpanded,
            // getSubHeading,
            groupId,
            isArrow
        } = this.props;

        const {
            attribute_label,
            attribute_code,
            attribute_options
        } = option;

        const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
        const content = swatch_data ? this.renderSwatch(option) : this.renderDropdown(option);

        return (
            <ExpandableContent
              key={ attribute_code }
              heading={ attribute_label }
              // subHeading={ getSubHeading(option) }
              mix={ {
                  block: 'ProductConfigurableAttributes',
                  elem: 'Expandable'
              } }
              isContentExpanded={ isContentExpanded }
              groupId={ groupId || '' }
              isArrow={ isArrow }
            >
                { content }
            </ExpandableContent>
        );
    }

    /**
     * Override: Remove Expandable Content Show more
     * @param option
     * @returns {JSX.Element}
     */
    renderDropdown(option) {
        const { attribute_values } = option;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="DropDownList"
            >
                { attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
            </div>
        );
    }
}

export default CategoryConfigurableAttributesComponent;
