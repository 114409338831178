import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/Collection/Query */
export class CollectionQuery {
    _getCollectionCardFields() {
        return [
            'collection_category_name',
            'name',
            'description',
            'url',
            // 'image',
            'image_collection',
            // 'image_collection_logo',
            'image_collection_ambiance',
            'est_collection'
        ];
    }

    getCategories(options = {}) {
        // eslint-disable-next-line no-magic-numbers
        const { filters = {}, page_size = 13, current_page = 1 } = options;
        return new Field('categories')
            .addArgument('filters', 'CategoryFilterInput', filters)
            .addArgument('pageSize', 'Int', page_size)
            .addArgument('currentPage', 'Int', current_page)
            .addFieldList(this._getCategoriesFields());
    }

    _getCategoriesFields() {
        return [
            new Field('items').addFieldList(this._getCollectionCardFields()),
            new Field('page_info').addFieldList([
                'current_page',
                'page_size',
                'total_pages'
            ]),
            new Field('collection_filters').addFieldList([
                'name',
                'category_id'
            ])
        ];
    }
}

export default new CollectionQuery();
