export const GET_SHOPS = 'GET_SHOPS';
export const GET_SHOP = 'GET_SHOP';
export const SET_LOADING = 'SET_LOADING';

/** @namespace PeggysagePwa/Store/FavoriteShop/Action/getFavoriteShop */
export const getFavoriteShop = (payload) => ({
    type: GET_SHOP,
    ...payload
});

/** @namespace PeggysagePwa/Store/FavoriteShop/Action/getShops */
export const getShops = (payload) => ({
    type: GET_SHOPS,
    ...payload
});

/** @namespace PeggysagePwa/Store/FavoriteShop/Action/setLoading */
export const setLoading = (payload) => ({
    type: SET_LOADING,
    ...payload
});
