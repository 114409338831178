// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CategoryContentPushType } from 'Type/Category.type';

import ProductListContentPush from './ProductListContentPush.component';

/** @namespace PeggysagePwa/Component/ProductListContentPush/Container */
export class ProductListContentPushContainer extends PureComponent {
    static propTypes = {
        contentPush: CategoryContentPushType.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        const { contentPush } = this.props;

        return {
            contentPush
        };
    }

    render() {
        return (
            <ProductListContentPush
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductListContentPushContainer;
