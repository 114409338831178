import {
    ONE_STAR_SHARE,
    STARS_COUNT,
    STARS_GRANULARITY_PERCENT,
    STARS_GRANULARITY_PERCENT_LIMIT
} from 'Component/ProductReviewRating/ProductReviewRating.config';
import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.override.style';

/**
     * Ceil percentage instead of round
     * @returns {(number|number|number)[]}
     * @namespace PeggysagePwa/Component/ProductReviewRating/Component/getStarCounts
 */
export function getStarCounts(summary) {
    const percentRounded = (summary / STARS_GRANULARITY_PERCENT).toFixed(1) * STARS_GRANULARITY_PERCENT;
    // eslint-disable-next-line fp/no-let
    let fullCount = Math.floor(percentRounded / ONE_STAR_SHARE);
    // eslint-disable-next-line fp/no-let
    let halfFullCount = percentRounded % ONE_STAR_SHARE === STARS_GRANULARITY_PERCENT ? 1 : 0;

    if (percentRounded % ONE_STAR_SHARE > STARS_GRANULARITY_PERCENT + STARS_GRANULARITY_PERCENT_LIMIT) {
        fullCount++;
    } else if (percentRounded % ONE_STAR_SHARE > STARS_GRANULARITY_PERCENT_LIMIT) {
        halfFullCount = 1;
    }

    const emptyCount = STARS_COUNT - fullCount - halfFullCount;
    return [fullCount, halfFullCount, emptyCount];
}

/** @namespace PeggysagePwa/Component/ProductReviewRating/Component */
export class ProductReviewRatingComponent extends SourceProductReviewRating {
    /**
     * Override counter rendering
     * @returns {JSX.Element|*}
     */
    render() {
        const {
            summary,
            code,
            placeholder,
            mix,
            count
        } = this.props;

        const [fullCount, halfFullCount, emptyCount] = getStarCounts(summary);

        const ariaText = this.getAriaText(summary, code);

        if (placeholder) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="ProductReviewRating"
              // title={ `${summary}%` }
              ref={ this.reviewRating }
              aria-label={ ariaText }
              mix={ mix }
            >
                <span block="ProductReviewRating" elem="Stars">
                    { this.renderStar(fullCount, STAR_FULL) }
                    { this.renderStar(halfFullCount, STAR_HALF_FULL) }
                    { this.renderStar(emptyCount, STAR_EMPTY) }
                </span>
                <span block="ProductReviewRating" elem="Counter">
                    { __('%s avis', count) }
                </span>
            </div>
        );
    }
}

export default ProductReviewRatingComponent;
