import {
    GET_ORDER_LIST,
    SET_ORDER_LOADING_STATUS,
    setLoadingStatus
} from 'SourceStore/Order/Order.action';

export {
    GET_ORDER_LIST,
    SET_ORDER_LOADING_STATUS,
    setLoadingStatus
};

export const SET_ORDER_ADDING_TO_FAVORITES_STATUS = 'SET_ORDER_ADDING_TO_FAVORITES_STATUS';

/** @namespace PeggysagePwa/Store/Order/Action/getOrderList */
export const getOrderList = (orderList, status, loadMore) => ({
    type: GET_ORDER_LIST,
    orderList,
    status,
    loadMore
});

/** @namespace PeggysagePwa/Store/Order/Action/setAddingToFavoritesStatus */
export const setAddingToFavoritesStatus = (status) => ({
    type: SET_ORDER_ADDING_TO_FAVORITES_STATUS,
    status
});
