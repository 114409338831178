/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import ActiveCampaignContainer from '../component/ActiveCampaign/ActiveCampaign.container';
import AcQuery from '../query/Ac.query';


export const addAcConfigQuery = (args, callback) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        AcQuery.getAcConfiguration()
    ];
};

export const addAcToConfigReducerInitialState = (args, callback) => {
    const { active_campaign } = BrowserDatabase.getItem('config') || { active_campaign: {} };

    return {
        ...callback(...args),
        active_campaign
    };
};

export const addAcToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { active_campaign } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        active_campaign
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addAcConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addAcToConfigReducerInitialState
    },
    'PeggysagePwa/Store/Config/Reducer/ConfigReducer': {
        function: addAcToConfigUpdate
    }
};
