/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import { GROUPED_PRODUCTS_GUEST } from '../component/FacebookPixel/FacebookPixel.config';
import FacebookPixelContainer from '../component/FacebookPixel/FacebookPixel.container';
import ProductHelper from '../component/FacebookPixel/utils/Product';
import FbpQuery from '../query/Fbp.query';

export const handle_syncCartWithBEError = (args, callback) => callback(...args)
    .then(
        (result) => {
            FacebookPixelContainer.getInstance().setGroupedProducts({});
            return result;
        }
    );

export const addFbpConfigQuery = (args, callback) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        FbpQuery.getFBPConfiguration()
    ];
};

export const addFbpToConfigReducerInitialState = (args, callback) => {
    const { fbp } = BrowserDatabase.getItem('config') || { fbp: {} };

    return {
        ...callback(...args),
        fbp
    };
};

export const addFbpToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { fbp } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        fbp
    };
};

export const afterRequestCustomerData = (args, callback) => {
    const fbp = FacebookPixelContainer.getInstance();

    /** transfer grouped products data from guest to logged in user */
    const transferGroupedProductsData = (id) => {
        if (!fbp || fbp.groupedProductsStorageName !== GROUPED_PRODUCTS_GUEST) {
            return;
        }


        const guestGroupedProducts = fbp.getGroupedProducts();
        fbp.setGroupedProducts({});
        fbp.updateGroupedProductsStorageName(id);

        const userGroupedProducts = fbp.getGroupedProducts();
        const result = ProductHelper.mergeGroupedProducts(guestGroupedProducts, userGroupedProducts);

        fbp.setGroupedProducts(result);
    };

    return callback(...args)
        .then((result) => {
            if (fbp) {
                let customer = BrowserDatabase.getItem('customer');
                transferGroupedProductsData(customer.id);
                fbp.updateGroupedProductsStorageName(customer.id);
            }

            return result;
        });
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addFbpConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addFbpToConfigReducerInitialState
    },
    'PeggysagePwa/Store/Config/Reducer/ConfigReducer': {
        function: addFbpToConfigUpdate
    },
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            requestCustomerData: afterRequestCustomerData
        }
    }
};
