import { TOGGLE_MENU_MOBILE } from './Menu.action';

/** @namespace PeggysagePwa/Store/Menu/Reducer/getInitialState */
export const getInitialState = () => ({
    isMenuMobileOpened: false
});

/** @namespace PeggysagePwa/Store/Menu/Reducer/MenuReducer */
export const MenuReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_MENU_MOBILE:
        const { isMenuMobileOpened } = action;
        return { ...state, isMenuMobileOpened };
    default:
        return state;
    }
};

export default MenuReducer;
